import { createSlice } from "@reduxjs/toolkit";

export const presentersSlice=createSlice({
    name:"presenters",
    initialState:{
        value:[]
    },
    reducers:{
        getPresentersList:(state,action)=>{
            state.value=action.payload.presenters
        }
    }
})

export const {getPresentersList}=presentersSlice.actions
export default presentersSlice.reducer