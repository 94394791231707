const axios = require("axios");
const qs = require('query-string');

function register(values, facebookId) {
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
};
    const data = {
      first_name: values.firstname,
      last_name: values.lastname,
      user_email: values.email,
      password: values.password,
      facebook_id: "",
    };
    
  
    return axios
      .post(
        process.env.REACT_APP_API_URL + "account/register",
        qs.stringify(data),
        customConfig
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return [];
      });
  }


  function verifyEmail(otp,userRegisterId) {
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    var token = localStorage.getItem("access_token");
  
    const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid: userRegisterId,
        pubid: process.env.REACT_APP_PUBID,
        country_code: countryCode,
        channelid: process.env.REACT_APP_CHANNELID,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: "web",
      },
      params: {
        otp: otp,
      },
    };
    return axios
    .get(process.env.REACT_APP_API_URL + "account/otp/verify",
    customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
  }




  export const Service ={
    register,
    verifyEmail
    // login
  }