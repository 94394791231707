import React, { useEffect, useState , useRef } from 'react'
import {ReactComponent as ShareIcon} from "../../../images/svg/share.svg"
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg"
import * as service from "./service";
import { FacebookShareButton, TwitterShareButton,EmailShareButton,WhatsappShareButton } from "react-share";
import { useLocation, useParams } from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { useSelector } from 'react-redux';
import { playerToken } from '../../../CommonService';
import VideoJS from '../../../Components/VideoJsPlayer/VideoJS';
import parse from "html-react-parser";

const PreviewArticleInfo = () => {
    const {id}=useParams()
    
    const [singleNewsData,setSingelNewsData]=useState({})
    const [newsDate,setNewsDate]=useState()
    const [shareButton,setShareButton]=useState(false)
    const loadingStatus=useSelector((state)=>state?.loading?.value)
  const [loading,setLoading]=useState(true)
  const [videoUrl,setVideoUrl]=useState("")
    const [videoPlayer,setVideoPlayer]=useState()
    const [videoImg,setVideoImg]=useState("")
  let shareRef = useRef(null)
  let shareIconRef = useRef(null)

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);


    useEffect(()=>{
        
        window.scroll(0,0)
        setLoading(true)
        service.getSingleNewsData(id).then((response)=>{
            setSingelNewsData(response?.data)
           
            if(response?.data[0]?.video_name){
                    setVideoImg(response?.data[0]?.first_frame)
                  
                playerToken().then((tokenResponse)=>{
                    let arr=response?.data[0]?.video_name?.split("/").reverse();
                    setVideoUrl(
                        "https://poppo.tv/playlist/playlist.m3u8?id=" +
                        arr[1] +
                        "&token=" +
                        tokenResponse?.data?.data +
                        "&type=video" +
                        "&pubid=" +
                        process.env.REACT_APP_PUBID
                    )
                })
            }else{
                setLoading(false)
            }
            
        })
    },[])
    useEffect(()=>{
        if(singleNewsData){
            if(singleNewsData[0]?.news_id){

                updateNewsAnalytics()
            }
                let dates=new Date(singleNewsData[0]?.schedule_date)
        let month=dates.toLocaleString('en-us', { month: 'short' })
        let year = dates.getFullYear()
        let day = dates.getDate().toString().length == 1 ? '0' + dates.getDate() : dates.getDate()
        let isoFormat = year + '  ' + month + ' ' + day
        setNewsDate(isoFormat)
        }
    },[singleNewsData])


    useEffect(()=>{
        if(videoUrl){
            
            const videoJsOptions = {
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                sources: [{
                  src: `${videoUrl}`,
                  type: 'application/x-mpegURL',
                }],
                poster:videoImg||require("../../../images/thumb.png")
              };
              setLoading(false)
            
            setVideoPlayer(<VideoJS options={videoJsOptions}   />)

        }
    },[videoUrl,videoImg])

    const updateNewsAnalytics = async () => {
        const response = await service.updateNewsAnalyticsAPI(singleNewsData[0]?.news_id);
        if(response?.status === 200){

        }
    }
    const replaceImage=(error)=>{
        error.target.src=require("../../../images/thumb.png")
    }
  return (
    <>
    {
        loading?(
            <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
        ):(

    <div className='articleInfo'>
        <div className="wrapper">
            <h1 className="heading">
               {singleNewsData[0]?.title}
            </h1>
            <div className="dateSection">
                <span className="icon">
                    <img src={require("../../../images/icon-calendartime.png")} alt="Icon-Calender" />
                </span>
                <span className="date">{newsDate&&newsDate}</span>
            </div>
            <div className="articleDetails">
                <div className="left">
                    <div className="imageContainer">
                        <img src={singleNewsData[0]?.logo_landscape} alt="Episodes" onError={(e)=>replaceImage(e)} />
                    </div>
                </div>
                <div className="right">
                    <p>{parse(singleNewsData[0]?.description.split("\n").join("<br>"))}</p>
                </div>
            </div>
            <div className="videoContainer">
                {videoPlayer}
            </div>
            <div className="buttonContainer">
                    <button ref={shareIconRef} onClick={()=>setShareButton(!shareButton)} className={shareButton?"active shareButton ":"shareButton"}>
                        <ShareIcon/>
                        <span>Share</span>
                        
                        <DownArrow className='active'/>
                    </button>
                    {
                        shareButton&&
                        <div className="shareContainer" ref={shareRef}>
                            <FacebookShareButton
                                url={window.location.href}
                            >
                                <span className='name facebook'>Facebook</span>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <span className='name twitter'>Twitter</span>
                            </TwitterShareButton>
                            <CopyToClipboard text={window.location.href}>
                                <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                            </CopyToClipboard>
                            <EmailShareButton
                                url={window.location.href}
                            >
                                <span className='name'>Mail</span>
                            </EmailShareButton>
                            <WhatsappShareButton  url={window.location.href}>
                          <span className='name whatsapp'>Whatsapp</span>
                          </WhatsappShareButton   >
                        </div>
                    }
                </div>
        </div>
    </div>
        )
    }
    </>
  )
}

export default PreviewArticleInfo