import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import * as service from "./service";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countryList from 'react-select-country-list'

const DonateByDebit = () => {
  const [rate, setRate] = useState("0.00");
  const [countryListOption, setCountryListOption] = useState([]);
  const [donateSuccess,setDonate]=useState("")
  const [values, setValues] = useState({
    paymentType: "direct-debit",
    paymentCurrency: "GBP",
    giftAidForm: false,
    contactByEmail:null,
    contactByPhone:null,
    contactByPost:null,
    country:"GB"
  });
  const [changeAmount, setChangeAmount] = useState(false);
  const [errors, setErrors] = useState({});
  const countries = useMemo(() => countryList().getData(), [])

  useEffect(() => {
    window.scroll(0, 0);
    setCountryListOption(
      countries?.map((item, index) => {
        const { value, label } = item;
        if(value!="GB"){
          return (
            <option key={index} value={value}>
              {label}
            </option>
          );

        }
      })
    );
  }, [donateSuccess]);
  const changeAmountValueHandler = () => {
    if (values?.paymentAmount) {
      setRate(values?.paymentAmount);
      setErrors({...errors,paymentAmount:null})
      setChangeAmount(false);
    }
  };
  const handleUpdate = (item) => {
    const { name, value } = item?.target;
    handleChange(name, value);
  };
  const handleChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
      paymentType: "direct-debit",paymentCurrency: "GBP"

    });
  };
  const validation = async () => {
    let error = {};
    let validateStatus = true;
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let nameRegex = /^[a-zA-Z ]{3,30}$/;
    let textRegex = /^[a-zA-Z ]{3,50}$/;
    const {
      contactByEmail,
      contactByPhone,
      contactByPost,
      mdd,
      paymentAmount,
      accountHolder,
      sortCode,
      accountNumber,
      phone,
      houseNo,
      city,
      firstName,
      lastName,
      address1,
      postalCode,
      country,
      email,
    } = values;
    let finalAmount = Number(rate)
    if(!finalAmount || finalAmount===0){
      error.paymentAmount = "Donation amount required!";
      validateStatus = false;
    } else {
      if (finalAmount < 1) {
        error.paymentAmount = "Invalid Amount!";
        validateStatus = false;
      }
    }

    if (!firstName) {
      error.firstName = "Field cannot be blank!";
      validateStatus = false;
    } else if (!firstName?.match(nameRegex)) {
      error.firstName =
        "Minimum 3-30 characters and shouldn't include any special character";
      validateStatus = false;
    }

    if (!lastName) {
      error.lastName = "Field cannot be blank!";
      validateStatus = false;
    } else if (!lastName?.match(nameRegex)) {
      error.lastName =
        "Minimum 3-30 characters and shouldn't include any special character";
      validateStatus = false;
    }

    if (!address1) {
      error.address1 = "Field cannot be blank!";
      validateStatus = false;
    }

    if (!postalCode) {
      error.postalCode = "Field cannot be blank!";
      validateStatus = false;
    }

    if (!phone) {
      error.phone = "Field cannot be blank!";
      validateStatus = false;
    }else if(phone<0){
      error.phone = "Invalid!";
      validateStatus = false;
    }

    if (!country) {
      error.country = "Field cannot be blank!";
      validateStatus = false;
    }

    if (!houseNo) {
      error.house = "Field cannot be blank!";
      validateStatus = false;
    }else if(houseNo<0){
      error.house = "Invalid!";
      validateStatus = false;
    }

    if (!city) {
      error.city = "Field cannot be blank!";
      validateStatus = false;
    } 

    if (!email) {
      error.email = "Field cannot be blank!";
      validateStatus = false;
    } else if (!email?.match(emailRegex)) {
      error.email = "Please enter a valid email address!";
      validateStatus = false;
    }

    if (!accountHolder) {
      error.accountHolder = "Field cannot be blank!";
      validateStatus = false;
    } 

    if (!sortCode) {
      error.sortCode = "Field cannot be blank!";
      validateStatus = false;
    }
    else if(sortCode<0){
        error.sortCode = "Invalid!";
        validateStatus=false
    }
    else if (sortCode?.length < 6) {
      error.sortCode = "Sortcode must be 6 digits.";
      validateStatus=false
    }

    if (!accountNumber) {
      error.accountNumber = "Field cannot be blank!";
      validateStatus = false;
    }else if(accountNumber<0){
      error.accountNumber = "Invalid!";
      validateStatus = false;
    }

    if (contactByEmail==null) {
      error.contactByEmail = "Please select one of these options!";
      validateStatus = false;
    }

    if (contactByPhone==null) {
      error.contactByPhone = "Please select one of these options!";
      validateStatus = false;
    }

    if (contactByPost==null) {
      error.contactByPost = "Please select one of these options!";
      validateStatus = false;
    }

    if (!mdd) {
      error.mdd = "Please select one of these options!";
      validateStatus = false;
    }

    setErrors(error);
    return validateStatus;
  };
  const SubmitHandler = async (e) => {
    e.preventDefault();
    const validateStatus = await validation();
    if (validateStatus === true) {
      service.donationAdd(values).then((response) => {
        if(response?.success==true){
          toast.success(response?.message, {
            position: "bottom-center",
            autoClose: 15000,
          })
          setValues({country:"GB",giftAidForm: false})
          setRate("0.00")
          setDonate("triggered")
          setCountryListOption(null)
        }else{
          toast.error(response?.message, {
            position: "bottom-center",
            autoClose: 15000,
          })
        }
      });
    }else{
      toast.error("Check the fields", {
        position: "bottom-center",
        autoClose: 15000,
      })
    }
  };



  return (
    <div className="donate-by-card">
      <ToastContainer />
      <div className="wrapper">
        <h1 className="heading">Your Donation</h1>
        <div className="currentDonationAmountContainer">
          <p>
            You are making a monthly donation of <span>£{rate}</span>.
          </p>
          {changeAmount ? (
            <div>
            <div className="inputContainer">
              <label>£</label>
              <input
                values={values || ""}
                name="paymentAmount"
                onChange={(e) => handleUpdate(e)}
                type="number"
              />
              <span onClick={() => changeAmountValueHandler()}>confirm</span>
            </div>
            {errors?.paymentAmount && (
                <span className="error d-block">{errors?.paymentAmount}</span>
              )}
            </div>
          ) : (
            <div className="buttonContainer">
              <button className="transparent" onClick={() => setChangeAmount(true)}>
                ChangeAmount
              </button>
              {errors?.paymentAmount && (
                <span className="error">{errors?.paymentAmount}</span>
              )}
            </div>
            
          )}
          
        </div>
        <ul className="donationFormContainer">
          <li className="giftSection">
            <div className="giftContainer">
              <h1 className="subHeading">Gift Aid</h1>
              <label htmlFor="gift" className="container">
                Make my donation a Gift Aid donation.
                <input
                  type="checkbox"
                  checked={values?.giftAidForm}
                  onChange={() =>
                    handleChange("giftAidForm", !values.giftAidForm)
                  }
                  id="gift"
                />
                <span className="checkmark"></span>
              </label>
              <p>
              Yes, I want to Gift Aid any donations made to Revelation Foundation now, in the future and in the past four years.
              </p>
              <p>
              I am a UK taxpayer and I understand that if I pay less Income and /or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in the relevant tax year, it is my responsibility to pay any difference. The Gift Aid claimed will be used to help fund the whole of Revelation Foundation’s work.
              </p>
              <p>
              I will update Revelation Foundation if my address or tax circumstances change in the future.
              </p>
            </div>
          </li>
          <li className="personDetails">
            <div className="personDetailsContainer">
              <h1 className="subHeading">Personal Details</h1>
              <form>
                <div className="oneByTwo">
                  <div className="Container">
                    <label htmlFor="fname">
                      First Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      id="fname"
                      value={values?.firstName || ""}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.firstName && (
                      <span className="error">{errors?.firstName}</span>
                    )}
                  </div>
                  <div className="Container">
                    <label htmlFor="lname">
                      Last Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      value={values?.lastName || ""}
                      id="lname"
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.lastName && (
                      <span className="error">{errors?.lastName}</span>
                    )}
                  </div>
                </div>
                <div className="oneByTwo">
                  <div className="Container">
                    <label htmlFor="email">
                      Email Address <span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      value={values?.email || ""}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.email && (
                      <span className="error">{errors?.email}</span>
                    )}
                  </div>
                  <div className="Container">
                    <label htmlFor="phone">
                      Phone <span>*</span>
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      value={values?.phone || ""}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.phone && (
                      <span className="error">{errors?.phone}</span>
                    )}
                  </div>
                </div>
                <div className="oneByTwo">
                  <div className="Container">
                    <label htmlFor="country">
                      Country <span>*</span>
                    </label>
                    <select
                      name="country"
                      id="country"
                      value={values?.country || ""}
                      onChange={(e) => handleUpdate(e)}
                    >
                      <option value="GB">United Kingdom</option>
                      {countryListOption}
                    </select>
                    {errors?.country && (
                      <span className="error">{errors?.country}</span>
                    )}
                  </div>
                  <div className="Container">
                    <label htmlFor="postCode">
                      PostCode <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      id="postCode"
                      value={values?.postalCode || ""}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.postalCode && (
                      <span className="error">{errors?.postalCode}</span>
                    )}
                  </div>
                </div>

                <div className="oneByTwo">
                  <div className="Container">
                    <label htmlFor="address1">
                      Address Line 1 <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="address1"
                      value={values?.address1 || ""}
                      id="address1"
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.address1 && (
                      <span className="error">{errors?.address1}</span>
                    )}
                  </div>
                  <div className="Container">
                    <label htmlFor="houseNo">
                      House No. <span>*</span>
                    </label>
                    <input
                      type="number"
                      name="houseNo"
                      id="houseNo"
                      value={values?.houseNo || ""}
                      onWheel={(e) => e.target.blur()}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.house && (
                      <span className="error">{errors?.house}</span>
                    )}
                  </div>
                </div>

                <div className="oneByTwo">
                  <div className="Container">
                    <label htmlFor="address2">Address Line 2</label>
                    <input
                      type="text"
                      name="address2"
                      value={values?.address2 || ""}
                      id="address2"
                      onChange={(e) => handleUpdate(e)}
                    />
                  </div>
                  <div className="Container">
                    <label htmlFor="address3">Address Line 3</label>
                    <input
                      type="text"
                      name="address3"
                      value={values?.address3 || ""}
                      id="address3"
                      onChange={(e) => handleUpdate(e)}
                    />
                  </div>
                </div>
                <div className="oneByTwo">
                  <div className="Container">
                    <label htmlFor="town">
                      Town <span>*</span>
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="town"
                      value={values?.city || ""}
                      onChange={(e) => handleUpdate(e)}
                    />
                    {errors?.city && (
                      <span className="error">{errors?.city}</span>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </li>
          <li className="contactSection">
            <div className="contactContainer">
              <h1 className="subHeading">Control How You Hear From Us</h1>
              <p>
                We would like to contact you from time to time to keep you
                informed of Revelation Foundation’s projects, fundraising
                activities and appeals. We will not share your data and you can
                unsubscribe at any time.
              </p>
              <div className="contactWay">
                <div className="left">
                  <span>May we contact you by email?</span>
                </div>
                <div className="right">
                  <label htmlFor="yes" className="container">
                    Yes
                    <input
                      type="radio"
                      id="yes"
                      required
                      checked={values?.contactByEmail == "true" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByEmail", "true")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="no" className="container">
                    no
                    <input
                      type="radio"
                      required
                      id="no"
                      checked={values?.contactByEmail == "false" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByEmail", "false")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {errors?.contactByEmail && (
                  <span className="error">{errors?.contactByEmail}</span>
                )}
              </div>
              <div className="contactWay">
                <div className="left">
                  <span>May we contact you by phone?</span>
                </div>
                <div className="right">
                  <label htmlFor="phoneYes" className="container">
                    Yes
                    <input
                      type="radio"
                      id="phoneYes"
                      checked={values?.contactByPhone == "true" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByPhone", "true")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="PhoneNo" className="container">
                    no
                    <input
                      type="radio"
                      id="PhoneNo"
                      checked={values?.contactByPhone == "false" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByPhone", "false")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {errors?.contactByPhone && (
                  <span className="error">{errors?.contactByPhone}</span>
                )}
              </div>
              <div className="contactWay">
                <div className="left">
                  <span>May we contact you by post?</span>
                </div>
                <div className="right">
                  <label htmlFor="PostYes" className="container">
                    Yes
                    <input
                      type="radio"
                      id="PostYes"
                      checked={values?.contactByPost == "true" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByPost", "true")}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <label htmlFor="PostNo" className="container">
                    no
                    <input
                      type="radio"
                      id="PostNo"
                      checked={values?.contactByPost == "false" ? "checked" : ""}
                      onChange={(e) => handleChange("contactByPost", "false")}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                {errors?.contactByPost && (
                  <span className="error">{errors?.contactByPost}</span>
                )}
              </div>
            </div>
          </li>
          <li className="accountDetails">
            <div className="accountDetailsContainer">
              <h1 className="subHeading">Set Up Direct Debit</h1>
              <form>
                <div className="oneByOne">
                  <label htmlFor="accountHolder">
                    Account Holder(s)<span>*</span>
                  </label>
                  <input
                    type="text"
                    id="accountHolder"
                    name="accountHolder"
                    value={values?.accountHolder || ""}
                    onChange={(e) => handleUpdate(e)}
                  />
                  {errors?.accountHolder && (
                    <span className="error">{errors?.accountHolder}</span>
                  )}
                </div>
                <div className="oneByOne">
                  <label htmlFor="sortCode">
                    Sortcode<span>*</span>
                  </label>
                  <input
                    type="text"
                    id="sortCode"
                    name="sortCode"
                    value={values?.sortCode || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleUpdate(e)}
                  />
                  {errors?.sortCode && (
                    <span className="error">{errors?.sortCode}</span>
                  )}
                </div>
                <div className="oneByOne">
                  <label htmlFor="accountNumber">
                    Account Number<span>*</span>
                  </label>
                  <input
                    type="number"
                    id="accountNumber"
                    name="accountNumber"
                    value={values?.accountNumber || ""}
                    onWheel={(e) => e.target.blur()}
                    onChange={(e) => handleUpdate(e)}
                  />
                  {errors?.accountNumber && (
                    <span className="error">{errors?.accountNumber}</span>
                  )}
                </div>
                <div className="dateSection">
                  <div className="left">
                    <span>
                      Monthly Donation Date <span>*</span>
                    </span>
                  </div>
                  <div className="right">
                    <label htmlFor="dateYes" className="container">
                      3rd
                      <input
                        type="radio"
                        id="dateYes"
                        checked={values?.mdd == "3" ? "checked" : ""}
                        onChange={(e) => handleChange("mdd", "3")}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor="dateNo" className="container">
                      18th
                      <input
                        type="radio"
                        id="dateNo"
                        checked={values?.mdd == "18" ? "checked" : ""}
                        onChange={(e) => handleChange("mdd", "18")}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  {errors?.mdd && (
                  <span className="error">{errors?.mdd}</span>
                )}
                </div>
              </form>
            </div>
          </li>
        </ul>
        <div className="donateButtonContainer">
          <button className="bg-button" onClick={(e) => SubmitHandler(e)}>
            Donate
          </button>
        </div>
      </div>
    </div>
  );
};

export default DonateByDebit;
