import React from 'react'
import { Link } from 'react-router-dom'

const NewsCard = ({title,logo_landscape,date,description,news_id}) => {
    let dates=new Date(date)
    let month=dates.toLocaleString('en-us', { month: 'short' })
    let year = dates.getFullYear()
    let day = dates.getDate().toString().length == 1 ? '0' + dates.getDate() : dates.getDate()
    let isoFormat = day + " " + month+ " " + year
    let newDate=isoFormat

    const replaceImage=(error)=>{
        error.target.src=require("../../images/thumb.png")
    }
  return (
    <div className='newsCard'>
        <div className="left">
            <div className="imageContainer">
                <img src={logo_landscape} alt="Thumbnail" onError={(e)=>replaceImage(e)} />
            </div>
        </div>
        <div className="right">
            <h1 className="title">{title}</h1>
            <p>{window.innerWidth>=1045?description.slice(0,300):description.slice(0,200) }{description.length>300&&"..."}</p>
            <div className="dateSection">
                <span className="icon">
                    <img src={require("../../images/icon-calendartime.png")} alt="Icon-Calender" />
                </span>
                <span className="date">{newDate}</span>
            </div>
            <div className="buttonContainer">
                <Link to={`/article-info/${news_id}`}>
                    <button className='bg-button'>Read More</button>
                </Link>
            </div>
        </div>
    </div>
  )
}

export default NewsCard