import axios from "axios";
const qs = require('query-string');


export const newsLetterAdd=(values,magazineId)=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };

  const data={
    email:values?.email?values?.email:null,
    first_name:values?.firstname,
    last_name:values?.lastname,
    phone:values?.phone,
    magazine_id:magazineId,
    contact_by_email:values?.contactByEmail,
    contact_by_post:values?.contactByPost,
    address:values?.address,
    postal_code:values?.postCode,
    country:values?.country
  }
  return axios
    .post(
      process.env.REACT_APP_API_URL + "account/newsletter/subscribe",
      qs.stringify(data),
      customConfig
    )
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      return [];
    });
}

export const magazineInfo=()=>{
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }
if (user_id) {
  uId = user_id;
}
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
};

return axios
  .get(
    process.env.REACT_APP_API_URL + "magazine/list",
    customConfig
  )
  .then((response) => {
    return response?.data;
  })
  .catch((error) => {
    return [];
  });
}