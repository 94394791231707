import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import CategoryTile from '../../../Components/CategoryTile/CategoryTile'
import * as service from "./service"
import parse from "html-react-parser";

const PresentersInfo = () => {
    const location=useLocation()
    const partner_id=location?.state?.id
    const [presenterDetails,setPresenterDetails]=useState()
    // const presentersList=useSelector((state)=>state?.presenters?.value)
    const [loading,setLoading]=useState(true)
    const loadingStatus=useSelector((state)=>state?.loading?.value)
    const [presentersList,setPresentersList]=useState()
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    useEffect(()=>{
        setLoading(true)
        service.getPartnerDetails(partner_id).then((response)=>{
            setPresenterDetails(response?.data)
            service.getPresentersList().then((presentersResponse)=>{
                setPresentersList(presentersResponse?.data)
                setLoading(false)
            })
        })
    },[location])
    // useEffect(()=>{
    //     if(!loadingStatus&&presenterDetails){
    //       setLoading(false)
    //     }else{
    //       setLoading(true)
    //     }
    //   },[presenterDetails,loadingStatus])
  return (
    <>
    {
        loading?(
            <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
        ):(

    <div className='presenters-info'>
        <div className="wrapper">
            <div className="imageContainer">
                <img src={`https://gizmeon.s.llnwi.net/vod/thumbnails/thumbnails/${presenterDetails?.partner_image}`} alt="Picture" />
            </div>
            <h1 className="name">{presenterDetails?.partner_name}</h1>
            <p className='presenter-info'>{parse(presenterDetails?.subtitle.split("\n").join("<br>"))}</p>
            <p className='presenter-details'>{presenterDetails?.title}</p>
            <div className="details">
                <h1>My Scripture</h1>
                <div className="description">
                    <p>{presenterDetails?.partner_description}</p>
                </div>
            </div>
            <div className="shows">
                {
                    presenterDetails?.shows?.length>0&&
                <CategoryTile 
                    title="Shows" 
                    cardType="descriptionCard"
                    shows={presenterDetails?.shows}
                    innerCard="innerCard"
                />
                }
                <CategoryTile 
                    title="Presenters" 
                    cardType="descriptionCard"
                    shows={presentersList}
                    presentersCard="presentersCard"
                    currentPartnerId={partner_id}
                    />
            </div>
        </div>
    </div>
        )
    }
    </>
  )
}

export default PresentersInfo