import React, { useEffect } from 'react'

const GiftAidInfo = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div className="giftAidInfo">
        <div className="wrapper">

            <h1 className="heading">Your Donation</h1>
            <div className="contentsContainer">
                <div className="imageContainer">
                    <img src={require("../../../../images/giftaid.png")} alt="Gift" />
                </div>
                <p>Gift Aid is a programme enabling registered charities, such as Revelation Foundation, to reclaim tax on a donation made by a UK taxpayer, effectively increasing the amount of the donation. Using Gift Aid means that the taxman will add 25p to every pound you give.</p>
                <p>If you are a UK tax payer, making a donation, and you would like us to claim Gift Aid, you need to let us know by ticking the relevant box on our form or, if you are giving by phone, by telling the operator that you would like us to claim.</p>
                <p>Gift Aid Declaration:</p>
                <p>By ticking the Gift Aid option you agree to the following:</p>
                <p>I confirm that I have paid or will pay an amount of Income Tax and/or Capital Gains Tax for the current year (6 April to 5 April) that is at least equal to the amount of tax that all the charities and Community Amateur Sports Clubs (CASCs) that I donate to will reclaim on my gifts for the current tax year. I understand that other taxes such as VAT and Council Tax do not qualify. I understand that the charity will reclaim 25p of tax on every £1 that I have given.</p>
                <p>For further information, visit https://www.gov.uk/donating-to-charity/gift-aid</p>
            </div>
        </div>
    </div>
  )
}

export default GiftAidInfo