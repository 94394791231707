import React, { useEffect, useState } from "react";
import { ReactComponent as PlusIcon } from "../../images/svg/plus.svg";
import ShowCard from "../ShowCard/ShowCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MagazineCard from "../MagazineCard/MagazineCard";
const CategoryTile = ({ title, cardType, shows, innerCard, presentersCard, currentPartnerId ,searchResultData,origin}) => {
  const [icon, setIcon] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const slicecount = 25;
  useEffect(() => {
    switch (title) {
      case "Featured":
        setIcon(<img src={require("../../images/icon-tv.png")} alt="Tv" />);
        break;
      case "Latest News":
        setIcon(<img src={require("../../images/icon-news.png")} alt="News" />);
        break;
      case "News":
        setIcon(<img src={require("../../images/icon-news.png")} alt="News" />);
        break;
      case "Shows":
        setIcon(<img src={require("../../images/icon-tv.png")} alt="Tv" />);
        break;
      case "Videos":
        setIcon(<img src={require("../../images/icon-tv.png")} alt="Tv" />);
        break;
      case "Magazines":
        setIcon(<img src={require("../../images/icon-magazine.png")} alt="Magazine" />);
        break;
      case "Favourites":
        setIcon(<img src={require("../../images/svg/star-red.svg").default} alt="Star" />);
        break;
    }
  }, []);
  const showMoreHandler = () => {
    if (title === "Latest News" ) {
      navigate("/latest-news");
    } else if (title === "Featured") {
      navigate("/featured");
    } 
    else if (title === "Presenters") {
      navigate("/presenters");
    } 
    else if(origin === "home" && title === "Shows")
    {
      navigate("/featured-shows");
    }
    else if ( origin !=="home" && (title === "News" || title === "Shows" || title === "Videos")) {
      navigate("/showAll", { state: searchResultData});
    }
    else {
      navigate("/showAll", { state: searchResultData});
    }
  }; 
  return (
    <div className={cardType == "magazineCard" ? "magazine tileContainer" : "tileContainer"}>
      <div className="head">
        <div className="left">
          <span className="title">{title == "Latest News" ? "News" : title}</span>
          <span className="icon">{icon}</span>
        </div>

        {cardType != "magazineCard" && (
          <div className="right">
            {location?.pathname !== "/favourites" && (
              <span
                className="icon"
                onClick={() => showMoreHandler()}
              >
                <PlusIcon />
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={
          cardType == "buttonCard" ? "buttonCard body" : cardType == "descriptionCard" ? "descriptionCard body" : "body"
        }
      >
        {cardType == "magazineCard" ? (
          <div className="mgzine">
            {shows?.map((item, index) => (
              <div className="item">
                <MagazineCard
                  key={index}
                  thumbnail={item?.logo}
                  title={item?.show_name}
                  url={item?.url}
                  data={item}
                  downloadButton={true}
                />
              </div>
            ))}
          </div>
        ) : (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={4}
            navigation={shows?.length > 4 ? true : false}
            watchSlidesProgress
            loop={false}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              981: {
                slidesPerView: 3,
                spaceBetween: 36,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 29,
              },
            }}
          >
            {presentersCard == "presentersCard"
              ? shows?.slice(0, slicecount)?.map((item, index) => {
                  if (item?.partner_id != currentPartnerId) {
                    return (
                      <SwiperSlide key={index}>
                        <div className={shows?.length > slicecount ? "item visibleShowAllButton" : "item"}>
                          <ShowCard
                            cardType={cardType}
                            title={item?.name}
                            description={item?.description}
                            thumbnail={item?.image}
                            innerCard="innerCard"
                            navigate={"presenter"}
                            id={item?.partner_id}
                          />
                          <div className="showMoreOverlay" 
                            onClick={() => showMoreHandler()}
                          
                          >
                            <h2>Show All {shows?.length}</h2>
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  }
                })
              : shows?.slice(0, slicecount)?.map((item, index) => (
                  <SwiperSlide key={index}>
                    {innerCard == "innerCard" ? (
                      <div className={shows?.length > slicecount ? "item visibleShowAllButton" : "item"}>
                        <ShowCard
                          cardType={cardType}
                          title={item?.show_name}
                          year={item?.year}
                          description={item?.synopsis}
                          thumbnail={item?.logo_thumb}
                          innerCard="innerCard"
                          id={
                            item?.vanity_url
                              ? item?.vanity_url
                              : item?.show_id && item?.video_id
                              ? item?.show_id
                              : item?.show_id
                          }
                          navigate={
                            title === "Favourites"
                              ? "show"
                              : cardType == "dateCard"
                              ? "show"
                              : cardType == "buttonCard"
                              ? "news"
                              : "show"
                          }
                          favourites={item?.watchlist_flag}
                          date={item?.schedule_date}
                          vanityUrl={item?.vanity_url}
                          data={item}
                          showId={item?.show_id}
                        />
                        <div className="showMoreOverlay"
                            onClick={() => showMoreHandler()}
                        >
                          <h2>Show All {shows?.length}</h2>
                        </div>
                      </div>
                    ) : (
                      <div className={shows?.length > slicecount ? "item visibleShowAllButton" : "item"}>
                        <ShowCard
                          cardType={cardType}
                          title={item?.show_name}
                          year={item?.year}
                          description={item?.synopsis}
                          thumbnail={item?.logo_thumb}
                          navigate={
                            cardType == "dateCard" && item?.show_id && item?.video_id
                              ? "video"
                              : cardType == "buttonCard"
                              ? "news"
                              : "show"
                          }
                          id={
                            cardType == "buttonCard" && item?.vanity_url
                              ? item?.vanity_url
                              : cardType == "buttonCard" && item?.news_id
                              ? item?.news_id
                              : item?.show_id && item?.video_id && item?.vanity_url
                              ? item?.vanity_url
                              : item?.show_id && item?.video_id
                              ? item?.video_id
                              : item?.vanity_url
                              ? item?.vanity_url
                              : item?.show_id
                          }
                          favourites={item?.watchlist_flag}
                          date={item?.schedule_date}
                          page="show"
                          vanityUrl={item?.vanity_url}
                          data={item}
                          showId={item?.show_id}
                        />
                        <div className="showMoreOverlay"
                            onClick={()=>showMoreHandler()}
                        >
                          <h2>Show All ({shows?.length})</h2>
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default CategoryTile;
