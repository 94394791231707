import { createSlice } from "@reduxjs/toolkit";

export const ScheduleRefreshSlice=createSlice({
    name:"scheduleRefresh",
    initialState:{
        value:""
    },
    reducers:{
        getSchedule:(state,action)=>{
            state.value=action.payload.scheduleRefresh
        }
    }
})

export const {getSchedule}=ScheduleRefreshSlice.actions
export default ScheduleRefreshSlice.reducer