import { createSlice } from "@reduxjs/toolkit";

export const accountDetailsSlice=createSlice({
    name:"accountDetails",
    initialState:{
        value:{}
    },
    reducers:{
        getAccountDetails:(state,action)=>{
            state.value=action.payload.accountDetails
        }
    }
})

export const {getAccountDetails}=accountDetailsSlice.actions
export default accountDetailsSlice.reducer