import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import NewsCard from '../../../Components/NewsCard/NewsCard'
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg"
import * as service from "./service"

const LatestNews = () => {
    const [newsData,setNewsData]=useState([])
    const [sliceCount,setSliceCount]=useState(6)
  const [loading,setLoading]=useState(true)
  const navigate = useNavigate()
    useEffect(()=>{
        setLoading(true)
        service.getNews().then((response)=>{
            setNewsData(response?.data)
            setLoading(false)
        })
    },[])
    
    const slicedNewsData=newsData?.slice(0,sliceCount)
    const showMoreHandler=()=>{
        setSliceCount(sliceCount+3)
    }
    
  return (
    <>
    {
        loading?(
            <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
        ):(

    <div className='latestNews'>
        <div className="wrapper">
            <div className="head">
                <div className="logoContainer" onClick={()=>navigate("/subscribe")}>
                    <img src={require("../../../images/rtimes.png")} alt="Logo" />
                </div>
            </div>
            <div className="body position-relative">
                <h1 className="heading">Latest News</h1>
                {
                    slicedNewsData?.map((data,index)=>(
                        <Link key={index} to={data?.vanity_url?`/article-info/${data?.vanity_url}`:`/article-info/${data?.news_id}`}>
                            <NewsCard
                                title={data?.title}
                                logo_landscape={data?.logo_landscape}
                                date={data?.schedule_date}
                                news_id={data?.vanity_url?data?.vanity_url:data?.news_id}
                                description={data?.description}
                            />
                        </Link>

                    ))
                }
                {
                    newsData?.length>sliceCount&&
                <div className="gradientBg">
                    <div className="buttonContainer text-center">
                        <button className="showMore" onClick={()=>showMoreHandler()}>
                            Show More
                            <DownArrow/>
                        </button>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
        )
    }
    </>
  )
}

export default LatestNews