import axios from "axios";

export const getPresentersList=()=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }

    const customConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": true,
          crossorigin: true,
          "access-token": token,
          uid: uId,
          pubid: process.env.REACT_APP_PUBID,
          country_code: countryCode,
          channelid: process.env.REACT_APP_CHANNELID,
          dev_id: deviceId,
          ip: ipaddress,
          device_type: "web",
        },
      };
      return axios.get(process.env.REACT_APP_API_URL + "partner/list",customConfig)
      .then((response)=>{
        return response?.data
      }).catch((err)=>{
        return []
      })
}

export const getPartnerDetails=(partner_id)=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }

    const customConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": true,
          crossorigin: true,
          "access-token": token,
          uid: uId,
          pubid: process.env.REACT_APP_PUBID,
          country_code: countryCode,
          channelid: process.env.REACT_APP_CHANNELID,
          dev_id: deviceId,
          ip: ipaddress,
          device_type: "web",
        },
      };
      return axios.get(process.env.REACT_APP_API_URL + "partner/"+partner_id+"/videos",customConfig)
      .then((response)=>{
        return response?.data
      }).catch((err)=>{
        return []
      })
}