import React, { useEffect, useRef, useState } from 'react'
import {ReactComponent as ShareIcon} from "../../../images/svg/share.svg"
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg"
import { FacebookShareButton, TwitterShareButton,EmailShareButton,WhatsappShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as service from "./service";
const HealingScriptures = () => {
    const [shareButton,setShareButton]=useState(false)
    const [data,setData]=useState()
    let shareRef = useRef(null)
  let shareIconRef = useRef(null)

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);

    useEffect(()=>{
        window.scroll(0,0)
        service.getHealingScriptures().then((response)=>{
            setData(response?.data)
        })
    },[])
  return (
    <div className='healingScriptures'>
        <div className="wrapper">
            <h1 className="heading">The Healing Scriptures</h1>
            <div className="imageContainer">
                <img src={require("../../../images/healing-scriptures.jpg")} alt="Picture" />
            </div>
            
            {/* {
                data?.data?.map((item,index)=>(

                    <div key={index} className="contents">
                        <h1 className="title">{item?.title}</h1>
                        <div className="details">
                            <p>{item?.description}</p>
                        </div>
                    </div>
                ))
            } */}
            <div className="contentsContainer">
            <div className="contents">
                <h1 className="title">Exodus 15:26</h1>
                <div className="details">
                    <p>I am the Lord who heals you.</p>
                </div>
            </div>

            <div className="contents">
                <h1 className="title">Exodus 23:25</h1>
                <div className="details">
                    <p>You shall serve the Lord your God and He shall bless your bread and water: and I will take sickness away from you.</p>
                </div>
            </div>
            <div className="contents">
                <h1 className="title">Psalm 103:1-5</h1>
                <div className="details">
                    <p>Bless the Lord oh my soul and forget not all His benefits. He forgives all your sins and heals all your diseases . He redeems your life from the pit and crowns you with love and compassion. He satisfies your desires with good things….so that your youth is renewed like the eagles.</p>
                </div>
            </div>
            <div className="contents">
                <h1 className="title">Psalm 107:20</h1>
                <div className="details">
                    <p>He sent His Word and healed them.</p>
                </div>
            </div>
            <div className="contents">
                <h1 className="title">Isaiah 53:5</h1>
                <div className="details">
                    <p>He was wounded for our transgressions, He was bruised for our iniquities, the chastisement of our peace was upon Him and by His stripes we are healed.</p>
                </div>
            </div>
            <div className="contents">
                <h1 className="title">Jeremiah 30:17</h1>
                <div className="details">
                    <p>For I will restore health unto you and heal you of your wounds, says the Lord.</p>
                </div>
            </div>
            </div>
            <div className="buttonContainer">
                <button ref={shareIconRef} onClick={()=>setShareButton(!shareButton)} className={shareButton?"active shareButton ":"shareButton"}>
                    <ShareIcon className='share'/>
                    <span>Share</span>
                    
                    <DownArrow className='active'/>
                </button>
                {
                        shareButton&&
                        <div className="shareContainer" ref={shareRef}>
                            <FacebookShareButton
                                url={window.location.href}
                            >
                                <span className='name facebook'>Facebook</span>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <span className='name twitter'>Twitter</span>
                            </TwitterShareButton>
                            <CopyToClipboard text={window.location.href}>
                                <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                            </CopyToClipboard>
                            <EmailShareButton
                                url={window.location.href}
                            >
                                <span className='name'>Mail</span>
                            </EmailShareButton>
                            <WhatsappShareButton  url={window.location.href}>
                          <span className='name whatsapp'>Whatsapp</span>
                          </WhatsappShareButton   >
                        </div>
                    }
            </div>         
        </div>
    </div>
  )
}

export default HealingScriptures