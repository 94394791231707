import React, { useEffect, useState } from "react";
import { playerToken } from '../../../CommonService'
import VideoJS from "../../../Components/VideoJsPlayer/VideoJS";

const OurStudio = () => {

  const [videoURL, setVideoURL] = useState()
  const [videoPlayer,setVideoPlayer]=useState()

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    window.scroll(0, 0)

    playerToken().then((tokenResponse) => {

      setVideoURL(`https://poppo.tv/playlist/playlist.m3u8?id=202211081667888111012345&token=${tokenResponse?.data?.data}&type=video`)
    })
  }, [])

  useEffect(()=>{
    if(videoURL){
        
        const videoJsOptions = {
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [{
              src: `${videoURL}`,
              type: 'application/x-mpegURL',
            }],
            poster:require("../../../images/thumb.png")
          };
        
        setVideoPlayer(<VideoJS options={videoJsOptions}   />)

    }
},[videoURL])

  return (
    <div className="ourStudio">
      <div className="wrapper">
        <div className="videoContainer">
          {videoPlayer}
        </div>
        <div className="contentsContainer">
          <div className="contents">
            <div className="details">
              <p>
                First, in 2015 Howard Conder scratched out some ideas of what he
                wanted the studio and offices to look like. Then, he proceeded
                to get some architectural drawings of these initial ideas, which
                were then made into a 3D animation by Jeremy Park.
              </p>
            </div>
          </div>
          <div className="contents">
            <div className="details">
              <p>
                Howard then hired the services of Fran Ruiz a Spanish architect
                with whom he already had dealings some years earlier. Fran
                provided the architectural plans and he also became the project
                manager for the construction work.
              </p>
            </div>
          </div>
          <div className="contents">
            <div className="details">
              <p>
                Next, Revelation TV began to raise funds for the project. We
                wanted to do the fundraising in three stages. 1st stage was to
                raise the funds to purchase the land and legal and planning
                permissions; 2nd stage was to raise the funds to construct the
                building, and the 3rd stage was to raise funds to equip the
                studios and integrate the same. The reason for these three
                stages was to make sure that we had sufficient funds to complete
                each stage to a point we could leave the project dormant if
                funds ceased to be raised or slowed down at any given time. This
                would allow us to pick up and continue at any time in the future
                in order to complete the project
              </p>
            </div>
          </div>
          <div className="contents">
            <div className="details">
              <p>
                The Revelation Foundation purchased the land in 2014. We began
                construction in 2017. In 2019 we began purchasing TV and Music
                equipment to add to our already-own kit.
              </p>
            </div>
          </div>
          <div className="contents">
            <div className="details">
              <p>
                We are now at the stage where we have completed the building
                work to a very high standard. The studios are not constructed to
                the usual industrial warehouse finish, but rather to a higher
                standard that would be as good as one’s home.
                <br />
                Aesthetically, the working environment is so pleasant to work in
                and conducive to being creative, a most enjoyable experience for
                everyone. Our facilities have a fresh air exchange system and
                air-conditioning, UPS and Generator.
              </p>
            </div>
          </div>
          <div className="contents">
            <h1 className="title">The layout is as follows:</h1>
            <div className="details list">
              <ul>
                <li>
                  <p>
                    On the ground floor is the reception with front and rear
                    entrances, and separate toilets for men and women.
                  </p>
                </li>
                <li>
                  <p>
                    Studio 1 is the largest of the five studios measuring
                    <span>
                      {" "}
                      Depth: 20m x Width: 23m x Height: 8.5m x (473m).{" "}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    Studio 1 control room with separate audio mixing room
                    measures
                    <span> Depth: 7 x Width: 5.5m x Height: 2.5m (40m). </span>
                  </p>
                </li>
                <li>
                  <p>
                    There is a large elevator which serves 4 floors and carries
                    a maximum weight of
                    <span>
                      {" "}
                      1,600 Kg - Depth: 2.4m x Width: 1.3m x Height: 2.3m{" "}
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    Please note that we have a weight restriction for heavy
                    vehicles due to the underground studios having limited floor
                    support. This weight restriction is
                    <span> 3.500Kg (Max Weight) </span>
                  </p>
                </li>
                <li>
                  <p>
                    Car parking on-site for 13 cars also street parking is
                    usually plentiful FOC.
                  </p>
                </li>
                <li>
                  <p>
                    On the lower ground floor are four Studios, and a Chill Out
                    area with some amenities for eating, two large dressing
                    rooms, one for men and one for women.
                  </p>
                </li>
                <li>
                  <p>
                    Technical areas
                    <span> Depth: 4.4m x Width: 6.8m x Height 3.1 (28m)</span>
                  </p>
                </li>
                <li>
                  <p>
                    Studio 2: with Control Room and the separate audio room are
                    permanently used for Revelation TV shows.
                  </p>
                </li>
                <li>
                  <p>
                    Studio 3 is the Virtual Reality studio (Green Screen) which
                    measures
                    <span> Depth 2.9m x Width: 9m x Height: 3m (80m)</span>
                  </p>
                </li>
                <li>
                  <p>
                    Control room for studio 3 measuring
                    <span>
                      {" "}
                      Depth: 6.7m x Weight: 3.2m x Height: 3.2m (22m).
                    </span>
                  </p>
                </li>
                <li>
                  <p>
                    Studio 4 is to be kitted out and should be online in the
                    near future. It measures
                    <span> Depth: 11m x Width: 10m x Height: 3.7m (103m)</span>
                  </p>
                </li>
                <li>
                  <p>
                    Studio 4 Control Room measures
                    <span> Depth: 4.3m x Width: 6m x Height; 3.4 (25m) </span>
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="contents">
            <h1 className="title">Music Recording Studio:</h1>
            <div className="details list">
              <ul>
                <li>
                  <p>
                    Control room measures
                    <span> Depth: 7.6m x Width: 6.8 x Height: 3m (52m) </span>
                    The control room has been acoustically treated and tuned to
                    a very high standard. This still has to have more equipment
                    installed.
                  </p>
                </li>
                <li>
                  <p>
                    Small ‘live’ room measures
                    <span>
                      {" "}
                      Depth: 6.20m x Width: 5m x Height: 2.95 m (25m).{" "}
                    </span>
                    Also available is a large ‘live’ area that measures
                    {" "}
                    <span>Depth: 11m x Width: 10m x Height: 3.7m (103m).{" "}</span>
                    This doubles up as Studio 4 when in required.
                  </p>
                </li>
                <li>
                  <div>

                    <p>
                      On the first floor (for Americans this is the floor above
                      the ground floor):
                    </p>
                    <ul>

                      <li>
                        <p>We have separate men's and women's toilets.</p>
                      </li>
                      <li>
                        <p>
                          We have a large office measuring
                          <span> Depth: 8.8m x Width: 9.2m x Height: 2.6m (84m)</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          A small management office measuring
                          <span> Depth: 5.3m x Width: 4m x Height: 2.7m (23m)</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          An Editors’ office measuring
                          <span> Depth: 5m x Width: 5m x Height: 2.6m (25m)</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          A grading room measuring
                          <span> Depth: 3.5m x Width: 3.5m x Height: 2.5m (12m)</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          A kitchen with a small dining area measuring
                          <span> Depth: 4.4m x Width: 5.1m x
                            Height 2.6m (23m)</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          There are separate toilets for men and women.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div>

                    <p>
                      Second floor (the top floor):
                    </p>
                    <ul>
                      <li>
                        <p>
                          On this floor, there is an area fenced off from the public with the air
                          conditioning and fresh air units housed and the generator and satellite
                          dishes.
                        </p>
                      </li>
                      <li>
                        <p>
                          The other half of the roof can be used for filming or chilling out. It measures{" "}
                          <span>10m x 10m (100m)</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Because of the energy crisis, we are adding more solar panels to the 78
                          installed on the main roof at the time of writing.
                        </p>
                      </li>
                      <li>
                        <p>
                          This is in order to reduce the electricity costs which make use of solar
                          power and being in Southern Spain where the sun shines nearly every day
                          of the year, it would be foolish of us not to take advantage of this fact by
                          adding more solar panels ASAP.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurStudio;
