const axios=require ("axios");
const qs = require('query-string');



export const scheduleDetails=()=>{
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }

  const customConfig = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": true,
        crossorigin: true,
        "access-token": token,
        uid: uId,
        pubid: process.env.REACT_APP_PUBID,
        country_code: countryCode,
        channelid: process.env.REACT_APP_CHANNELID,
        dev_id: deviceId,
        ip: ipaddress,
        device_type: "web",
      },
      params:{
        channel_id:370
    }
    };
    return axios.get(process.env.REACT_APP_API_URL + "schedule/guide/"+370,customConfig)
    .then((response)=>{
      return response?.data
    }).catch((err)=>{
      return []
    })
}
function scheduleReminder(id , flag) {
  var token = localStorage.getItem("access_token");
  let device_id = localStorage.getItem("deviceId");
  let ipaddress = localStorage.getItem("ipaddress");
  let uId=localStorage.getItem("guestUserId");
  let user_id=localStorage.getItem("userId"); 
  let countryCode = localStorage.getItem("country_code");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: device_id,
      ip: ipaddress,
      device_type: "web",
    },
  };
  const data = {
    schedule_id :id,
    cancel : flag
  }
  return axios
    .post(
      process.env.REACT_APP_API_URL + "schedule/remind", 
      qs.stringify(data),
      customConfig
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}



export const Service={
   
    scheduleDetails,
    scheduleReminder

}