import './App.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import LayoutsRoute from './Router/Routes';
import { useEffect, useState } from 'react';
import * as service from "./CommonService";
import Fingerprint2 from "fingerprintjs2";
import { getSessionId } from './Utils/utils';
import { useDispatch } from 'react-redux';
import { getDonationKey } from './Redux/DonationKey/DonationKeySlice';
import { getUser } from './Redux/User/userSlice';

const App=()=> {
  const [isAuthenticated,setIsAuthenticated]=useState(false)
  const user=localStorage.getItem("userId")
  const dispatch=useDispatch()
 useEffect(()=>{
    if(user){
        dispatch(getUser({
            user:user
        }))
    }
  },[user])
  useEffect(()=>{
    
      Fingerprint2.get((components)=>{
        var values=components.map((component)=>{
          return component.value
        })
        var murmur = Fingerprint2.x64hash128(values.join(""), 31);
        if(!localStorage.getItem("deviceId")){
          localStorage.setItem("deviceId", murmur);
        }
          service.getGeoInfo().then((response)=>{
            if(!localStorage.getItem("guestUserId")&&!user)
            {
              service.guestUser().then((gResponse)=>{
                if(gResponse){
                  service.authenticate().then(async (response)=>{
                    
                    if(response?.token){
                      setIsAuthenticated(true)
                      if(response?.donation_key){
                        dispatch(getDonationKey({
                          donationKey:response?.donation_key
                        }))
                      }
                    }
                    await getSessionId()
                    if(!localStorage.getItem("applaunch")){
                      service.applaunchEvent("POP01").then(()=>{})
                    }

                    service.analytics(gResponse?.data?.user_id).then(()=>{})

                  })
                }
              })
            }else{
              service.authenticate().then(async(response)=>{
               

                if(response?.token){
                  setIsAuthenticated(true)
                  if(response?.donation_key){
                    dispatch(getDonationKey({
                      donationKey:response?.donation_key
                    }))
                  }
                }
                await getSessionId()
                if(!localStorage.getItem("applaunch")){
                  service.applaunchEvent("POP01").then(()=>{})
                }

              })
            }
            
            
          })

      })
  },[])
  return (
    <div>
      {isAuthenticated&&<LayoutsRoute/>}
      
    </div>
  );
}

export default App;
