import React from "react";

const Spotify = () => {
  return (
    <div className="spotify">
      <div className="wrapper">
        <div className="top">
            <div className="head">
                <div className="left">
                    <h1>Spotify</h1>
                </div>
                <div className="right">
                    <div className="imageContainer">
                        <img src={require("../../../images/spotify_green.png")} alt="Spotify" />
                    </div>
                </div>
            </div>
        </div>
        <div className="bottom">
          <div className="podcastsContainer">
            <div className="podcast">
              <iframe
                src="https://open.spotify.com/embed/show/6kuZNoHEJ0OlbwxnHnrynq"
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="podcast">
              <iframe
                src="https://open.spotify.com/embed/show/7a2tplm0My9KgfpLkNC2pU"
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="podcast">
              <iframe
                src="https://open.spotify.com/embed/show/4cq4A10oKmNhWxYImOebUe"
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="podcast">
              <iframe
                src="https://open.spotify.com/embed/show/4VIIFiya4c0ZUfBy7zZWic"
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="podcast">
              <iframe
                src="https://open.spotify.com/embed/show/592UNLHv4OX9eZYkSuLITf"
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>

            <div className="podcast">
              <iframe
                src="https://open.spotify.com/embed/show/6cILo17vvUBUrI6HNsSNM0"
                width="100%"
                height="232"
                frameborder="0"
                allowtransparency="true"
                allow="encrypted-media"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spotify;
