import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as service from "./service"


const ResetPassword = () => {
    const [inputValues,setInputValues]=useState({
        newpassword:"",
        confirmpassword:""
    })
    const [newPasswordValidation,setNewPasswordValidation]=useState("")
    const [confirmPasswordValidation,setConfirmPasswordValidation]=useState("")
    const [successMessage,setSuccessMessage]=useState()
    const [validation,setValidation]=useState("")
    const {token}=useParams()
    const navigate=useNavigate()
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    const formValidation=(values)=>{
        let passwordRegex=  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;
        if(values?.newpassword&&values?.confirmpassword){

            if(values?.newpassword?.match(passwordRegex)){
                if(values.newpassword==values.confirmpassword){
                    return true
                }else{
                    return "confirm not match"
                }
            }else{
                return "regex not match"
            }
        }else{
            return "no data"
        }
        
    }
    const submitHandler=(e)=>{
        e.preventDefault()

       if(formValidation(inputValues)==true){
        setValidation(null)
        setConfirmPasswordValidation(null)
        setNewPasswordValidation(null)
        const data={
            newpassword:inputValues?.newpassword,
            token:token
        }
        service.resetPassword(data).then((response)=>{
            if(response?.success==true){
                
                if(response?.message=="Password changed!"){
                    setSuccessMessage(true)
                    setTimeout(()=>{
                        navigate("/register-login")
                    },2000)
                }
            }else{
                setValidation("Something went wrong try after some time")
            }
        })
       }else{
        if(formValidation(inputValues)=="no data"){
            setValidation("Fields cannot be blank!")
        }else if(formValidation(inputValues)=="regex not match"){
            setValidation(null)
            setNewPasswordValidation("Password should be 8-20 characters and include at least 1 uppercase letter,1 lowercase letter, 1 number and 1 special characters")
        }else if (formValidation(inputValues)=="confirm not match"){
            setValidation(null)
            setNewPasswordValidation(null)
            setConfirmPasswordValidation("Password not matching")
        }
       }

        
    }
  return (
      
      <div className='resetPassword'>
        <div className="wrapper">    
            <h1 className="heading">Reset Password</h1>
            <div className="contents">
                
                <div className="formSection">
                    <form>
                        
                            <div className="inputContainer">
                                <label htmlFor="newpassword">New Password</label>
                                <input type="password" id='newpassword' value={inputValues.newpassword || ""} onChange={(e)=>setInputValues({...inputValues,newpassword:e.target.value})} />
                                {
                                    newPasswordValidation &&
                                    <span className="error">{newPasswordValidation}</span>
                                }
                            </div>
                            <div className="inputContainer">
                                <label htmlFor="#confirmpassword">Confirm Password</label>
                                <input type="password" id='confirmpassword' value={inputValues.confirmpassword || ""} onChange={(e)=>setInputValues({...inputValues,confirmpassword:e.target.value})} />
                                {
                                    confirmPasswordValidation &&
                                    <span className="error">{confirmPasswordValidation}</span>
                                }
                            </div>
                       
                       {
                        validation &&
                        <span className="error text-center mb-3">
                            {validation}
                        </span>
                       }
                       
                        <div className="buttonContainer">
                            <button type='submit' className="bg-button" onClick={(e)=>submitHandler(e)}>Reset Password</button>
                        </div>
                        {
                            successMessage &&
                            <span className="success text-success">
                                Password Changed Successfully
                            </span>
                        }
                    </form>
                </div>
            </div>
        </div>
    </div>
  );
};

export default ResetPassword;
