import React from 'react';
import { useNavigate } from 'react-router-dom';
import {ReactComponent as ArrowDown} from "../../images/svg/arrow-down.svg"

const MagazineCard = ({thumbnail,title,url,data,downloadButton}) => {
  const navigate = useNavigate()

  return (
    <div className='magazineCard' title={title}>
        <div className="top" onClick={()=>navigate("/subscribe",{state:data})}>
            <div className="imageContainer">
                <img src={thumbnail} alt="Thumbnail" />
            </div>
        </div>
        <div className="bottom">
            {/* <h3 className="title">
                Magazine Title
            </h3> */}
            <p onClick={()=>navigate("/subscribe",{state:data})}>{title}</p>
            {
                downloadButton &&
            <div className="buttonContainer">
                <button onClick={()=>window.open(url,'_blank')}>
                    <ArrowDown/>
                    Download
                </button>
            </div>
            }
        </div>
    </div>
  )
}

export default MagazineCard