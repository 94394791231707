import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ShowCard from '../../../Components/ShowCard/ShowCard'
import * as service from "./service"
const Featured = () => {
    const [allShows,setAllShows]=useState()
  const [loading,setLoading]=useState(true)
    useEffect(()=>{
        setLoading(true)
        service.featured().then((response)=>{
            setAllShows(response?.data)
            setLoading(false)
        })
    },[])
  return (
    <>
        {
            loading?(
                <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
            ):(

    <div className='allshows'>
        <div className="wrapper">
            <h1 className="heading">Featured</h1>
            {
                !allShows&&
            <h1 className="nodatas">No Featured</h1>
            }
            <div className="showsList">
                {
                    allShows?.map((data,index)=>(
                        <div className="items" key={index}>

                            <ShowCard
                                cardType="dateCard"
                                title={data?.show_name}
                                thumbnail={data?.logo_thumb}
                                description={data?.synopsis}
                                date={data?.schedule_date}
                                navigate={data?.show_id&&data?.video_id?"video":"show"}
                                id={data?.vanity_url&&data?.video_id&&data?.show_id?data?.vanity_url:data?.video_id&&data?.show_id?data?.video_id:data?.vanity_url?data?.vanity_url:data?.show_id}
                                
                            />
                        </div>
                    ))
                }
                    
            </div>
        </div>
    </div>
            )
        }
    </>
  )
}

export default Featured