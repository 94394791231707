import React, { useMemo } from "react";
import { ReactComponent as ArrowDown } from "../../../images/svg/arrow-down.svg";
import * as service from "./service";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import countryList from "react-select-country-list";

const Subscribe = () => {
  const [inputValues, setInputValues] = useState({
    firstname: "",
    lastname: "",
    email: null,
    phone: "",
    contactByEmail: false,
    contactByPost: false,
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState(false);
  const [magazineData, setMagazineData] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [countryListOption, setCountryListOption] = useState([]);
  const [countryName, setCountryName] = useState({
    value: "GB",
    label: "United Kingdom",
  });
  const countries = useMemo(() => countryList().getData(), []);

  useEffect(() => {
    setCountryListOption(
      countries?.map((item, index) => {
        const { value, label } = item;

        if (value != countryName?.value) {
          return (
            <option key={index} value={value}>
              {label}
            </option>
          );
        }
      })
    );
  }, [inputValues?.country]);

  useEffect(() => {
    window.scroll(0, 0);
    if (location?.state) {
      setLoading(false);
      setMagazineData(location?.state);
    } else {
      setLoading(true);
      service.magazineInfo().then((response) => {
        if (response?.success === true) {
          setMagazineData(response.data[0]);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
    }
  }, [location.state]);

  useEffect(()=>{
    if(magazineData){
      if(magazineData?.magazine_id === "68"){
        setInputValues({...inputValues,country:"GB",contactByPost:true})
      }
    }
  },[magazineData])
  const formValidation = () => {
    let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let nameRegex = /^[a-zA-Z ]{3,30}$/;
    let error = {};
    let validateStatus = true;
    const {
      firstname,
      lastname,
      email,
      contactByEmail,
      contactByPost,
      address,
      postCode,
      country,
    } = inputValues;

    if (!firstname) {
      error.firstname = "Field cannot be blank!";
      validateStatus = false;
    } else if (!firstname?.match(nameRegex)) {
      error.firstname =
        "Name should be 3-30 characters and shouldn't include any special character";
      validateStatus = false;
    }

    if (!lastname) {
      error.lastname = "Field cannot be blank!";
      validateStatus = false;
    } else if (!lastname?.match(nameRegex)) {
      error.lastname =
        "Name should be 3-30 characters and shouldn't include any special character";
      validateStatus = false;
    }

    if(magazineData?.magazine_id !== "68"){
      if (!email) {
        error.email = "Field cannot be blank!";
        validateStatus = false;
      } else if (!email?.match(emailRegex)) {
        error.email = "Please enter a valid email address!";
        validateStatus = false;
      }
      if (contactByPost) {
        if (!address) {
          error.address = "Field cannot be blank!";
          validateStatus = false;
        }
        if (!postCode) {
          error.postCode = "Field cannot be blank!";
          validateStatus = false;
        }
        if (!country) {
          error.country = "Field cannot be blank!";
          validateStatus = false;
        }
      }
  
      if (!contactByEmail && !contactByPost) {
        error.contact = "Please select either Email or Post";
        validateStatus = false;
      }
    }else{
      if (!address) {
        error.address = "Field cannot be blank!";
        validateStatus = false;
      }
      if (!postCode) {
        error.postCode = "Field cannot be blank!";
        validateStatus = false;
      }
      if (!country) {
        error.country = "Field cannot be blank!";
        validateStatus = false;
      }
    }


    
    setErrors(error);
    return validateStatus;
  };
  const subscribeHandler = (e) => {
    e.preventDefault();

    if (formValidation() == true) {
      service
        .newsLetterAdd(inputValues, magazineData?.magazine_id)
        .then((response) => {
          if (response?.success == true) {
            setInputValues({...inputValues,
              firstname: "",
              lastname: "",
              email: "",
              phone: "",
              address: "",
              contactByEmail: false,
              postCode:"",
              contactByPost: magazineData?.magazine_id==="68"?true:false,
            });
            setSuccessMessage(true);
            setTimeout(() => {
              setSuccessMessage(false);
            }, 3000);
          }
        });
    }
  };
  if (loading) {
    return (
      <div className="loadingContainer">
        <div className="loadingOverlay"></div>
        <div className="spinnerIcon">
          <div className="inner lds-dual-ring"></div>
        </div>
      </div>
    );
  }
  return (
    <div className="subscribe">
      <div className="wrapper">
        <div className="subscribeContainer">
          {/* <div className="logoContainer">
            <img src={require("../../../images/rtimeslogo.png")} alt="Logo" />
          </div> */}
          <h1 className="heading">
            {magazineData?.show_name === "Life is Important"?"Receive ":"Subscribe to "}
            {magazineData?.show_name
              ? magazineData?.show_name
              : magazineData?.name}
          </h1>
          <div className="formSection">
            <form>
              <div className="inputSections">
                <div className="half">
                  <div className="inputContainer">
                    <label htmlFor="fname">First Name</label>
                    <input
                      type="text"
                      value={inputValues.firstname || ""}
                      onChange={(e) =>
                        setInputValues({
                          ...inputValues,
                          firstname: e.target.value,
                        })
                      }
                      id="fname"
                    />
                    {errors?.firstname && (
                      <span className="error text-danger">
                        {errors?.firstname}
                      </span>
                    )}
                  </div>
                  <div className="inputContainer">
                    <label htmlFor="lname">Last Name</label>
                    <input
                      type="text"
                      id="lname"
                      value={inputValues.lastname || ""}
                      onChange={(e) =>
                        setInputValues({
                          ...inputValues,
                          lastname: e.target.value,
                        })
                      }
                    />
                    {errors?.lastname && (
                      <span className="error text-danger">
                        {errors?.lastname}
                      </span>
                    )}
                  </div>
                </div>
                {magazineData?.magazine_id === "68" ? (
                  <>
                    <div className="half">
                     
                      <div className="inputContainer">
                        <label htmlFor="phone">Phone(Optional)</label>
                        <input
                          type="text"
                          id="phone"
                          value={inputValues.phone || ""}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              phone: e.target.value,
                            })
                          }
                        />
                      </div>
                     
                          <div className="inputContainer">
                            <label htmlFor="address">Address</label>
                            <input
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  address: e.target.value,
                                })
                              }
                              name="address"
                              id="address"
                              type="text"
                              value={inputValues?.address || ""}

                            ></input>
                            {errors?.address && (
                              <span className="error text-danger">
                                {errors?.address}
                              </span>
                            )}
                          </div>
                       
                    </div>
                      <>
                        <div className="half">
                         
                          <div className="inputContainer">
                            <label htmlFor="postCode">Postcode</label>
                            <input
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  postCode: e.target.value,
                                })
                              }
                              name="postCode"
                              id="postCode"
                              type="text"
                              value={inputValues?.postCode || ""}

                            ></input>
                            {errors?.postCode && (
                              <span className="error text-danger">
                                {errors?.postCode}
                              </span>
                            )}
                          </div>
                          <div className="inputContainer">
                            <label htmlFor="country">Country</label>
                            <select
                              name="country"
                              id="country"
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  country: e.target.value,
                                })
                              }
                            >
                              {countryName?.label && (
                                <option value={countryName?.value}>
                                  {countryName?.label}
                                </option>
                              )}
                              {countryListOption}
                            </select>
                            {errors?.country && (
                              <span className="error text-danger">
                                {errors?.country}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                  </>
                ) : (
                  <>
                    <div className="half">
                      <div className="inputContainer">
                        <label htmlFor="email">Email Address</label>
                        <input
                          type="email"
                          id="email"
                          value={inputValues.email || ""}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              email: e.target.value,
                            })
                          }
                        />
                        {errors?.email && (
                          <span className="error text-danger">
                            {errors?.email}
                          </span>
                        )}
                      </div>
                      <div className="inputContainer">
                        <label htmlFor="phone">Phone(Optional)</label>
                        <input
                          type="text"
                          id="phone"
                          value={inputValues.phone || ""}
                          onChange={(e) =>
                            setInputValues({
                              ...inputValues,
                              phone: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    {inputValues?.contactByPost && (
                      <>
                        <div className="half">
                          <div className="inputContainer">
                            <label htmlFor="address">Address</label>
                            <input
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  address: e.target.value,
                                })
                              }
                              name="address"
                              id="address"
                              type="text"
                              value={inputValues?.address || ""}
                            ></input>
                            {errors?.address && (
                              <span className="error text-danger">
                                {errors?.address}
                              </span>
                            )}
                          </div>
                          <div className="inputContainer">
                            <label htmlFor="postCode">Postcode</label>
                            <input
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  postCode: e.target.value,
                                })
                              }
                              name="postCode"
                              id="postCode"
                              type="text"
                              value={inputValues?.postCode || ""}

                            ></input>
                            {errors?.postCode && (
                              <span className="error text-danger">
                                {errors?.postCode}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="half">
                          <div className="inputContainer">
                            <label htmlFor="country">Country</label>
                            <select
                              name="country"
                              id="country"
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  country: e.target.value,
                                })
                              }
                            >
                              {countryName?.label && (
                                <option value={countryName?.value}>
                                  {countryName?.label}
                                </option>
                              )}
                              {countryListOption}
                            </select>
                            {errors?.country && (
                              <span className="error text-danger">
                                {errors?.country}
                              </span>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>

              <div className="full">
                <p>
                  We will not share your data and you can unsubscribe at any
                  time. We will keep your personal information secure. If, at
                  any time, you would like to change how we contact you, you can
                  e-mail us at info@revelationtv.com.
                </p>
                <p>
                  You can also call us on +44 (0) 20 8972 1400 or +1 737 600
                  8222 .
                </p>
              </div>
              {
                magazineData?.magazine_id !== "68"&&
                <>
                
                <p className="contact">How can we contact you?</p>
              <div className="checkboxSection">
                <div className="inputContainer">
                  <label htmlFor="post" className="Squarecontainer">
                    Post
                    <input
                      id="post"
                      type="checkbox"
                      checked={inputValues?.contactByPost}
                      onChange={() =>
                        setInputValues({
                          ...inputValues,
                          contactByPost: !inputValues?.contactByPost,
                          country: !inputValues?.contactByPost ? "GB" : null,
                        })
                      }
                    />
                    <span className="Squarecheckmark"></span>
                  </label>
                </div>
                
                <div className="inputContainer">
                  <label
                    htmlFor="email"
                    className="Squarecontainer"
                    onClick={() =>
                      setInputValues({
                        ...inputValues,
                        contactByEmail: !inputValues?.contactByEmail,
                      })
                    }
                  >
                    Email
                    <input
                      id="p"
                      type="checkbox"
                      checked={inputValues?.contactByEmail}
                    />
                    <span className="Squarecheckmark"></span>
                  </label>
                </div>
              </div>
              {errors?.contact && (
                <span className="error contact text-danger">
                  {errors?.contact}
                </span>
              )}
                </>
              }
              {successMessage && (
                <span className="success text-success">
                  Subscribed successfully
                </span>
              )}
              <div className="buttonContainer">
                <button
                  className="bg-button"
                  type="submit"
                  onClick={(e) => subscribeHandler(e)}
                >
                   {magazineData?.show_name === "Life is Important"?"Send ":"Subscribe"}
                </button>
              </div>
            </form>
          </div>

          <div className="magazineSection">
            <div className="left">
              <div className="imageContainer">
                <img
                  src={magazineData?.image || magazineData?.logo}
                  alt="Magazine"
                />
              </div>
            </div>
            <div className="right">
              <h1>
                Download the latest{" "}
                {magazineData?.show_name
                  ? magazineData?.show_name
                  : magazineData?.name}
              </h1>
              <div className="downloadbuttonContainer">
                <button onClick={() => window.open(magazineData.url, "_blank")}>
                  <ArrowDown />
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
