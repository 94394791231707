import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PaymentSuccess = () => {
    const navigate=useNavigate()
  return (
    <div className='success'>
        <div className="successContainer">

        <div className="checkIcon"> 
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" fill="#fff" className="bi bi-check2" viewBox="0 0 16 16">
            <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"/>
        </svg>
        </div>
        <h5>Success</h5>
        <p>Your payment has successfully completed</p>
        <button onClick={()=>navigate("/")}>OK</button>
        </div>
    </div>
  )
}

export default PaymentSuccess