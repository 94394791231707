import axios from "axios";
const qs = require('query-string');


export const accountDetails=()=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }
  
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios.get(`${process.env.REACT_APP_API_URL}account/details`,customConfig)
  .then((response)=>{
    return response?.data
  }).catch((err)=>{
    return [];
  })
}

export const userDetailUpdate=(values)=>{
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
};
const formData = new FormData();

if (values?.newpassword) {
  formData.append("password", values?.currentpassword);
  formData.append("new_password", values?.newpassword);
  
}else{
  formData.append("password", values?.currentpassword);
  formData.append("file", values?.file);
  formData.append("country_code", values?.country);
  formData.append("first_name", values?.firstname);
  formData.append("last_name", values?.lastname);
}
const data={
  first_name:values?.firstname,
  country_code:values?.country,
  last_name:values?.lastname,
  password:values?.currentpassword,
  new_password:values?.newpassword,
  
}
return axios.post(`${process.env.REACT_APP_API_URL}user/update`,
formData,
customConfig)
.then((response)=>{
  return response?.data
}).catch((err)=>{
  return err?.response?.data
})
}

