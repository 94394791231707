import axios from "axios";
import { getDeviceType ,getBrowserType } from "./Utils/utils";
const qs = require('query-string');

export const getGeoInfo=()=>{
    return axios.get("https://giz.poppo.tv/service/ipinfo")
    .then((response)=>{
        if(response?.data){
            localStorage.setItem("country_code",response?.data?.countryCode,30)
            localStorage.setItem("ipaddress",response?.data?.query, 30)
            let currentLocation = {};
            currentLocation["country_name"] = response?.data?.country;
            currentLocation["city"] = response?.data?.city;
            currentLocation["latitude"] = response?.data?.lat;
            currentLocation["longitude"] = response?.data?.lon;
            currentLocation["IPv4"] = response?.data?.query;
            currentLocation["state"] = response?.data?.region;
            localStorage.setItem(
              "currentLocation",
              JSON.stringify(currentLocation)
            );
        }
    })
}

export const guestUser=()=>{
    let countryCode = localStorage.getItem("country_code");
  let ipaddress = localStorage.getItem("ipaddress");
  let deviceId = localStorage.getItem("deviceId");
  const customConfig = {
    headers: {
      pubid: process.env.REACT_APP_PUBID,
      channelid: process.env.REACT_APP_CHANNELID,
      country_code: countryCode,
      device_type: "web",
      dev_id: deviceId,
      ip: ipaddress,
      ua: navigator.userAgent,
    },
  };
    return axios.post(process.env.REACT_APP_API_URL + "account/register/guest","",customConfig)
    .then((response)=>{
        localStorage.setItem("guestUserId", response?.data?.user_id);
        return true
    }).catch(()=>{
        return false;
    })
}

export const authenticate=()=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    if(user_id){
        uId=user_id
    }
    const customConfig = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": true,
          crossorigin: true,
          uid: uId,
          pubid: process.env.REACT_APP_PUBID,
          country_code: countryCode,
          channelid: process.env.REACT_APP_CHANNELID,
          dev_id: deviceId,
          ip: ipaddress,
          device_type: "web",
        },
      };
      return axios.get(process.env.REACT_APP_API_URL + "account/authenticate",customConfig)
      .then((response)=>{
        if(response?.data?.message=="Invalid user"){

        }else{
            localStorage.setItem("access_token",response?.data?.token)
            return response.data;
        }
      })
}

export const logoutAll=(user_id) =>{
  let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    var token = localStorage.getItem("access_token");

  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: user_id,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "account/logoutall", customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
export const logout=(user_id) =>{
  let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    var token = localStorage.getItem("access_token");

  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: user_id,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "account/logout", customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}
export const playerToken=()=> {
  var token = localStorage.getItem("access_token");
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };

  return axios
    .get("https://poppo.tv/proxy/api/GenerateToken", customConfig)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return [];
    });
}

export const getShowDetails=(showId)=>{
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
};
return axios.get(`${process.env.REACT_APP_API_URL}show/${showId}`,customConfig)
.then((response)=>{
  return response?.data
}).catch((err)=>{
  return [];
})
}

export const getShows=(key) => {
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
    params: {
      key: key,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "show/search", customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export const updateFavourites=(id,flag) => {
  let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }
  
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios.get(`${process.env.REACT_APP_API_URL}watchlist/show/${id}/${flag}`,customConfig)
  .then((response)=>{
    return response?.data
  }).catch((err)=>{
    return [];
  })
}

export const getTopBanners=() => {
  let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }
  
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
    params:{
      type:"top"
    }
  };
  return axios.get(`${process.env.REACT_APP_API_URL}banner/list`,customConfig)
  .then((response)=>{
    return response?.data
  }).catch((err)=>{
    return [];
  })
}

export const logoutFunction=(user) => {
  
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    var token = localStorage.getItem("access_token");
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: user,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  return axios.get(`${process.env.REACT_APP_API_URL}account/logout`,customConfig)
  .then((response)=>{
    return response?.data
  }).catch((err)=>{
    return [];
  })
}

export const analytics=(Response_uid,Response_email,userName) => {
  let countryCode=localStorage.getItem("country_code");
  localStorage.setItem("device_analytics", "true");
  let uId=localStorage.getItem("guestUserId");
  let user_id = (Response_uid) ? Response_uid : localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let deviceId=localStorage.getItem("deviceId");
  var token = localStorage.getItem("access_token");
  let sessionId = localStorage.getItem("session_id");
  let userEmail = (Response_email)? Response_email : "";
 
  let appid = "150";
  let deviceType = getDeviceType();
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let browserType = getBrowserType();
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
  let currentLocation = JSON.parse(localStorage.getItem("currentLocation"));
  const data = {
    session_id: sessionId,
    device_id: deviceId,
    publisherid: process.env.REACT_APP_PUBID,
    channelid: process.env.REACT_APP_CHANNELID,
    app_id: appid,
    user_id: uId,
    ip_address: currentLocation.IPv4,
    ua: navigator.userAgent,
    timestamp: ctime,
    country: currentLocation.country_name,
    device_type: deviceType,
    city: currentLocation.city,
    latitude: currentLocation.latitude,
    longitude: currentLocation.longitude,
    width: window.innerWidth,
    height: window.innerHeight,
    device_make: navigator.userAgent,
    device_model: navigator.userAgent,
    browser: browserType,
    user_name: userName&&userName,
    user_email: userEmail?userEmail:"",
    user_contact_number: "",
    country_code: countryCode,
  };
  return axios
    .post("https://analytics.poppo.tv/device", qs.stringify(data), customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}

export const  applaunchEvent=(event)=> {
  let sessionId = localStorage.getItem("session_id");
  let uId = localStorage.getItem("guestUserId");
  let user_id = localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let device_id = localStorage.getItem("deviceId");
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let appid = "150"; //revelation
  const requestBody = {
    app_id: appid,
    timestamp: ctime,
    device_id: device_id,
    event_type: event,
    publisherid: process.env.REACT_APP_PUBID,
    user_id: uId,
    channel_id: process.env.REACT_APP_CHANNELID,
    session_id: sessionId,
  };
  var token = localStorage.getItem("access_token");
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
 
  return axios
    .post(
      "https://analytics.poppo.tv/event",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      localStorage.setItem("applaunch", true);
      return response.data;
    })
    .catch((error) => {
      localStorage.removeItem("applaunch");
      return [];
    });
}

export const magazineInfo=()=>{
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }
if (user_id) {
  uId = user_id;
}
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
};

return axios
  .get(
    process.env.REACT_APP_API_URL + "magazine/list",
    customConfig
  )
  .then((response) => {
    return response?.data;
  })
  .catch((error) => {
    return [];
  });
}