import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-ads';
import 'videojs-ima';
import "videojs-sprite-thumbnails"; // videoJs Thumbnail Plugin

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);
  const {options, onReady} = props;
  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);
      });
      if(props?.hoverThumbnail){
        player.spriteThumbnails({
          url: props?.hoverThumbnail,
          width: 160,
          height: 90,
          interval: 1
        });

      }
      

    // You could update an existing player in the `else` block here
    // on prop change, for example:
    }
  }, [options, videoRef]);
  

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;
    if(props?.adurl){

      var imaOptions = {
        adTagUrl: props?.adurl
      };
  
      player.ima(imaOptions)
      
    }
    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
        
      }
    };
  }, [videoRef]);

  return (
    <div data-vjs-player>
      <video ref={videoRef} className='video-js vjs-big-play-centered' />
    </div>
  );
}

export default VideoJS;