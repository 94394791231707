import React, { useEffect } from 'react'

const Contact = () => {
    useEffect(()=>{
        window.scroll(0,0)
    },[])
  return (
    <div className='contact'>
        <div className="wrapper">
            <h1 className="heading">
                Get in Touch
            </h1>
            <div className="contactContainer">
                <div className="left">
                    <h1>UK Contact Info</h1>
                    <div className="UKContactContainer">
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-location.png")} alt="Location" />
                            </div>
                            <span>Suite 124, 61 Victoria Road, Surbiton. KT6 4JX. UK.</span>
                        </div>
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-tel.png")} alt="Tel" />
                            </div>
                            <span>Tel: +44 (0) 208 972 1400</span>
                        </div>
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-mail.png")} alt="Mail" />
                            </div>
                            <a href='mailto:info@revelationtv.com'>info@revelationtv.com</a>
                        </div>
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-chat.png")} alt="chat" />
                            </div>
                            <div className="prayer">
                                <span>Need prayer? call us on:</span>
                                <a href='tel:+4402089721403'>+44 (0) 208 972 1403</a>
                                <span>or email</span>
                            <a href='mailto:prayer@revelationtv.com'>prayer@revelationtv.com</a>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="right">
                    <h1>USA Contact Info</h1>
                    <div className="USAContactContainer">
                        <div className="oneByTwo">
                            <div className="logoContainer location">
                                <img src={require("../../../images/icon-location.png")} alt="Location" />
                            </div>
                            <span>P.O. Box 12836, Beaumont, Texas 77726, USA</span>
                        </div>
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-tel.png")} alt="Tel" />
                            </div>
                            <span>Tel: 1-737-600-8222 (US Local Rate)</span>
                        </div>
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-mail.png")} alt="Mail" />
                            </div>
                            <a href='mailto:info@revelationtv.com'>info@revelationtv.com</a>
                        </div>
                        <div className="oneByTwo">
                            <div className="logoContainer">
                                <img src={require("../../../images/icon-chat.png")} alt="chat" />
                            </div>
                            <div className="prayer">
                                <span>Need prayer? call us on:</span>
                                <a href='tel:+17376008222' className='tel'>1-737-600-8222 (US Local Rate)</a>
                                <span>or email</span>
                            <a href='mailto:prayer@revelationtv.com'>prayer@revelationtv.com</a>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="technicalSupport">
                    <span>For technical support,<span onClick={()=>window.open("http://support.revelationtv.com/","_blank")}>Click Here</span></span>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default Contact