import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import CategoryTile from '../../../Components/CategoryTile/CategoryTile'
import { getShows } from '../../../CommonService'
const SearchResult = () => {
  const location=useLocation()
  const [searchData,setSearchData]=useState([])
  const [searchKey,setSearchKey]=useState()
  const params=new URLSearchParams(location?.search)
  const q=params?.get("q")
  const [loading,setLoading]=useState(true)
  useEffect(()=>{
    setLoading(true)
    getShows(q).then((response)=>{
      if(response?.success==true){

        setSearchData(response?.data)
        setLoading(false)
      }else{
        setLoading(false)
      }
    })
  },[q])
  return (
    <>
    {
      loading ?(
        <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
      ):(

    <div className='searchResult'>
        <div className="wrapper">
            <h1 className="heading">Search Results({q})</h1>
            {
              searchData?.map((data,index)=>(
                <CategoryTile 
                  cardType={data?.category_name=="Shows"?"descriptionCard":data?.category_name=="Videos"?"dateCard":"buttonCard"} 
                  title={data?.category_name}
                  shows={data?.shows}
                  key={index}
                  searchResultData = {data}
                />

              ))
            }
            {
              searchData?.length<=0&&
            <h1 className='noData'>No data</h1>
            }
        </div>
    </div>
      )
    }
    </>
  )
}

export default SearchResult