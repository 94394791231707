import React, { useEffect, useState , useRef } from 'react';
import {ReactComponent as ShareIcon} from "../../../images/svg/share.svg";
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg";
import { FacebookShareButton, TwitterShareButton,EmailShareButton,WhatsappShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import * as service from "./service";
const StatementOfFaith = () => {
    const [shareButton,setShareButton]=useState(false)
    const [data,setData]=useState()
    let shareRef = useRef(null)
  let shareIconRef = useRef(null)

  useEffect(()=>{
    window.scroll(0,0)
  },[])

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);

  return (
    <div className='statementOfFaith'>
        <div className="wrapper">
            <div className="imageContainer">
                <img src={require("../../../images/statement-of-faith.png")} alt="Picture" />
            </div>

            <p className='head'>We believe that Man’s primary purpose is to glorify God and to love and enjoy Him forever.</p>
            <div className="contentsContainer">

                <div className="contents">
                    <h1 className="title">The Holy Bible</h1>
                    <div className="details">
                        <p>The Holy Bible is the Word of God and is made up of the Old and New Testaments. The Word of God, as revealed by the scriptures of the Holy Bible, is infallible; particularly in the original Hebrew and Greek languages. We believe, also, that ALL scripture is inspired by the Holy Spirit and beneficial for teaching, reproving and correction, so that the man and woman of God can be fully competent and equipped for every good work. The Bible primarily teaches what man must believe about God and his creation and what God requires of man.</p>
                    </div>
                </div>

                <div className="contents">
                    <h1 className="title">God</h1>
                    <div className="details">
                        <p>God is spirit, whose being – love, wisdom, power, holiness, justice, goodness and truth - are infinite, eternal and unchanging. There is only one God, the living and true God. Three persons are in the one God - the Father, the Son and the Holy Spirit. These three are one, the same in substance and equal in power and glory. The decrees of God are his eternal plan based on the purposes of his will; by which, for his own glory, he has pre-ordained everything that happens. God requires man to obey his revealed will. The essence of his will is for us to love the Lord our God with all our heart, with all our soul, and with all our strength, and to love our neighbours as ourselves.</p>
                    </div>
                </div>
                <div className="contents">
                    <h1 className="title">Sin</h1>
                    <div className="details">
                        <p>Sin entered the world when man, through the first Adam, rebelled against God and fell into a condition of sin and misery through seeking to live independently of God.</p>
                    </div>
                </div>
                <div className="contents">
                    <h1 className="title">Jesus Christ</h1>
                    <div className="details">
                        <p>Jesus Christ the Son of God became man by assuming a real body and a reasoning soul. He was conceived by the power of the Holy Spirit in the womb of the Virgin Mary, who gave birth to him; yet he was without sin. Christ reveals the Word of God to us for our salvation by his Word and Spirit. Christ offered himself up as a sacrifice for us once and for all to satisfy divine justice and to reconcile us to God, and he continually intercedes for us. Christ brings us under his power and rule and defends us, and restrains and conquers all his and our enemies. Today, he is seated at the right hand of the Father as our High Priest and Mediator. He is coming back again soon to judge the living and the dead.</p>
                    </div>
                </div>
                <div className="contents">
                    <h1 className="title">Redemption</h1>
                    <div className="details">
                        <p>From man’s fallen state is only possible through the Lord Jesus Christ, the eternal son of God, who became man. He was and continues to be God and man in two distinct natures and one person eternally. Jesus Christ is the only way to the Father. Redemption is obtained through being born again, for what is born of the flesh is flesh, and what is born of the Spirit is spirit. The redeemed of God will be resurrected bodily.</p>
                    </div>
                </div>
                <div className="contents">
                    <h1 className="title">Faith In Jesus</h1>
                    <div className="details">
                        <p>God requires from us faith in Jesus Christ and repentance unto everlasting life whereby through saving grace as a sinner, being truly aware of his sinfulness, understands the mercy of God in Christ, grieves for and hates his sins, and turns from them to God, fully intending and striving for a new obedience in the new man. Faith in Jesus Christ is a saving grace, by which we receive and rest on Him alone for salvation, as He is offered to us in the gospel.</p>
                    </div>
                </div> 
                <div className="contents">
                    <h1 className="title">The Church</h1>
                    <div className="details">
                        <p>The Church: is comprised of believers in Jesus Christ saved by faith. It is necessary for all believers to have communion with God and with one another. It is God’s will that all Israel shall come to faith in Jesus as Messiah before his return and for all believers, Jew and gentile, to be one new man in Christ. The Church’s mission is to make disciples of all nations.</p>
                    </div>
                </div>   
            </div>

            <div className="buttonContainer">
                <button ref={shareIconRef} onClick={()=>setShareButton(!shareButton)} className={shareButton?"active sharebutton ":"sharebutton"}>
                    <ShareIcon className='share'/>
                    <span>Share</span>
                    
                    <DownArrow className='active'/>
                </button>
                {
                        shareButton&&
                        <div className="shareContainer" ref={shareRef}>
                            <FacebookShareButton
                                url={window.location.href}
                            >
                                <span className='name facebook'>Facebook</span>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <span className='name twitter'>Twitter</span>
                            </TwitterShareButton>
                            <CopyToClipboard text={window.location.href}>
                                <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                            </CopyToClipboard>
                            <EmailShareButton
                                url={window.location.href}
                            >
                                <span className='name'>Mail</span>
                            </EmailShareButton>
                            <WhatsappShareButton  url={window.location.href}>
                          <span className='name whatsapp'>Whatsapp</span>
                          </WhatsappShareButton   >
                        </div>
                    }
            </div>         
        </div>
    </div>
  )
}

export default StatementOfFaith