import React, { useEffect, useState } from 'react'
import ShowCard from '../../../Components/ShowCard/ShowCard'
import * as service from "./service"
import { getPresentersList } from '../../../Redux/PresentersList/presentersSlice'
import { useDispatch, useSelector } from 'react-redux'

const Presenters = () => {
  const [presentersList,setPresentersList]=useState([])
  const dispatch=useDispatch()
  const loadingStatus=useSelector((state)=>state?.loading?.value)
  const [loading,setLoading]=useState(true)

  useEffect(()=>{
    setLoading(true)
    service.getPresentersList().then((response)=>{
      setPresentersList(response?.data)
      setLoading(false)
      dispatch(getPresentersList({
        presenters:response?.data
      }))
    })
  },[])
  return (
    <>
    {
      loading?(
        <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
      ):(

    <div className='presenters'>
      <div className="wrapper">
        <h1 className="heading">
          Presenters
        </h1>
        <div className="content">
          <h1 className="title">Meet Our Presenters.</h1>
          <p>They come from all walks of life. Find out what programmes they present and what times they will be shown.</p>
          <div className="presentersDetails">
            {
              presentersList?.map((item,index)=>(
                <div className="item" key={index}>
                  <ShowCard 
                  cardType="descriptionCard"
                  title={item?.name}
                  thumbnail={item?.image}
                  innerCard="innerCard"
                  description={item?.description}
                  navigate={"presenter"}
                  id={item?.partner_id}
                  />
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
      )
    }
    </>
  )
}

export default Presenters