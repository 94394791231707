import React from 'react'
import { useNavigate } from 'react-router-dom'

const PrayerSuccessPage = () => {
  const navigate = useNavigate()
  return (
    <div className='prayerSuccessPage'>
        <div className="wrapper">
            <p className="message">
                Thank you for sharing your prayer need. This will now be passed on to Revelation TV's intercessory team to stand in agreement with you in prayer for your need.
                <br/>
                <br/>
                If you would like to keep in contact with Revelation TV and to receive our monthly magazine/news updates please <span onClick={()=>navigate("/subscribe")}>click here</span>
            </p>
        </div>
    </div>
  )
}

export default PrayerSuccessPage