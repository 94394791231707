import axios from "axios";
import { getBrowserType, getDeviceType } from "../../../Utils/utils";
const qs = require('query-string');

export const getSingleNewsData=(news_id,preview)=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
    if(user_id){
        uId=user_id
    }
 
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
    params:{
      preview:preview
    },
    
  };
  return axios.get(process.env.REACT_APP_API_URL+"news/"+news_id,customConfig)
  .then((response)=>{
    return response?.data
  }).catch((err)=>{
    return []
  })
}

export const getSingleNewsDataPreview=(news_id)=>{
  let uId=localStorage.getItem("guestUserId");
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  let user_id=localStorage.getItem("userId");
  var token = localStorage.getItem("access_token");
  if(user_id){
      uId=user_id
  }

const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    uid: uId,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
};
return axios.get(process.env.REACT_APP_API_URL+"news/preview/"+news_id,customConfig)
.then((response)=>{
  return response?.data
}).catch((err)=>{
  return []
})
}

export const updateNewsAnalyticsAPI=async (newsId) => {
  let countryCode=localStorage.getItem("country_code");
  localStorage.setItem("device_analytics", "true");
  let uId=localStorage.getItem("guestUserId");
  let user_id =  localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let deviceId=localStorage.getItem("deviceId");
  var token = localStorage.getItem("access_token");
  let sessionId = localStorage.getItem("session_id");
 
  let appid = "150";
  let deviceType = getDeviceType();
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let browserType = getBrowserType();
 
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
  const data = {
    news_id:newsId,
    event_type:"POP10",
    session_id:sessionId,
    device_id:deviceId,
    user_id:uId,
    timestamp:ctime,
    app_id:appid,
    channel_id:process.env.REACT_APP_CHANNELID,
    publisherid:process.env.REACT_APP_PUBID,
    
  };
  return await axios
    .post("https://analytics.poppo.tv/event", qs.stringify(data), customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}