import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as service from "./service"

const Prayer = () => {
    const [inputValues,setInputValues]=useState(
        {
        firstname:"",
        lastname:"",
        message:""
        }
    )
    const [fNamevalidation,setFnameValidation]=useState("")
    const [lNamevalidation,setLnameValidation]=useState("")
    const [messageValidation,setMessageValidation]=useState("")
    const [successMessage,setSuccessMessage]=useState("")
    const navigate = useNavigate()
    useEffect(()=>{
        window.scroll(0,0)
        
    },[])
    const formValidation=(values)=>{
        let nameRegex = /^[a-zA-Z ]{3,30}$/;
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if(
            values?.firstname?.match(nameRegex)&&
            values?.lastname?.match(nameRegex)&&
            values?.message
            ){
                setFnameValidation(null)
                setLnameValidation(null)
                setMessageValidation(null)
                return true
            }else{
                return false
            }
    }

    const handleSubmit=(e)=>{
        let nameRegex = /^[a-zA-Z ]{3,30}$/;
        let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        e.preventDefault()

            

        if(formValidation(inputValues)==true){
            service.prayerSend(inputValues).then((response)=>{
                if(response?.success === true){

                    setInputValues(null)
                    navigate("/send-prayer/success")
                }
            })
        }else{
            if(inputValues.firstname==""){
                setFnameValidation("Field cannot be blank!")
            }else{
                if(inputValues.firstname.match(nameRegex)){
                    setFnameValidation(null)
                }else{
                    setFnameValidation("Name should be 3-30 characters and shouldn't include any special character")
                }
            }

        if(inputValues.lastname==""){
            setLnameValidation("Field cannot be blank!")
        }else{
            if(inputValues.lastname.match(nameRegex)){
                setLnameValidation(null)
            }else{
                setLnameValidation("Name should be 3-30 characters and shouldn't include any special character")
            }
        }

        if(inputValues.message==""){
            setMessageValidation("Field cannot be blank!")
        }else{
            setMessageValidation(null)
        }
        }
    }
  return (
    <div className='prayerSection'>
        <div className="wrapper">    
            <h1 className="heading">Send Prayer Request</h1>
            <div className="contents">
                <h2 className="title">We are here to stand in prayer with you.</h2>
                <p>If you would like prayer, call or email our prayer department on 
                     <a href='tel:+4402089721403'> +44 (0) 208 972 1403</a> or <a href='tel:17376008222'>1-737-600-8222</a > (US Local Rate) 
                    Email <a href="mailto:prayer@revelationtv.com">prayer@revelationtv.com</a>
                </p>
                <div className="formSection">
                    <form>
                        <div className="half">
                            <div className="fname">
                                <label htmlFor="#fname">First Name</label>
                                <input type="text" id='fname' value={inputValues?.firstname || ''} onChange={(e)=>setInputValues({...inputValues,firstname:e.target.value})} />
                                {
                                    fNamevalidation&&
                                <span className="error text-danger">{fNamevalidation}</span>
                                }
                            </div>
                            <div className="lname">
                                <label htmlFor="#lname">Last Name</label>
                                <input type="text" id='lname' value={inputValues?.lastname || ''} onChange={(e)=>setInputValues({...inputValues,lastname:e.target.value})}/>
                                {
                                    lNamevalidation&&
                                <span className="error text-danger">{lNamevalidation}</span>
                                }
                            </div>
                        </div>
                        <div className="full">
                            <div className="messageBox">
                                <label htmlFor="message">Prayer Request</label>
                                <textarea id="message" cols="30" rows="10" value={inputValues?.message || ''} onChange={(e)=>setInputValues({...inputValues,message:e.target.value})}></textarea>
                                {
                                    messageValidation&&
                                <span className="error text-danger">{messageValidation}</span>
                                }
                            </div>
                        </div>
                        <div className="buttonContainer">
                            <button type='submit' onClick={(e)=>handleSubmit(e)} className="bg-button">Send</button>
                        </div>
                        {
                            successMessage&&
                            <span className="text-success success">{successMessage}</span>
                        }
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Prayer