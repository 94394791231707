import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ShowCard from '../../../Components/ShowCard/ShowCard';

const SearchShowAll = ({}) => {
  const [allShows, setAllShows] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setLoading(true);
    if (location?.state) {
      setAllShows(location.state);
      setLoading(false);
    } else {
      setError("No show data found");
      setLoading(false);
    }
  }, [location?.state]);

  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <div className="loadingOverlay"></div>
          <div className="spinnerIcon">
            <div className="inner lds-dual-ring"></div>
          </div>
        </div>
      ) : error ? (
        <div className="errorContainer">
          <p>{error}</p>
        </div>
      ) : (
        <div className='searchallshows'>
          <div className='wrapper'>
            <h1 className='heading'>{allShows?.category_name || 'All Shows'}</h1>
            <div className='showsList'>
              {allShows?.shows?.map((data, index) => (
                <div className="items" key={index}>
                   <ShowCard
                          cardType={"dateCard"}
                          title={data?.show_name}
                          year={data?.year}
                          description={data?.synopsis}
                          thumbnail={data?.logo_thumb}
                          navigate ={
                            allShows?.category_name  === "Shows" ? "show" :
                            allShows?.category_name === "Videos" ? "video" :
                            allShows?.category_name === "News" ? "news" 
                            :"show"
                          }
                          id={
                            data?.vanity_url ||
                            data?.news_id ||
                            (data?.show_id && data?.video_id) ||
                            data?.show_id
                          }
                          favourites={data?.watchlist_flag}
                          date={data?.schedule_date}
                          page="show"
                          vanityUrl={data?.vanity_url}
                          data={data}
                          showId={data?.show_id}
                        />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchShowAll;
