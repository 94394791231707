import React, { useState , useRef } from 'react';
import {ReactComponent as ShareIcon} from "../../../images/svg/share.svg"
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg"
import * as service from "./service";
import { useEffect } from 'react';
import { FacebookShareButton, TwitterShareButton,EmailShareButton } from "react-share";
import {CopyToClipboard} from 'react-copy-to-clipboard';

const BuildingFund = () => {
    const [data,setData]=useState()
    const [descriptionData,setDescriptionData]=useState()
    const [shareButton,setShareButton]=useState(false)
    let shareRef = useRef(null)
  let shareIconRef = useRef(null)

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);
    useEffect(()=>{
        service.getBuildingFundData().then((response)=>{
            setData(response?.data)
            setDescriptionData(response?.data?.description.split("\n"))
        })
    },[])
  return (
    <div className='buildingFund'>
        <div className="wrapper">
            <h1 className="heading">
                The Building Fund
            </h1>
            <div className="videoContainer">
                <video controls src="https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4"></video>
            </div>
            <div className="details">
                {
                    descriptionData?.map((item,index)=>{
                        if(item!=""){
                            return(
                                <p key={index}>{item}</p>

                            )
                        }
                    })
                }
                <span className="link">View the latest and previous Building Fund programmes here</span>
            </div>
        </div>
        <div className="gallery">
                <div className="imageContainer">
                    <img src="https://previews.123rf.com/images/nithid/nithid1905/nithid190500006/123035514-modern-buildings-of-singapore-skyline-landscape-in-business-district-with-reflection-in-water-of-mar.jpg" alt="Photo" />
                </div>
                <div className="imageContainer">
                    <img src="https://previews.123rf.com/images/anekoho/anekoho1311/anekoho131100043/23852103-landscape-of-the-singapore-financial-district-and-business-building.jpg" alt="Photo" />
                </div>
                <div className="imageContainer">
                    <img src="https://previews.123rf.com/images/nithid/nithid1905/nithid190500006/123035514-modern-buildings-of-singapore-skyline-landscape-in-business-district-with-reflection-in-water-of-mar.jpg" alt="Photo" />
                </div>
        </div>
            <div className="wrapper">

        <div className="buttonContainer">
                <button ref={shareIconRef} onClick={()=>setShareButton(!shareButton)} className={shareButton?"active shareButton ":"shareButton"}>
                    <ShareIcon className='share'/>
                    <span>Share</span>
                    
                    <DownArrow className='active'/>
                </button>
                {
                        shareButton&&
                        <div className="shareContainer" ref={shareRef}>
                            <FacebookShareButton
                                url={window.location.href}
                            >
                                <span className='name facebook'>Facebook</span>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <span className='name twitter'>Twitter</span>
                            </TwitterShareButton>
                            <CopyToClipboard text={window.location.href}>
                                <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                            </CopyToClipboard>
                            <EmailShareButton
                                url={window.location.href}
                            >
                                <span className='name'>Mail</span>
                            </EmailShareButton>
                        </div>
                    }
            </div> 
            </div>
    </div>
  )
}

export default BuildingFund