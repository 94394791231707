import React, { useEffect } from 'react'
import Login from './Login'
import Register from './Register'

const RegisterLogin = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div className='register-login position-relative'>
      <div className="wrapper">
        <h1 className='heading'>Register / Log In</h1>
        <div className="registerLoginContainer">
          <Register/>
          <Login/>
        </div>

      </div>
    </div>
  )
}

export default RegisterLogin