import React, { useEffect, useState, useRef, useCallback } from 'react'
import { ReactComponent as ShareIcon } from "../../../images/svg/share.svg"
import { ReactComponent as DownArrow } from "../../../images/svg/down-arrow.svg"
import ShowCard from '../../../Components/ShowCard/ShowCard'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import * as service from "./service"
import VideoJS from '../../../Components/VideoJsPlayer/VideoJS';
import * as CommonService from "../../../CommonService"
import { FacebookShareButton, TwitterShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useScrollPosition from "../../../Components/ScrollPositon/useScrollPosition"
import { useSelector } from 'react-redux';
import parse from "html-react-parser";

let debounce = true
var videoStarted = false;
let seconds = 60
let prevTime


const VideoPlayer = () => {
  const [showDetails, setShowdetails] = useState([])
  const [videoPlayer, setVideoPlayer] = useState()
  const location = useLocation()
  const { id } = useParams()
  const playerRef = React.useRef(null);
  const [newURL, setNewURL] = useState("")
  const [episodes, setEpisodes] = useState([])
  const navigate = useNavigate()
  const [adurl, setAdUrl] = useState("")
  const [categoryNames, setCategoryNames] = useState()
  const [videoDetails, setVideoDetails] = useState()
  let uId = localStorage.getItem("guestUserId")
  let user_id = localStorage.getItem("userId");
  const [continueWatchingStatus, setContinueWatchingStatus] = useState(false)
  const [watched_duration, setWatchedDuration] = useState()
  const [continueWatching, setContinueWatching] = useState()
  const [sliceCount, setSliceCount] = useState(8)
  const [shareButton, setShareButton] = useState(false)
  const user = useSelector((state) => state.user.value)
  const [playerStatus, setPlayerStatus] = useState("")
  const loadingStatus = useSelector((state) => state?.loading?.value)
  const [loading, setLoading] = useState(true)
  const path = location?.pathname
  const initialVideoInfo = location?.state?.videoData
  let categories = ""
  const scrollPosition = useScrollPosition()
  const [playerData, setPlayerData] = useState()
  let shareRef = useRef(null)
  let shareIconRef = useRef(null)
  let episodeCount = 1;

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };
    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);

  
  useEffect(() => {

    window.scroll(0, 0)
    setLoading(true)
    service.getVideoDetails(id).then((videoResponse) => {
      if (videoResponse?.success === true) {
        setVideoDetails(videoResponse?.data)
        let video_data = videoResponse?.data
        service.getShowDetails(videoResponse?.data?.show_id).then((response) => {
          if (response?.success === true) {
            setShowdetails(response?.data)
            setEpisodes(response?.data?.videos)
            service.playerToken().then((tokenResponse) => {

              if (response?.data?.teaser && response?.data?.teaser_flag == 1) {
                let arr = response?.data?.teaser?.split("/").reverse();
                setNewURL(
                  "https://poppo.tv/playlist/playlist.m3u8?id=" +
                  arr[1] +
                  "&token=" +
                  tokenResponse.data.data +
                  "&type=trailer&auth=<authtoken>")
              } else {
                let video_id = video_data?.video_id;

                if (user_id) {
                  uId = user_id;
                }
                if (video_id) {

                  service.videoSubscription(video_id).then((videoSubResponse) => {
                    let subFlag = true;
                    if (videoSubResponse?.data?.length <= 0 && uId) {
                      let arr = videoResponse?.data?.video_name?.split("/").reverse();
                      if (user) {
                        if (videoResponse?.data?.watched_duration > 0) {
                          setContinueWatchingStatus(true)
                          if (window.confirm("Do you want to watch where you stopped?") == true) {
                            setContinueWatching("ok")
                          } else {
                            setContinueWatching("cancel")
                          }
                          setWatchedDuration(videoResponse?.data?.watched_duration)
                        }
                      }
                      setNewURL(
                        "https://poppo.tv/playlist/playlist.m3u8?id=" +
                        arr[1] +
                        "&token=" +
                        tokenResponse?.data?.data +
                        "&type=video" +
                        "&pubid=" +
                        process.env.REACT_APP_PUBID
                      )
                    } else {
                      service.userSubscription(uId).then((userResponse) => {
                        var userSubDetails = userResponse.data;
                        // debugger

                        if (userResponse.forcibleLogout === true) {
                          alert(
                            "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                          );
                          CommonService.logoutAll(uId).then((res) => {
                            setTimeout(() => {
                              redirectToLogin();
                            }, 1000);
                          });
                        } else if (userResponse.session_expired === true) {
                          alert("Sorry! Session Has Expired");
                          redirectToLogin();
                        } else {
                          videoSubResponse.data.map(function (subscription, index) {
                            if (userResponse.data.length == 0 && subFlag) {
                              subFlag = false;
                              localStorage.setItem("showId", response?.show_id, 10);
                              localStorage.setItem("videoId", video_data?.video_id, 10);
                              navigate({
                                pathname: "/SubscriptionList",
                                state: {
                                  videoData: video_data.video_id,
                                },
                              });
                            } else {
                              let subscribedVideo = userResponse?.data?.filter(
                                (e) => e.sub_id == subscription.publisher_subscription_id
                              );
                              if (
                                subscribedVideo.length == 0 &&
                                index + 1 < videoSubResponse.length
                              ) {
                                return;
                              }
                              if (
                                subscribedVideo.length == 0 &&
                                subFlag &&
                                index + 1 == videoSubResponse.length
                              ) {
                                subFlag = false;
                                localStorage.setItem("showId", response?.data?.show_id, 10);
                                localStorage.setItem("videoId", video_data?.video_id, 10);
                                navigate({
                                  pathname: "/SubscriptionList",
                                  state: {
                                    videoData: video_data.video_id,
                                  },
                                });
                              } else if (subFlag) {
                                subFlag = false;
                                let arr = video_data?.video_name?.split("/").reverse();

                                setNewURL(
                                  "https://poppo.tv/playlist/playlist.m3u8?id=" +
                                  arr[1] +
                                  "&token=" +
                                  tokenResponse?.data?.data +
                                  "&type=video" +
                                  "&pubid=" +
                                  process.env.REACT_APP_PUBID
                                )
                              }
                            }
                          });
                        }

                      })
                    }
                  })
                }
              }

            })
          }
        })
      }

    })
  }, [])
  useEffect(() => {
    if (newURL) {
      const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        controlBar: {
          pictureInPictureToggle: false
        },
       
        sources: [{
          src: `${newURL}`,
          type: 'application/x-mpegURL'
        }],
      };

      setVideoPlayer(
        <VideoJS options={videoJsOptions} hoverThumbnail={videoDetails?.hover_thumbnail} onReady={handlePlayerReady} />

      )
      setLoading(false)

    }

  }, [newURL])
  useEffect(() => {
    if (showDetails) {

      showDetails?.categories?.map((item, index) => {
        categories = categories + item.category_name + ",";
      });
      setCategoryNames(categories);


    }
  }, [showDetails])

  useEffect(() => {
    if (videoDetails) {
    }
  }, [videoDetails])
  const redirectToLogin = () => {

  };
  const handlePlayerReady = (player) => {
    let is_live = "0";
    playerRef.current = player;
    setPlayerData(player)
    // You can handle player events here, for example:

    player.on("loadedmetadata", () => {
      player.play()


      if (continueWatching == "ok") {
        player.currentTime(watched_duration.toString())
        player.play()


      } else {
        player.play()

      }

    })

    player.on("play", () => {
      setPlayerStatus("play")
      let event = videoStarted == true ? "POP09" : "POP02";
      videoStarted = true;

      service.onVideoPlayFunction(videoDetails, event, categoryNames, player.currentTime(), is_live, id).then((response) => {
      })
    })

    player.on("timeupdate", function (e) {
      let event = "POP03";
      setPlayerStatus("playing")

      var hasPlayedTime = player.currentTime();
      var intPlayedTime = parseInt(hasPlayedTime, 10);
      var isSixty = intPlayedTime % seconds === 0 && intPlayedTime !== 0;
      if (isSixty && debounce) {
        debounce = false;
        prevTime = intPlayedTime;
        service.onVideoPlayFunction(videoDetails, event, categoryNames, player.currentTime(), is_live, id).then((response) => {
        })
      } else {
        if (debounce == false && prevTime != intPlayedTime) {
          debounce = true;
        }
      }
    });



    player.on("pause", () => {
      let event = "POP04";
      setPlayerStatus("pause")
      service.onVideoPlayFunction(videoDetails, event, categoryNames, player.currentTime(), is_live, id).then((response) => {
      })
    })
    player.on("ended", () => {
      setPlayerStatus("ended")
      episodeCount = findIndex() + 1
      let event = "POP05";

      service.onVideoPlayFunction(videoDetails, event, categoryNames, player.currentTime(), is_live, id).then((response) => {
      })
      if (episodes[0]?.videos[episodeCount]) {
        showDetailByEpisode(episodes[0]?.videos[episodeCount])
      }

    });

    player.on("dispose", () => {
      videoStarted = false
      player.pause()
    })

  };

  const findIndex = () => {
    let elementIndex = null;
    if (episodes) {
      let filtered = episodes?.map((item) => {
        return item?.videos
      }).map((item, index) => {
        item?.filter((filteringItem, index) => {
          if (filteringItem?.video_id === videoDetails?.video_id) {
            elementIndex = index
          }
        })
      })
    }
    return elementIndex
  }


  const showDetailByEpisode = (details) => {

    navigate(
      details?.vanity_url ? `/watch-video/${details?.vanity_url}` :
        `/watch-video/${details?.video_id}`
    )
    window.scroll(0, 0)
    setVideoPlayer(null)
    setVideoDetails(details)
    setShowdetails({ ...details, single_video: showDetails?.single_video })
    service.playerToken().then((tokenData) => {

      service.videoSubscription(details?.video_id).then((videoSubResponse) => {
        let subFlag = true;
        if (videoSubResponse?.data?.length <= 0 && uId) {

          let arr = details?.video_name?.split("/").reverse();
          // setAdUrl(convertAdUrl(details,showDetails))
          if (user) {
            if (details?.watched_duration > 0) {
              setContinueWatchingStatus(true)
              if (window.confirm("Do you want to watch where you stopped?") == true) {
                setContinueWatching("ok")
              } else {
                setContinueWatching("cancel")
              }
              setWatchedDuration(details?.watched_duration)
            }
          }

          setNewURL(
            "https://poppo.tv/playlist/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            tokenData?.data?.data +
            "&type=video" +
            "&pubid=" +
            process.env.REACT_APP_PUBID
          )

        } else {
          service.userSubscription(304214).then((userResponse) => {
            var userSubDetails = userResponse.data;
            // debugger

            if (userResponse.forcibleLogout === true) {
              alert(
                "Sorry, You’ve reached the maximum Device limit. Please log in again!"
              );
              CommonService.logoutAll(uId).then((res) => {
                setTimeout(() => {
                  redirectToLogin();
                }, 1000);
              });
            } else if (userResponse.session_expired === true) {
              alert("Sorry! Session Has Expired");
              redirectToLogin();
            } else {
              videoSubResponse.data.map(function (subscription, index) {
                if (userResponse.data.length == 0 && subFlag) {
                  subFlag = false;
                  localStorage.setItem("showId", showDetails?.show_id, 10);
                  localStorage.setItem("videoId", details?.video_id, 10);
                  navigate({
                    pathname: "/SubscriptionList",
                    state: {
                      videoData: details?.video_id,
                    },
                  });
                } else {
                  let subscribedVideo = userResponse?.data?.filter(
                    (e) => e.sub_id == subscription.publisher_subscription_id
                  );
                  if (
                    subscribedVideo.length == 0 &&
                    index + 1 < videoSubResponse.length
                  ) {
                    return;
                  }
                  if (
                    subscribedVideo.length == 0 &&
                    subFlag &&
                    index + 1 == videoSubResponse.length
                  ) {
                    subFlag = false;
                    localStorage.setItem("showId", showDetails.show_id, 10);
                    localStorage.setItem("videoId", details?.video_id, 10);
                    navigate({
                      pathname: "/SubscriptionList",
                      state: {
                        videoData: details?.video_id,
                      },
                    });
                  } else if (subFlag) {
                    subFlag = false;
                    let arr = details?.video_name?.split("/").reverse();

                    setNewURL(
                      "https://poppo.tv/playlist/playlist.m3u8?id=" +
                      arr[1] +
                      "&token=" +
                      tokenData?.data?.data +
                      "&type=video" +
                      "&pubid=" +
                      process.env.REACT_APP_PUBID
                    )
                  }
                }
              });
            }

          })
        }
      })
    })
  }

  const showMoreHandler = () => {
    setSliceCount(sliceCount + 8)
  }


  return (
    <>

      {
        loading ? (
          <div className="loadingContainer" >
            <div className="loadingOverlay"></div>
            <div className="spinnerIcon">

              <div className="inner lds-dual-ring"></div>
            </div>
          </div>
        ) : (

          <div className='videoPlayer position-relative'>
            <div className="wrapper">
              <div className="videoContainer">
                {
                  videoPlayer && videoPlayer
                }


              </div>
              <div className="detailsContainer">
                <div className="top">
                  <div className="left">
                    <h1 className="title">{videoDetails?.show_name || videoDetails?.video_title}</h1>
                  </div>
                  <div className="right">
                    <div className="dateSection">
                      <span className="icon">
                        <img src={require("../../../images/icon-calendartime.png")} alt="Icon-Calender" />
                      </span>
                      <span className="date">{videoDetails?.schedule_date}</span>
                    </div>
                  </div>
                </div>
                <div className="bottom">

                  <p>{videoDetails && videoDetails?.video_description ? parse(videoDetails?.video_description?.split("\n").join("<br>")) : videoDetails?.synopsis? parse(videoDetails?.synopsis?.split("\n").join("<br>")):null}</p>
                  <div className="buttonContainer">

                    <button ref={shareIconRef} className={shareButton ? "active share " : "share"} onClick={() => setShareButton(!shareButton)}>
                      <ShareIcon className='share' />
                      <span>
                        Share
                      </span>
                      <DownArrow className='active' />
                    </button>
                    {
                      shareButton &&
                      <div className="shareContainer" ref={shareRef}>
                        <FacebookShareButton
                          url={window.location.href}
                        >
                          <span className='name facebook'>Facebook</span>
                        </FacebookShareButton>
                        <TwitterShareButton
                          url={window.location.href}
                        >
                          <span className='name twitter'>Twitter</span>
                        </TwitterShareButton>
                        <CopyToClipboard text={window.location.href}>
                          <button className='clipboard' onClick={() => alert("Link copied to clipboard")}>Clipboard</button>
                        </CopyToClipboard>
                        <EmailShareButton
                          url={window.location.href}
                        >
                          <span className='name'>Mail</span>
                        </EmailShareButton>
                        <WhatsappShareButton url={window.location.href}>

                          <span className='name whatsapp'>Whatsapp</span>
                        </WhatsappShareButton   >
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="episodesContainer">
                <h1 className="heading">
                  Episodes
                </h1>
                <div className="episodes">
                  {
                    showDetails?.single_video == 0 ?
                      episodes?.map((video, key) => {
                        return video?.videos
                      }).map((data, index) => {
                        return data?.slice(0, sliceCount)
                      })
                        .map((slicedData, index) => {
                          return slicedData?.map((vd, index) => (
                            <div className="episode" key={index} onClick={() => showDetailByEpisode(vd)}>
                              <ShowCard
                                cardType="dateCard"
                                thumbnail={vd?.thumbnail_350_200}
                                title={vd?.video_title}
                                description={vd?.video_description}
                                innerCard={"innerCard"}
                                date={vd?.schedule_date}
                                // id={showDetails?.show_id}
                                // navigate={"show"}
                                page="videoplayer"
                              />
                            </div>

                          ))
                        }) :
                      episodes?.map((video, key) => (
                        <div className="episode" key={key} onClick={() => showDetailByEpisode(video)}>
                          <ShowCard
                            cardType="dateCard"
                            thumbnail={video?.thumbnail_350_200}
                            title={video?.video_title}
                            description={video?.video_description}
                            innerCard="innerCard"
                            date={video?.schedule_date}
                            page="videoplayer"

                          />
                        </div>
                      ))
                  }




                </div>
                {
                  showDetails?.single_video == 0 && episodes[0]?.videos?.length > sliceCount &&
                  <div className="gradientBg text-center">
                    <div className="buttonContainer">
                      <button className="showMore" onClick={() => showMoreHandler()}>
                        Show More
                        <DownArrow />
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default VideoPlayer