import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as service from "../AccountSetting/service"


const ChangePassword = () => {
    const [inputValues,setInputValues]=useState({
        currentpassword:"",
        newpassword:"",
        confirmpassword:""
    })
    const [passwordError,setPasswordError]=useState(false)
    const [currentPasswordValidation,setCurrentPasswordValidation]=useState("")
    const [newPasswordValidation,setNewPasswordValidation]=useState("")
    const [confirmPasswordValidation,setConfirmPasswordValidation]=useState("")
    const [successMessage,setSuccessMessage]=useState()
    const navigate=useNavigate()
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    const submitHandler=(e)=>{
        e.preventDefault()
        let passwordRegex=  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$/;

        if(inputValues.currentpassword==""){
            setCurrentPasswordValidation("Field cannot be blank!")
        }else{
            setCurrentPasswordValidation(null)
        }

        if(inputValues.newpassword==""){
            setNewPasswordValidation("Field cannot be blank!")
        }else{
            if(inputValues.newpassword.match(passwordRegex)){
                setNewPasswordValidation(null)
            }else{
                setNewPasswordValidation("Password should be 8-20 characters and include at least 1 uppercase letter,1 lowercase letter, 1 number and 1 special characters")
            }
        }

        if(inputValues.confirmpassword==""){
            setConfirmPasswordValidation("Field cannot be blank!")
        }else{
            if(inputValues.confirmpassword==inputValues.newpassword){
                setConfirmPasswordValidation(null)
            }else{
                setConfirmPasswordValidation("Password not match")
            }
        }


        if(inputValues.currentpassword&&inputValues?.newpassword&&inputValues.confirmpassword&&!currentPasswordValidation&&!newPasswordValidation&&!confirmPasswordValidation){
            let data={
                newpassword:inputValues?.newpassword,
                currentpassword:inputValues?.currentpassword
            }
            service.userDetailUpdate(data).then((response)=>{
                if(response?.message=="Incorrect Password"){
                    setPasswordError(true)
                    setSuccessMessage(false)
                }

                if(response?.success==true){
                    setSuccessMessage(true)
                    setPasswordError(false)
                    setTimeout(()=>{
                        navigate("/")
                    },1000)
                }
            })
        }
    }
  return (
    <Fragment>
      
      <div className='ChangePassword'>
        <div className="wrapper">    
            <h1 className="heading">Change Password</h1>
            <div className="contents">
                
                <div className="formSection">
                    <form>
                        <div className="inputContainer">
                                <label htmlFor="#cpaasword">Current Password</label>
                                <input type="password" id='cpaasword' value={inputValues.currentpassword || ""} onChange={(e)=>setInputValues({...inputValues,currentpassword:e.target.value})} />
                                {
                                    currentPasswordValidation &&
                                    <span className="error">{currentPasswordValidation}</span>
                                }
                            </div>
                            <div className="inputContainer">
                                <label htmlFor="#newpassword">New Password</label>
                                <input type="password" id='newpassword' value={inputValues.newpassword || ""} onChange={(e)=>setInputValues({...inputValues,newpassword:e.target.value})} />
                                {
                                    newPasswordValidation &&
                                    <span className="error">{newPasswordValidation}</span>
                                }
                            </div>
                            <div className="inputContainer">
                                <label htmlFor="#confirmpassword">Confirm Password</label>
                                <input type="password" id='confirmpassword' value={inputValues.confirmpassword || ""} onChange={(e)=>setInputValues({...inputValues,confirmpassword:e.target.value})} />
                                {
                                    confirmPasswordValidation &&
                                    <span className="error">{confirmPasswordValidation}</span>
                                }
                            </div>
                       
                       
                        <div className="buttonContainer">
                            <button type='submit' className="bg-button" onClick={(e)=>submitHandler(e)}>Save Changes</button>
                        </div>
                        {
                            passwordError && 
                            <span className="error center">Incorrect Password</span>
                        }
                        {
                            successMessage &&
                            <span className="success text-success">
                                Password Changed Successfully
                            </span>
                        }
                    </form>
                </div>
            </div>
        </div>
    </div>
    </Fragment>
  );
};

export default ChangePassword;
