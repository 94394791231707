import React from 'react'
import { Link } from 'react-router-dom'
import "./scss/Footer.css"

const Footer = () => {
  return (
    <div className='footer-container py-4'>
        <div className="footerWrapper d-flex justify-content-between">
            <div className="left">
                <div className="top">
                    <ul className="links d-flex">
                        <Link to="/gift-aid-info">
                        <li><span className="link me-2">Gift Aid Info</span></li>
                        </Link>
                      <Link to="/videos">
                      <li><span className="link me-2">Videos</span></li>
                      </Link>
                      <Link to="/presenters"><li><span  className="link me-2">Presenters</span></li></Link>
                        <Link to="/schedule">  <li><span className="link me-2">Schedule</span></li></Link>
                      

                        <Link to="/contact-us">
                        <li><span className="link me-2">Contact</span></li>
                        </Link>
                        <Link to="/privacy-policy">
                        <li><span>Privacy Policy</span></li>
                        </Link>

                       


                    </ul>
                </div>
                <div className="center">
                    <p>Revelation Foundation Limited is a registered Charity in England & Wales 1100573</p>
                    <p>Company Registration No 04930129</p>
                </div>
                <div className="bottom">
                    <p><span>© 2023 </span>revelation tv</p>
                </div>
            </div>
            <div className="right">
            <div className="top">
                <ul className="social d-flex">
                    <li onClick={()=>window.open("https://www.facebook.com/RevelationTVOfficial",'_blank')}><img src={require("../../images/icon-sm-facebook.png")} alt="Facebook" /></li>
                    <li onClick={()=>window.open("https://twitter.com/revelationtv",'_blank')}><img src={require("../../images/icon-sm-twitter.png")} alt="Twitter" /></li>
                    <li onClick={()=>window.open("https://www.youtube.com/user/rtveurope",'_blank')}><img src={require("../../images/icon-sm-youtube.png")} alt="Youtube" /></li>
                    <li onClick={()=>window.open("https://www.instagram.com/revelation_tv/",'_blank')}><img src={require("../../images/icon-sm-instagram.png")} alt="Instagram" /></li>
                    <li onClick={()=>window.open("https://www.revelationtv.com/spotify",'_blank')}><img src={require("../../images/icon-sm-spotify.png")} alt="spotify" /></li>
                </ul>
            </div>
            <div className="bottom">
                <div className="imageContainer">
                    <img src={require("../../images/footer-image.png")} alt="" />
                </div>
            </div>
        </div>
        </div>

        
    </div>
  )
}

export default Footer