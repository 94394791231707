import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import * as service from "./service"
import { analytics ,authenticate } from '../../../CommonService'
import { getUser } from '../../../Redux/User/userSlice'
import { useEffect } from 'react'
const Login = () => {
    const [loginValues,setLoginValues]=useState({
        email:"",
        password:""
    })
    const [email, setemail] = useState("");
    const [password, setpassword] = useState("");
    const [redirectUrl,setRedirectUrl]=useState("")

    const [loginerrors, setLoginErrors] = useState({
        email: "",
        password: "",
      });
      const location=useLocation()

      useEffect(()=>{
        if(location?.state?.path){
          setRedirectUrl(location?.state?.path)
        }else{
          setRedirectUrl("/")
        }
      },[location?.state])

      const validateLoginEmail = (email) => {
        if (
          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
            email
          )
        ) {
          return true;
        }
        return false;
      };
      const validationLogin = () => {
        let loginerrors = {};
        let formIsValid = true;
    
        if (loginValues.email.trim()) {
          var errorMsg = validateLoginEmail(loginValues.email);
          if (errorMsg === true) {
            loginerrors.email = "";
            setemail("");
          } else {
            formIsValid = false;
            setemail(" Input--errored");
            loginerrors.email = "Invalid email Address";
          }
        } else {
          formIsValid = false;
          loginerrors.email = "Required Email Field";
          setemail(" Input--errored");
        }
        if (loginValues.password.trim()) {
          loginerrors.password = "";
          setpassword("");
        } else {
          formIsValid = false;
          setpassword(" Input--errored");
          loginerrors.password = "Required Password Field";
        }
        setLoginErrors(loginerrors);
        return formIsValid;
      };

    const navigate=useNavigate()
    const [errorMessage,setErrorMessage]=useState("")
    const dispatch=useDispatch()
    const loginHandler=(e)=>{
        e.preventDefault()
        if (validationLogin()) {
        service.login(loginValues).then((response)=>{
            if(response?.data?.success===true){
                localStorage.setItem("userId",response?.data?.data?.[0]?.user_id)
                dispatch(getUser({
                  user:response?.data?.data?.[0]?.user_id
              }))
                if(localStorage.getItem("device_analytics")){
                  localStorage.removeItem("device_analytics")
                }
                authenticate().then(()=>{
                  analytics(response?.data?.data?.[0]?.user_id,response?.data?.data?.[0]?.user_email,response?.data?.data?.[0]?.first_name).then((response)=>{
                    
                    navigate(redirectUrl,{replace:true})
                  })
                })
            }else{
                if(response?.data?.message=="Invalid Credentials"){
                    setErrorMessage("Invalid Credentials")
                }
            }
        })
    }
    }
    
  return (
    <div className='login'>
        <h1 className="title">
            Log In to Revelation TV
        </h1>
        <div className="formContainer">
           <form action="post">
                <div className="full">
                    <div className="inputContainer">
                        <label htmlFor="loginemail">
                            Email Address
                        </label>
                        <input type="email" id='loginemail' value={loginValues.email} onChange={(e)=>setLoginValues({...loginValues,email:e.target.value})} />
                        {loginerrors.email && (
                        <p
                          style={{
                            color: "red",
                          
                            fontWeight: "lighter",
                          }}
                        >
                          {loginerrors.email}
                        </p>
                      )}
                    </div>
                </div>
                <div className="full">
                    <div className="inputContainer">
                        <label htmlFor="Loginpassword">
                            Password
                        </label>
                        <input type="password" id='Loginpassword' value={loginValues.password} onChange={(e)=>setLoginValues({...loginValues,password:e.target.value})} />
                        {loginerrors.password && (
                        <p
                          style={{
                            color: "red",
                          
                            fontWeight: "lighter",
                          }}
                        >
                          {loginerrors.password}
                        </p>
                      )}
                    </div>
                </div>
                {
                    errorMessage &&
                    <span className="error">{errorMessage}</span>
                }
                <span className='forgot' onClick={()=>navigate("/forgot-password")}>Forgot password?</span>
                <div className="buttonContainer">
                    <button className="bg-button" onClick={(e)=>loginHandler(e)}>Log In</button>
                </div>
            </form> 
        </div>
    </div>
  )
}

export default Login