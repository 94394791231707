import React from 'react'
import { useNavigate } from 'react-router-dom'

const PresenterCard = ({title,partners}) => {
    const navigate=useNavigate()
    const presenterButtonHandler=(data)=>{
        if(data?.is_valid==true){
            navigate("/presenters-info",{state:{id:data?.partner_id}})
        }
    }
  return (
    <div className='cardContainer'>
        <h1 className="title text-center">{title}</h1>
        <div className="cards d-flex">
            {
                partners?.map((data,index)=>(

                <div key={index} className="imageContainer position-relative">
                    <img src={data?.image} alt="Thumbnail" />
                    <div className="cardGradient">
                        <div className="buttonContainer text-center">
                            <button className={data?.is_valid==true?'pointer':"name"} onClick={()=>presenterButtonHandler(data)}>{data?.name}</button>
                        </div>
                    </div>
                </div>
                ))
            }
            
        </div>
    </div>
  )
}

export default PresenterCard