import React, { useEffect, useState } from 'react'
import ScheduleDays from '../../../Components/ScheduleDays/ScheduleDays'
import ScheduleCard from '../../../Components/ScheduleCard/ScheduleCard'
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg"
import {Service} from '../../../Components/ScheduleDays/Service'
import { useSelector } from 'react-redux'

const Schedule = () => {


  return (
    <div className='wrapper' >
        <ScheduleDays title="Schedule" schedulepage={true}/>
        
    </div>
  )
}

export default Schedule