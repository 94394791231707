import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div className='privacyPolicy'>
    <div className="wrapper">    
        <h1 className="heading" >Privacy Policy</h1>
        <br></br>
        <br></br>
      
        <h6>Revelation foundation Privacy Policy</h6>
     <p className="bottom-distance">Who are we? In this policy, 'we', 'our' or 'us' refers to Revelation    Foundation,    a registered charity    in    England    and    Wales    (1100573).    This    also    includes    Revelation    Association    and    Revelation TV Company    registered in England no. 04930129, Limited    Company    No:    VAT    registration    no.    907493407, Our    address    is:    129    Prestbury    Road,    Macclesfield,    Cheshire,    SK10    3DA    (REVELATION    FOUNDATION)</p>  
        <h6 className="bottom-distance">Your Privacy</h6>
        <p className="bottom-distance">
          We respect your privacy and want to communicate with you in a way that
          suits you, in accordance with the General Data Protection Regulation
          (GDPR). Your privacy is important to us and this policy explains how
          we use your personal information and keep it secure. What is personal
          information? Personal information covers any data that enables us to
          identify you as an individual. This could include, name, address,
          telephone number, email address or bank details (submitted when making
          a donation).
        </p>
        <h6 className="bottom-distance">How do we use your personal information?</h6>
        <p className="bottom-distance">
          We use your information to send you postal mailings, such as our
          supporter magazine "R Times", appeal updates and other information you
          have asked for or that we may feel would be of interest to you. We
          only use your email details to send you communications that you have
          requested. You can change the way we communicate with you, at any
          time, in the following ways:
        </p>
        <ul>
          <li>• By calling our number +44 (0) 208 972 1400</li>
          <li>• By unsubscribing from emails</li>
          <li>• By emailing our Contact Centre at info@revelationtv.com</li>
        </ul>
        <p className="bottom-distance">
          When you supply personal information to us, we only use this
          information for the purpose for which it was given and ensure that it
          is kept securely. From time to time we will ask our supporters to take
          part in surveys, to help us learn and improve how we work.
        </p>
        
        <h6 className="bottom-distance">Lawful basis for processing your information</h6>
        <p className="bottom-distance">
          We use different reasons for using your information: Consent -
          sometimes we use your information to send you communications, such as
          emails, because you have given us clear consent to do so. Contract -
          we may process your personal data when we need to do this to fulfil a
          contract with you, e.g. if you have made a donation or requested
          RTimes by Mail Legal Obligation - sometimes we will need to store your
          information because we have a legal reason to do this, e.g. if you
          have gift aided donations to us, HMRC require us to keep the
          information for up to 7 years. Legitimate Interest - we will process
          your information when it is in our legitimate interest to do this and
          when these interests do not override your rights. Those legitimate
          interests include providing you with postal information e.g.
          fundraising appeals, supporter magazine etc.
        </p>
        <h6 className="bottom-distance" >How long do we keep your information?</h6>
        
        <p className="bottom-distance">
          We only keep your information for as long as we need to. We will have
          a lawful reason to keep your data and when we no longer need it, we
          will delete it. If you have gift aided donations to us, HMRC require
          us to keep the information for up to 7 years. If you have donations
          recorded we are required to keep them for up to 2 years. Do we share
          your personal information? We do not share your details with third
          parties, unless it is necessary to do so. Some of the ways it may be
          necessary to share your information include:
        </p>
        
        <p>• fulfilling orders</p>
        <p>• sending postal mail and email</p>
        <p>• analysing data</p>
        <p>• providing marketing assistance</p>
        <p>• processing financial transactions (see next section)</p>
        <p>• other necessary functions.</p>
         
        
        
        <p>
          In cases like these, we will have a data agreement in place to ensure
          that these companies will not use your information for their own use,
          but only to fulfil the function on behalf of Revelation Foundation.
        </p>
        <p>
          Your information will not be passed to third parties for marketing
          purposes.
        </p>
        <br></br>
        <h6>How do we use your financial information and keep it secure?</h6>
        <br></br>
        <p className="bottom-distance">
          In order to process financial transactions, we may need your bank or
          credit card details. All credit card payments are processed by
          WorldPay, via a secure server. WorldPay's privacy policy:
          https://www.worldpay.com/uk/privacy-policy If you have given us bank
          details in order to set up a direct debit, your information will be
          processed by Revelation Foundation, within a secure hosting
          environment. Information you give us via the website is stored and
          processed in the UK. However, because of the nature of the internet,
          it is possible that your data may pass through countries outside the
          EEA.
        </p>
        <h6 className="bottom-distance">Your rights</h6>
        <p className="bottom-distance">You have the right to:</p>
       
        <p>• request a copy of the information we hold about you</p>
        <p> • update or amend the information we hold about you if it is wrong</p>
        <p>• change your communication preferences at any time</p>
        <p> • object to the processing of your information for marketing
            purposes</p>
        <p> • raise a concern or complaint about the way in which your
            information is being used.</p>
    
        
       
        <p className="bottom-distance">
          If you wish to find out more about these rights, or if you have any
          queries about this statement, please contact our Contact Centre at
          info@revelationtv.com or call +44 (0) 208 972 1400.
        </p>
        <h6 className="bottom-distance">Our Website</h6>
        <p>
          Our website uses cookies to help us to provide you with a good
          experience when you browse. A cookie is a small file of letters and
          numbers that we may put on your computer or mobile device when you
          access our website. These cookies allow us to distinguish you from
          other users of the website - for example, they will tell us whether
          you have visited our site before or whether you are a new visitor.
        </p>
        <p>
          For all areas of our website which collect personal information, we
          use a secure server. Although we cannot completely guarantee the
          security of any information you transmit to us, we enforce strict
          procedures and security features to protect your information and
          prevent unauthorised access.
        </p>
        <h6 className="bottom-distance">Children</h6>
        <p>
          If you are under 18, please get your parent/guardian's consent before
          adding any personal information to our website
        </p>
        <p className="bottom-distance">
          We will not knowingly send any marketing material to those under 18.
        </p>
        <p>How to contact us</p>
        <p>You can get it touch with us in the following ways:</p>
        <br></br>
        <p>• by telephone +44 (0) 208 972 1400</p>
        <p>• by email at info@revelationtv.com</p>
        <p >
          • by post: Revelation Foundation, Suite 124, 61 Victoria Road, Surbiton. KT6 4JX. UK.
        </p>
        <h6 className="bottom-distance">
          How to update your details
        </h6>
        <p className="bottom-distance"> 
          To notify us of a change to your name, address or e-mail details,
          please email us at info@revelationtv.com, write to us at the address
          below, or telephone us on +44 (0) 208 972 1400. Please keep us
          informed of changes so we can stay in touch. Revelation Foundation, Suite 124, 61 Victoria Road, Surbiton. KT6 4JX. UK.
        </p>
        <h6 className="bottom-distance">Changes to This Privacy Policy</h6>
        
        <p className="bottom-distance">
          This Privacy Policy is effective as of the date stated at the bottom
          of this Privacy Policy. We may change this Privacy Policy from time to
          time. Please be aware that, to the extent permitted by applicable law,
          our use of the Personal Information and other information is governed
          by the Privacy Policy in effect at the time we collect the
          information. Please refer back to this Privacy Policy on a regular
          basis.
        </p>
        <h4>USA Privacy Policy</h4>
        <h4>REVELATION TV inc.</h4>
        <br></br>
        <h6 className="bottom-distance">Who are we?</h6>
        
        <p>
          In this policy, 'we', 'our' or 'us' refers to Revelation TV inc, an
          exempt organisation as described in Section 501c3 of the Internal
          Revenue Code EIN 26-2219361
        </p>
        <p>Our address is: P.O. Box 12836, Beaumont, Texas 77726, USA</p>
        <h6 className="bottom-distance">Your Privacy</h6>
       
        <p>
          We respect your privacy and want to communicate with you in a way that
          suits you, in accordance with the General Data Protection Regulation
          (GDPR). Your privacy is important to us and this policy explains how
          we use your personal information and keep it secure.
        </p>
        <h6 className="bottom-distance">What is personal information?</h6>
        
        <p>
          Personal information covers any data that enables us to identify you
          as an individual. This could include, name, address, telephone number,
          email address or bank details (submitted when making a donation).
        </p>
        <h6 className="bottom-distance">How do we use your personal information?</h6>
        <p>
          We use your information to send you postal mailings, such as our
          supporter magazine, appeal updates and other information you have
          asked for or that we may feel would be of interest to you.
        </p>
        <p>
          We only use your email details to send you communications that you
          have requested.
        </p>
        <p>
          You can change the way we communicate with you, at any time, in the
          following ways:
        </p>
        <p>• By calling our number 1-737-600-8222</p>
        <p>• By unsubscribing from emails</p>
        <p>• By emailing our Contact Centre at info@revelationtv.com</p>
        
        <p>
          When you supply personal information to us, we only use this
          information for the purpose for which it was given and ensure that it
          is kept securely.
        </p>
        <p className="bottom-distance">
          From time to time we will ask our supporters to take part in surveys,
          to help us learn and improve how we work.
        </p>
        <h6 className="bottom-distance">Lawful basis for processing your information</h6>
        <p>We use different reasons for using your information:</p>
        <p>
          Consent - sometimes we use your information to send you
          communications, such as emails, because you have given us clear
          consent to do so
        </p>
        <p>
          Contract - we may process your personal data when we need to do this
          to fulfil a contract with you, e.g. if you have made a donations or
          requested RTimes by Mail
        </p>
        <p>
          Legal Obligation - sometimes we will need to store your information
          because we have a legal reason to do this.
        </p>
        <p>
          Legitimate Interest - we will process your information when it is in
          our legitimate interest to do this and when these interests do not
          override your rights. Those legitimate interests include providing you
          with postal information e.g. fundraising appeals, supporter magazine
          etc.
        </p>
        <h6 className="bottom-distance">How long do we keep your information</h6>
       
        <p>
          We only keep your information for as long as we need to. We will have
          a lawful reason to keep your data and when we no longer need it, we
          will delete it. If you have gift aided donations to us, HMRC require
          us to keep the information for up to 7 years. If you have donations
          recorded we are required to keep them for up to 2 years.
        </p>
        <h6 className="bottom-distance">Do we share your personal information?</h6>
        
        <p>
          We do not share your details with third parties, unless it is
          necessary to do so. Some of the ways it may be necessary to share your
          information include:
        </p>
        <h6 className="bottom-distance">Do we share your personal information?</h6>
        
        <p>fulfilling orders</p>
        <p>sending postal mail and email</p>
        <p>analysing data</p>
        <p>providing marketing assistance</p>
        <p>processing financial transactions (see next section)</p>
        <p>other necessary functions.</p>
        <p>
          In cases like these, we will have a data agreement in place to ensure
          that these companies will not use your information for their own use,
          but only to fulfil the function on behalf of Revelation TV inc.
        </p>
        <p className="bottom-distance">
          Your information will not be passed to third parties for marketing
          purposes.
        </p>
        <br></br>
        <h6 className="bottom-distance">How do we use your financial information and keep it secure?</h6>
        
        <p>
          In order to process financial transactions, we may need your bank or
          credit card details.
        </p>
        <p>
          All credit card payments are processed by WorldPay, via a secure
          server.
        </p>
        <p>
          WorldPay's privacy policy: https://www.worldpay.com/uk/privacy-policy{" "}
        </p>
        <p>
          Information you give us via the website is stored and processed in the
          UK. However, because of the nature of the internet, it is possible
          that your data may pass through countries outside the EEA.
        </p>
        <h6 className="bottom-distance">Your rights</h6>
        <h6 className="bottom-distance">You have the right to:</h6>
        
        <p>request a copy of the information we hold about you</p>
        <p>update or amend the information we hold about you if it is wrong
</p>
        <p>change your communication preferences at any time</p>
        <p>object to the processing of your information for marketing purposes</p>
        <p>raise a concern or complaint about the way in which your information is being used.</p>
        

        <p>If you wish to find out more about these rights, or if you have any queries about this statement, please contact our Contact Centre at info@revelationtv.com or call 1-737-600-8222.</p>
        
        <h6 className="bottom-distance">Our Website</h6>
        
        <p>
        Our website uses cookies to help us to provide you with a good experience when you browse. A cookie is a small file of letters and numbers that we may put on your computer or mobile device when you access our website.  These cookies allow us to distinguish you from other users of the website - for example, they will tell us whether you have visited our site before or whether you are a new visitor.
        </p>
        <p>For all areas of our website which collect personal information, we use a secure server. Although we cannot completely guarantee the security of any information you transmit to us, we enforce strict procedures and security features to protect your information and prevent unauthorised access. 
</p>

<h6 className="bottom-distance">Children</h6>
<p>If you are under 18, please get your parent/guardian's consent before adding any personal information to our website</p>

<p>We will not knowingly send any marketing material to those under 18.</p>

<h6 className="bottom-distance">How to contact us</h6>
   <h6 className="bottom-distance">You can get it touch with us in the following ways:</h6>
   <p>through our online form</p> 
   <p>by telephone 1-737-600-8222</p> 
   <p>by email at info@revelationtv.com
</p> 
   <p>by post: Revelation TV, PO Box 12836, Beaumont, Texas 77726, USA.</p> 
   
   <h6 className="bottom-distance">How to update your details</h6>
   <br></br>
<p>To notify us of a change to your name, address or e-mail details, please email us at info@revelationtv.com, write to us at the address below, or telephone us on 1-737-600-8222. Please keep us informed of changes so we can stay in touch.</p>

<p>Revelation TV, PO Box 12836, Beaumont, Texas 77726, USA</p>
<h6 className="bottom-distance">Changes to This Privacy Policy</h6>

<p>This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time. Please be aware that, to the extent permitted by applicable law, our use of the Personal Information and other information is governed by the Privacy Policy in effect at the time we collect the information. Please refer back to this Privacy Policy on a regular basis.

</p>
</div>
</div>


   
  );
};

export default PrivacyPolicy;
