import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./scss/Header.css";
import { ReactComponent as AccountCircleIcon } from "../../images/svg/account_icon.svg";
import { ReactComponent as MenuIcon } from "../../images/svg/menu-icon.svg";
import { ReactComponent as SearchIcon } from "../../images/svg/search.svg";
import { ReactComponent as CloseIcon } from "../../images/svg/closeIcon.svg";
import * as service from "../../CommonService";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../Redux/User/userSlice";
import { getAccountDetails } from "../../Redux/AccountDetails/accountDetailsSlice";
import { accountDetails } from "../../views/pages/AccountSetting/service";
import { checkOperatingSystem } from "../../Utils/utils";
import useScrollPosition from "../ScrollPositon/useScrollPosition";
const Header = () => {
  const [dropDownData, setDropDownData] = useState("");
  const [dropDownDataMobile, setDropDownDataMobile] = useState("");
  const [activeState, setActiveState] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState("");
  const [isLoggedIn, setIsLoggeIn] = useState(false);
  const user = useSelector((state) => state?.user?.value);
  const dispatch = useDispatch();
  const [HoverEffect, setHoverEffect] = useState();
  const [liveHoverEffect, setLiveHoverEffect] = useState(false);
  const location = useLocation();
  const [profileImage, setProfileImage] = useState();
  const [deviceType, setDeviceType] = useState();
  const [isOpenMobileApp, setIsOpenMobileApp] = useState(false);
  const accountDetailsRedux = useSelector(
    (state) => state?.accountDetails?.value
  );
  const searchLogo = useRef(null);
  const scrollPosition = useScrollPosition();
  let searchRef = useRef(null);
  let searchIconRef = useRef(null);
  const appScheme = "revelationtv://home";

  useEffect(() => {
    setDeviceType(checkOperatingSystem());
  }, []);
  useEffect(() => {
    if (deviceType) {
      if (deviceType === "android" || deviceType === "iPhone") {
        if (scrollPosition < 50) {
          setIsOpenMobileApp(true);
        } else {
          setIsOpenMobileApp(false);
        }
      }
    }
  }, [scrollPosition, deviceType]);
  useEffect(() => {
    let handleOutClick = (e) => {
      if (!searchIconRef?.current?.contains(e.target)) {
        if (!searchLogo?.current?.contains(e.target)) {
          if (!searchRef?.current?.contains(e.target)) {
            setSearchActive(false); //outside click
          }
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [searchRef]);
  useEffect(() => {
    if (user) {
      accountDetails().then((response) => {
        dispatch(
          getAccountDetails({
            accountDetails: response?.data,
          })
        );
        if (response?.data?.user_image) {
          setProfileImage(response?.data?.user_image);
        }
      });
      setIsLoggeIn(true);
    } else {
      setIsLoggeIn(false);
    }
  }, [user]);
  useEffect(() => {
    if (accountDetailsRedux) {
      setProfileImage(accountDetailsRedux?.user_image);
    }
  }, [accountDetailsRedux]);
  useEffect(() => {
    switch (location?.pathname) {
      case "/":
        setActiveState("home");
        break;
      case "/live":
        setActiveState("live");
        break;
      case "/about-revelation":
        setActiveState("about");
        break;
      case "/statement-of-faith":
        setActiveState("about");
        break;
      case "/leadership-team":
        setActiveState("about");
        break;
      case "/our-studio":
        setActiveState("about");
        break;
      case "/presenters":
        setActiveState("about");
        break;
      case "/latest-news":
        setActiveState("news");
        break;
      case "/israel-tour":
        setActiveState("news");
        break;
      case "/videos":
        setActiveState("ondemand");
        break;
      case "/schedule":
        setActiveState("schedule");
        break;
      case "/donation":
        setActiveState("give");
        break;
      case "/send-prayer":
        setActiveState("prayer");
        break;
      case "/healing-scriptures":
        setActiveState("prayer");
        break;
      case "/contact-us":
        setActiveState("contact");
        break;
        case "/tv":
          setActiveState("about");
          break;
      default:
        setActiveState("");
    }
  }, [location?.pathname]);
  const menuHandler = (section) => {
    switch (section) {
      case "news":
        if (dropDownDataMobile == "news") {
          setDropDownDataMobile(null);
        } else {
          setDropDownDataMobile("news");
        }
        break;
      case "about":
        if (dropDownDataMobile == "about") {
          setDropDownDataMobile(null);
        } else {
          setDropDownDataMobile("about");
        }
        break;
      case "prayer":
        if (dropDownDataMobile == "prayer") {
          setDropDownDataMobile(null);
        } else {
          setDropDownDataMobile("prayer");
        }
        break;
    }
  };
  const searchHanldeChange = (value) => {
    setInputValue(value);
  };

  const logout = () => {
    service.logout(user).then((response) => {
      localStorage.removeItem("userId");
      localStorage.removeItem("device_analytics");
      service.guestUser().then((gresponse) => {
        service.authenticate().then(() => {
          service.analytics(gresponse?.data?.user_id);
        });
      });
      dispatch(
        getUser({
          user: null,
        })
      );
    });
  };

  const searchHandler = () => {
    navigate(`/search?q=${inputValue}`);
    setSearchActive(false);
  };

  const isAppInstalled = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the app's custom URL scheme is present in the user agent string
    if (userAgent.match(new RegExp(appScheme, "i"))) {
      return true; // The app is installed
    } else {
      return false; // The app is not installed
    }
  };

  const deviceOpen = () => {
    if (deviceType === "android") {
      window.location.href = appScheme;
      if (isAppInstalled() === true) {
        window.location.href = appScheme;
      } else {
        window.open(
          "https://play.google.com/store/apps/details?id=com.revelation.android",
          "_blank"
        );
      }
    } else if (deviceType === "iPhone") {
      window.location = appScheme;

      setTimeout(() => {
        window.location =
          "https://apps.apple.com/us/app/revelation-tv/id1226981767";
      }, 100);
    }
  };

  const logoRedirect = () => {
    if (location.pathname === "/") {
      window.location.reload();
    } else {
      navigate("/");
    }
  };

  const pageRefresh = () => {
    if (location.pathname === "/videos") {
      window.location.reload();
    }
  };

  const downloadMagazine = () => {
    service.magazineInfo().then((response) => {
      if (response.success === true) {
        window.open(response.data[0].url, "_blank");
      }
    });
  };

  return (
    <div
      className={
        scrollPosition > 50
          ? "header-container transparentBackground"
          : "header-container"
      }
    >
      {isOpenMobileApp === true && (
        <div className="responsiveOpenAppContainer">
          <div className="left">
            <div className="logoContainer">
              <img src={require("../../images/main_logo.png")} alt="Logo" />
            </div>
            <div className="description">
              <h5>Watch our Shows</h5>
              <p>Open in the Mobile app</p>
            </div>
          </div>

          <div className="right">
            <div className="buttonContainer">
              <button onClick={deviceOpen}>OPEN</button>
            </div>
          </div>
        </div>
      )}

      <div className="Headerwrapper d-flex justify-content-between align-items-center">
        <div className="left">
          <h1 onClick={() => logoRedirect()}>
            <img src={require("../../images/main_logo.png")} alt="Logo" />
          </h1>
        </div>
        <div className="center">
          <ul className="nav">
            <Link
              to="/live"
              className={
                activeState === "live"
                  ? "top"
                  : liveHoverEffect
                  ? "top liveHover"
                  : "top"
              }
              onMouseOver={() => setLiveHoverEffect(true)}
              onMouseLeave={() => setLiveHoverEffect(false)}
            >
              <li
                className={
                  activeState == "live" ? "nav-links active" : "nav-links"
                }
                onClick={() => setActiveState("live")}
              >
                <span className="dot"></span>
                <span className="link">Watch Live</span>
              </li>
            </Link>
            <Link
              to="/"
              className={
                activeState === "home"
                  ? "top"
                  : HoverEffect == "home"
                  ? "top underLine"
                  : "top"
              }
              onMouseOver={() => setHoverEffect("home")}
              onMouseLeave={() => setHoverEffect(null)}
            >
              <li
                className={
                  activeState == "home" ? "nav-links active" : "nav-links"
                }
                onClick={() => setActiveState("home")}
              >
                <span className="link">Home</span>
              </li>
            </Link>
            <li
              className={
                activeState == "about"
                  ? "active nav-links d-flex align-items-center"
                  : "nav-links d-flex align-items-center"
              }
              onMouseOver={() => {
                setDropDownData("about");
                setSearchActive(false);
              }}
              onMouseLeave={() => {
                setDropDownData(null);
              }}
            >
              <span
                className="link me-2"
                onClick={() => navigate("/about-revelation")}
              >
                About
              </span>
              <span className="dropDownArrow">&#9660;</span>
              <ul
                className={
                  dropDownData == "about" ? "active submenu" : "submenu"
                }
              >
                <Link to="/about-revelation">
                  <li onClick={() => setActiveState("about")}>
                    <span className="subLink">About Revelation</span>
                  </li>
                </Link>
                <Link to="/statement-of-faith">
                  <li onClick={() => setActiveState("about")}>
                    <span className="subLink">Statement of Faith</span>
                  </li>
                </Link>
                <Link to="/leadership-team">
                  <li onClick={() => setActiveState("about")}>
                    <span className="subLink">Leadership Team</span>
                  </li>
                </Link>
                <Link to="/presenters">
                  <li onClick={() => setActiveState("about")}>
                    <span className="subLink">Presenters</span>
                  </li>
                </Link>
                <Link to="/our-studio">
                  <li onClick={() => setActiveState("about")}>
                    <span className="subLink">Our Studio</span>
                  </li>
                </Link>

                <Link to="/tv">
                  <li onClick={() => setActiveState("about")}>
                    <span className="subLink">How to Watch</span>
                  </li>
                </Link>
              </ul>
            </li>
            <li
              className={
                activeState == "news"
                  ? "active nav-links d-flex align-items-center"
                  : "nav-links d-flex align-items-center"
              }
              onMouseOver={() => {
                setDropDownData("news");
                setSearchActive(false);
              }}
              onMouseLeave={() => setDropDownData(null)}
            >
              <span
                className="link me-2"
                onClick={() => navigate("/latest-news")}
              >
                News
              </span>
              <span className="dropDownArrow">&#9660;</span>
              <ul
                className={
                  dropDownData == "news" ? "submenu active" : "submenu"
                }
              >
                <Link to="/latest-news">
                  <li onClick={() => setActiveState("news")}>
                    <span className="subLink">Latest News</span>
                  </li>
                </Link>
                <Link to="/israel-tour">
                  <li onClick={() => setActiveState("news")}>
                    <span className="subLink">Israel Tour</span>
                  </li>
                </Link>
                <Link to="/magazines">
                <li onClick={()=>setActiveState("news")}>
                  <span className="subLink">Magazines</span>
                </li>
                  </Link>
              </ul>
            </li>
            <Link
              to="/videos"
              className={
                activeState === "ondemand"
                  ? "top"
                  : HoverEffect == "ondemand"
                  ? "top underLine"
                  : "top"
              }
              onMouseOver={() => setHoverEffect("ondemand")}
              onMouseLeave={() => setHoverEffect(null)}
            >
              <li
                className={
                  activeState == "ondemand" ? "nav-links active" : "nav-links"
                }
                onClick={() => {
                  setActiveState("ondemand");
                  pageRefresh();
                }}
              >
                <span className="link">On Demand</span>
              </li>
            </Link>
            <Link
              to="/schedule"
              className={
                activeState === "schedule"
                  ? "top"
                  : HoverEffect == "schedule"
                  ? "top underLine"
                  : "top"
              }
              onMouseOver={() => setHoverEffect("schedule")}
              onMouseLeave={() => setHoverEffect(null)}
            >
              <li
                className={
                  activeState == "schedule" ? "nav-links active" : "nav-links"
                }
                onClick={() => setActiveState("schedule")}
              >
                <span className="link">Schedule</span>
              </li>
            </Link>
            <Link
              to="/donation"
              className={
                activeState === "give"
                  ? "top"
                  : HoverEffect == "give"
                  ? "top underLine"
                  : "top"
              }
              onMouseOver={() => setHoverEffect("give")}
              onMouseLeave={() => setHoverEffect(null)}
            >
              <li
                className={
                  activeState == "give"
                    ? "nav-links give active"
                    : HoverEffect == "give"
                    ? "nav-links give underLine"
                    : "nav-links give"
                }
                onClick={() => {
                  setActiveState("give");
                }}
                onMouseOver={() => setHoverEffect("give")}
                onMouseLeave={() => setHoverEffect(null)}
              >
                <span className="link">Give</span>
              </li>
            </Link>
            <li
              className={
                activeState == "prayer"
                  ? "nav-links d-flex align-items-center active"
                  : "nav-links d-flex align-items-center"
              }
              onMouseOver={() => {
                setDropDownData("prayer");
                setSearchActive(false);
              }}
              onMouseLeave={() => setDropDownData(null)}
            >
              <span
                className="link me-2"
                onClick={() => navigate("/send-prayer")}
              >
                Prayer
              </span>
              <span className="dropDownArrow">&#9660;</span>
              <ul
                className={
                  dropDownData == "prayer"
                    ? "submenu prayer active"
                    : "submenu prayer"
                }
              >
                <Link to="/send-prayer">
                  <li onClick={() => setActiveState("prayer")}>
                    <span className="subLink">Send Prayer Request</span>
                  </li>
                </Link>
                <Link to="/healing-scriptures">
                  <li onClick={() => setActiveState("prayer")}>
                    <span className="subLink">The Healing Scriptures</span>
                  </li>
                </Link>
              </ul>
            </li>
            <Link
              to="/contact-us"
              className={
                activeState === "contact"
                  ? "top"
                  : HoverEffect == "contact"
                  ? "top underLine"
                  : "top"
              }
              onMouseOver={() => setHoverEffect("contact")}
              onMouseLeave={() => setHoverEffect(null)}
            >
              <li
                className={
                  activeState == "contact" ? "nav-links active" : "nav-links"
                }
                onClick={() => setActiveState("contact")}
              >
                <span className="link">Contact</span>
              </li>
            </Link>
          </ul>
        </div>
        <div className="right d-flex align-items-center">
          {searchActive && (
            <div
              className={
                !searchActive
                  ? "searchContainer no-active"
                  : "searchContainer active"
              }
            >
              <input
                ref={searchRef}
                type="text"
                placeholder="Search..."
                onChange={(e) => searchHanldeChange(e.target.value)}
              />
              <SearchIcon
                ref={searchLogo}
                className="text-white searchIcon"
                onClick={() => searchHandler()}
              />
            </div>
          )}
          <ul className="d-flex align-items-center">
            <li
              ref={searchIconRef}
              className="icon search"
              onClick={() => {
                setSearchActive(!searchActive);
                setDropDownData(null);
              }}
            >
              {!searchActive ? (
                <SearchIcon className="text-white" />
              ) : (
                <CloseIcon />
              )}
            </li>
            <li
              className="d-flex align-items-center icon profile"
              onMouseOver={() => {
                setDropDownData("account");
                setSearchActive(false);
              }}
              onMouseLeave={() => setDropDownData(null)}
            >
              {user && profileImage ? (
                <div className="profileImageContainer">
                  <img src={profileImage} alt="ProfileImage" />
                </div>
              ) : (
                <AccountCircleIcon
                  className="text-white"
                  style={{ fontSize: "1.2em" }}
                />
              )}
              <span
                className={
                  dropDownData == "account"
                    ? "dropDownArrow active"
                    : "dropDownArrow"
                }
              >
                &#9660;
              </span>

              <ul
                className={
                  dropDownData == "account" ? "submenu active" : "submenu"
                }
              >
                {user && (
                  <>
                    <Link to="/favourites">
                      <li>
                        <span className="subLink">My Favourites</span>
                      </li>
                    </Link>
                    <Link to="/account-settings">
                      <li>
                        <span className="subLink">Account Settings</span>
                      </li>
                    </Link>
                  </>
                )}
                {isLoggedIn ? (
                  <Link to="/">
                    <li onClick={(e) => logout()}>
                      <span className="subLink">Log Out</span>
                    </li>
                  </Link>
                ) : (
                  <Link to="/register-login">
                    <li>
                      <span className="subLink">Log In / Register</span>
                    </li>
                  </Link>
                )}
              </ul>
            </li>
            <li className="menuIcon" onClick={() => setMobileMenu(true)}>
              <MenuIcon />
            </li>
          </ul>
          <div className="logo" onClick={() => navigate("/subscribe")}>
            <h1>
              <img src={require("../../images/rtimeslogo.png")} alt="Logo" />
            </h1>
          </div>
        </div>
      </div>
      {mobileMenu && (
        <>
          <div className="overlay" onClick={() => setMobileMenu(false)}></div>
          <div className={mobileMenu ? "mobileMenu active" : "mobileMenu"}>
            <div className="top">
              <span onClick={() => setMobileMenu(false)}>x</span>
            </div>
            <ul className="menu">
              <li
                className={
                  activeState == "live" ? "nav-links active" : "nav-links"
                }
                onClick={() => {
                  setActiveState("live");
                  setMobileMenu(false);
                }}
              >
                <Link to="/live">
                  <span className="link">Watch Live</span>
                </Link>
              </li>
              <li
                className={
                  activeState == "home" ? "nav-links active" : "nav-links"
                }
                onClick={() => {
                  setActiveState("home");
                  setMobileMenu(false);
                }}
              >
                <Link to="/">
                  <span className="link">Home</span>
                </Link>
              </li>
              <li
                className="nav-links d-flex"
                onClick={() => menuHandler("about")}
              >
                <div className="aboutLink">
                  <span className="link me-2">About</span>
                  <span className="dropDownArrow">&#9660;</span>
                </div>
                <ul
                  className={
                    dropDownDataMobile == "about" ? "active submenu" : "submenu"
                  }
                >
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/about-revelation">
                      <span className="subLink">About Revelation</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/statement-of-faith">
                      <span className="subLink">Statement of Faith</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/leadership-team">
                      <span className="subLink">Leadership Team</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/presenters">
                      <span className="subLink">Presenters</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/our-studio">
                      <span className="subLink">Our Studio</span>
                    </Link>
                  </li>

                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/tv">
                      <span className="subLink">How to Watch</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className="nav-links d-flex"
                onClick={() => menuHandler("news")}
              >
                <div className="newsLink">
                  <span className="link me-2">News</span>
                  <span className="dropDownArrow">&#9660;</span>
                </div>
                <ul
                  className={
                    dropDownDataMobile == "news" ? "submenu active" : "submenu"
                  }
                >
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/latest-news">
                      <span className="subLink">Latest News</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/israel-tour">
                      <span className="subLink">Israel Tour</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/magazines">
                      <span className="subLink">Magazines</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li
                className="nav-links"
                onClick={() => {
                  setMobileMenu(false);
                  pageRefresh();
                }}
              >
                <Link to="/videos">
                  <span className="link">On Demand</span>
                </Link>
              </li>
              <li
                className={
                  activeState == "schedule" ? "nav-links active" : "nav-links"
                }
                onClick={() => {
                  setActiveState("schedule");
                  setMobileMenu(false);
                }}
              >
                <Link to="/schedule">
                  <span className="link">Schedule</span>
                </Link>
              </li>
              <li
                className="nav-links"
                onClick={() => setMobileMenu(false)}
              >
                <Link to="/donation">
                  <span className="link">Give</span>
                </Link>
              </li>
              <li
                className="nav-links d-flex"
                onClick={() => menuHandler("prayer")}
              >
                <div className="prayerLink">
                  <span className="link me-2">Prayer</span>
                  <span className="dropDownArrow">&#9660;</span>
                </div>
                <ul
                  className={
                    dropDownDataMobile == "prayer"
                      ? "submenu prayer active"
                      : "submenu prayer"
                  }
                >
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/send-prayer">
                      <span className="subLink">Send Prayer Request</span>
                    </Link>
                  </li>
                  <li onClick={() => setMobileMenu(false)}>
                    <Link to="/healing-scriptures">
                      <span className="subLink">The Healing Scriptures</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-links" onClick={() => setMobileMenu(false)}>
                <Link to="/contact-us">
                  <span className="link">Contact</span>
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
