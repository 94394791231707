import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice=createSlice({
    name:"loading",
    initialState:{
        value:true
    },
    reducers:{
        getLoadingSlice:(state,action)=>{
            state.value=action.payload.loading
        }
    }
})

export const {getLoadingSlice}=loadingSlice.actions
export default loadingSlice.reducer