import React, { useEffect, useState } from 'react'
import { playerToken } from '../../../CommonService'
import SubscribeBanner from '../../../Components/SubscribeBanner/SubscribeBanner'
import VideoJS from '../../../Components/VideoJsPlayer/VideoJS'
import * as service from "./service"
const AboutRevelation = () => {
    const [data,setData]=useState()
    const [descriptionData,setDescriptionData]=useState()
    const [videoUrl,setVideoUrl]=useState("")
    const [videoPlayer,setVideoPlayer]=useState()
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        setLoading(true)
        window.scroll(0,0)
        service.getAboutDetails().then((response)=>{
            setData(response?.data)
            setDescriptionData(response?.data?.description?.split("\n"))
            playerToken().then((tokenResponse)=>{
                let arr=response?.data?.video?.video_name?.split("/").reverse();
                setVideoUrl(
                    "https://poppo.tv/playlist/playlist.m3u8?id=" +
                    arr[1] +
                    "&token=" +
                    tokenResponse?.data?.data +
                    "&type=video" +
                    "&pubid=" +
                    process.env.REACT_APP_PUBID
                )
            })
        })
    },[])

    useEffect(()=>{
        if(videoUrl){
            
            const videoJsOptions = {
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                sources: [{
                  src: `${videoUrl}`,
                  type: 'application/x-mpegURL',
                }],
                poster:require("../../../images/thumb.png")
              };
              setLoading(false)
            
            setVideoPlayer(<VideoJS options={videoJsOptions}   />)

        }
    },[videoUrl])

    if(loading){
        return(
            <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
        )
    }
  return (
    <div className='aboutRevelation'>
        <div className="wrapper">

            <div className="headContainer position-relative">
                <div className="glowWhite"></div>
                <div className="glowRed1"></div>
                <div className="glowRed2"></div>
                    <p>{data?.title}</p>
                <div className="revLogoContainer">
                    <img src={require("../../../images/rev_logo_image.png")} alt="Logo" />
                </div>
            </div>
            <div className="videoContainer">
                {
                    videoPlayer
                }
            </div>
            <div className="about">
                <p>{descriptionData?.[0]}</p>
                
                <p>{descriptionData?.[2]}</p>
            </div>
            
            <div className="details">
                <ul>
                    {
                        descriptionData?.map((item,index)=>{
                            if(index!=0&&index!=2&&item!=""){
                                return(
                                    <li key={index}>
                        <p>{item}</p>
                    </li>
                                )
                            }
                        })
                    }
                </ul>
            </div>
        </div>
        <SubscribeBanner/>
        
    </div>
  )
}

export default AboutRevelation