import React from "react";
import { useState, useRef, useEffect } from "react";
import { ReactComponent as ShareIcon } from "../../../images/svg/share.svg";
import { ReactComponent as DownArrow } from "../../../images/svg/down-arrow.svg";
import youtubeIcon from "../../../images/youtube.png";
import instagramIcon from "../../../images/instagram.png";
import facebookIcon from "../../../images/facebook.png";
import twitterIcon from "../../../images/twitter.png";
import calanderIcon from "../../../images/icon-calendartime.png";
import freestat from "../../../images/freestat.jpg";
import staticVideo from "../../../StaticVideo/static.mp4"
import articleImage from "../../../images/RokuArticle.jpg";
import {
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
export default function Roku() {
  let shareRef = useRef(null);
  let shareIconRef = useRef(null);
  const [shareButton, setShareButton] = useState(false);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);
  return (
<div className="rokuDevices">
<div className="wrapper">
  <h1 className="heading">Roku® streaming device</h1>

  <div className="contents">
    <div className="sections">
      <div className="imageContainer">
      <img src={articleImage} />
      </div>
      <div className="description">
        <p>
        <strong>Roku® streaming devices offer a reliable and economic way to watch Revelation TV on most modern televisions with a HDMI input located where wireless Internet is available in your home. Some televisions come with a Roku® streaming device pre-installed. You can buy a Roku® streaming device or a new TV with preinstalled Roku® from Argos and other UK stores.</strong>
   </p>
     <br></br>
                 <p >  Roku® streaming devices cost less than $30/£30 to purchase with no   monthly subscription costs to view Revelation TV. Recommended UK    Retailers -{" "}
            <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.roku.com/intl?next=/products/players&source=www.roku.com"
                  )
                }
              >
                click here.
              </span>{" "}
              .
            </p>

            <p >
              To use your Roku® streaming devices you will need to{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() => window.open("https://my.roku.com/signup")}
              >
                create
              </span>{" "}
              a free account activating your new Roku® device on website
              www.roku.com.
            </p>

            <p >
              Once your Roku® account and device are activated on the Roku®
              website, remain logged in and you should be able to add Revelation
              TV to your Roku®device using this{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://channelstore.roku.com/en-ot/details/13f12a7b81f66e3c07b7009996c45a41/revelation-tv"
                  )
                }
              >
                link
              </span>{" "}
              If you are already a Roku® user log in to your existing Roku®
              account and use the same link
            </p>
            <p >
              Alternatively once your Roku® account and device are activated you
              can add Revelation TV on your TV screen using your remote control.
              Go to "Streaming Channels" "Religious" then scroll down till you
              find Revelation TV. There are a large number of channels in this
              category so you make need to scroll down several rows.of channels.
              Currently Revelation TV is around row 40. If you see a "Search"
              option then you could skip the above and type in "Revelation TV"
              and then add.
            </p>
            <p >
              Should you find you need further help in activating or linking
              your Roku® streaming device to your Roku® account please read this{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() =>
                  window.open("https://support.roku.com/article/235180868")
                }
              >
                information
              </span>{" "}
              from Roku®. There are some videos on YouTube that you may find
              helpful like this{" "}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={() =>
                  window.open("https://www.youtube.com/watch?v=It_ZSbzwiWg")
                }
              >
                one
              </span>
              . You can also phone Roku®. support for further help on +44
              (0)2036841123.
            </p>
            <p >
              Please be aware that once your Roku® account is set up you can
              delete any credit or debit card information given. PayPal can also
              be used to set up a Roku® account. Currently you can set up a
              verified PayPal account using your email address without the need
              to complete the add bank or credit card details stage. With your
              verified PayPal account you can open a Roku® account and set up
              your Roku® device.
            </p>
            <br></br>
        
          <p className="italic">
            If you expect to watch live shows on Revelation TV in the UK please visit this<span
              style={{ color: "blue", cursor: "pointer",margin:'5px' }}
              onClick={() =>
                window.open(
                  "https://support.revelationtv.com/knowledgebase/articles/460473-tv-licensing-and-revelation-tv-online"
                )
              }
            >
              site
            </span>
            //support.revelationtv.com/knowledgebase/articles460473-tv-licensing-and-revelation-tv-online
          </p>    
      </div>
    </div>
<div className="date">
      <div className="icon">
        <img
          src={require("../../../images/icon-calendartime.png")}
          alt="Calender"
        />
      </div>
      <span>13 Nov 2019</span>
    </div>
<div className="video-player-container">
          <video
              controls
              src={staticVideo}
            ></video>
          </div>
    <div className="buttonContainer">
              <button ref={shareIconRef} onClick={()=>setShareButton(!shareButton)} className={shareButton?"active shareButton ":"shareButton"}>
                  <ShareIcon/>
                  <span>Share</span>
                  
                  <DownArrow className='active'/>
              </button>
              {
                  shareButton&&
                  <div className="shareContainer" ref={shareRef}>
                      <FacebookShareButton
                          url={window.location.href}
                      >
                          <span className='name facebook'>Facebook</span>
                      </FacebookShareButton>
                      <TwitterShareButton
                          url={window.location.href}
                      >
                          <span className='name twitter'>Twitter</span>
                      </TwitterShareButton>
                      <CopyToClipboard text={window.location.href}>
                          <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                      </CopyToClipboard>
                      <EmailShareButton
                          url={window.location.href}
                      >
                          <span className='name'>Mail</span>
                      </EmailShareButton>
                      <WhatsappShareButton  url={window.location.href}>
                    <span className='name whatsapp'>Whatsapp</span>
                    </WhatsappShareButton   >
                  </div>
              }
          </div>
  </div>
</div>
</div>

  );
}
