import moment from "moment";
export const convertAdUrl = (videoDetails, showDetails) => {
    const adUrl = showDetails?.ad_link;
    const currentLocation = JSON.parse(localStorage.getItem("currentLocation"));
    let uId = 280;
    let user_id = localStorage.getItem("userId");
    if (user_id) {
      uId = user_id;
    }
    const width = window.innerWidth;
    const height = window.innerHeight;
    const dnt = 0;
    const ipAddress = currentLocation.IPv4;
    const latitude = currentLocation.latitude;
    const longitude = currentLocation.longitude;
    const userAgent = navigator.userAgent;
    const deviceIfa = "";
    const uuid = "";
    const country = currentLocation.country_name;
    const deviceId = localStorage.getItem("deviceId");
    const keyword = showDetails?.categories[0]?.category_name;
    const deviceModel = navigator.userAgent;
    const deviceMake = navigator.userAgent;
    const channelId = process.env.REACT_APP_CHANNELID;
    const userId = uId;
    const videoId = videoDetails.video_id;
    const bundleId = "";
    const appName = "revelationTv";
    const duration = showDetails?.videos[0]?.video_duration
      ? showDetails?.videos[0]?.video_duration * 60
      : "";
    const appstoreUrl = window.location.href;
    const city = currentLocation.city;
    const region = currentLocation.state;
    const showid = showDetails?.show_id;
    const categories = showDetails?.categories
      .map((item) => item.category_id)
      .join();
  
    const finalAdurl = adUrl
      .replace("[WIDTH]", width)
      .replace("[HEIGHT]", height)
      .replace("[DNT]", dnt)
      .replace("[IP_ADDRESS]", ipAddress)
      .replace("[LATITUDE]", latitude)
      .replace("[LONGITUDE]", longitude)
      .replace("[USER_AGENT]", userAgent)
      .replace("[DEVICE_IFA]", deviceIfa)
      .replace("[UUID]", uuid)
      .replace("[COUNTRY]", country)
      .replace("[DEVICE_ID]", deviceId)
      .replace("[KEYWORDS]", keyword)
      .replace("[DEVICE_MODEL]", deviceModel)
      .replace("[DEVICE_MAKE]", deviceMake)
      .replace("[CHANNEL_ID]", channelId)
      .replace("[USER_ID]", userId)
      .replace("[VIDEO_ID]", videoId)
      .replace("[BUNDLE]", bundleId)
      .replace("[APP_NAME]", appName)
      .replace("[DURATION]", duration)
      .replace("[APP_STORE_URL]", appstoreUrl)
      .replace("[CITY]", city)
      .replace("[REGION]", region)
      .replace("[SHOW_ID]", showid)
      .replace("[CATEGORIES]", categories);
  
    return finalAdurl;
  };

  export const getSessionId = () => {
    let date = new Date();
    let millis = date.getTime();
    let deviceId = localStorage.getItem("deviceId");
    let sessionId = millis + deviceId;
    localStorage.setItem("session_id", sessionId);
  };

  export const convertTimeToLocal = (date) => {
    let tempDate = moment.utc(date);
    let localDate = moment(tempDate).local();
    let timeData = localDate.format("hh:mm A");
    return timeData;
  };
  export const convertTime = (duration) => {
    var hours = Math.floor(duration / 60);
    var minutes = duration % 60;
    var min = Math.round(minutes);
    if (hours === 0) {
      return min + "min";
    } else {
      return hours + "hr, " + min + "min";
    }
  };
  export const convertSecondsToMin = (d) => {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);
  
    var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
    return hDisplay + mDisplay;
  };

  export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  export const checkOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
    //Check mobile device is Android
    if (/android/i.test(userAgent)) {
      //Add your Code here
      return "android";
    }
  
    //Check mobile device is IOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      //Add your Code here
      return "iPhone";
    }
  
    //Check device os is Windows (For Laptop and PC)
    if (navigator.appVersion.indexOf("Win") != -1) {
      //Add your Code here
      return "window";
    }
  
    //Check device os is MAC (For Laptop and PC)
    if (navigator.appVersion.indexOf("Mac") != -1) {
      //Add your Code here
      return "mac";
    }
    return "none";
  };

  export const getBrowserType = () => {
    var isFirefox = typeof InstallTrigger !== "undefined";
    if (isFirefox === true) {
      return "Firefox";
    }
    var isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      })(
        !window["safari"] ||
          (typeof window["safari"] !== "undefined" &&
            window["safari"].pushNotification)
      );
    if (isSafari === true) {
      return "Safari";
    }
    var isIE = false || !!document.documentMode;
    if (isIE === true) {
      return "IE";
    }
    var isEdge = !isIE && !!window.StyleMedia;
    if (isEdge === true) {
      return "Edge";
    }
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    if (isChrome === true) {
      return "Chrome";
    }
    var isEdgeChromium = isChrome && navigator.userAgent.indexOf("Edg") != -1;
    if (isEdgeChromium === true) {
      return "EdgeChromium";
    }
  };