import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as service from "./service";
import { getAccountDetails } from '../../../Redux/AccountDetails/accountDetailsSlice';
const AccountSettings = () => {
  const [accountDetails,setAccountDetails]=useState()
  const[successMessage,setSuccessMessage]=useState(false)
  const [fNamevalidation,setFnameValidation]=useState("")
    const [lNamevalidation,setLnameValidation]=useState("")
    const [passwordValidation,setPasswordValidation]=useState("")
  const [loading,setLoading]=useState(true)
  const[profileWill,setProfileWill]=useState()
  const accountDetailsRedux=useSelector((state)=>state?.accountDetails?.value)
  const[inputValues,setInputValues]=useState({
    firstname:"",
    lastname:"",
    email:"",
    country:"",
    currentpassword:"",
    file:"",
    country_name:""
  })
  const [passwordIncorrectMessage,setPasswordIncorrectMessage]=useState(false)
 const countries=[]
 const dispatch=useDispatch()
  const countryList = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas (the)",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory (the)",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands (the)",
    CF: "Central African Republic (the)",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands (the)",
    CO: "Colombia",
    KM: "Comoros (the)",
    CD: "Congo (the Democratic Republic of the)",
    CG: "Congo (the)",
    CK: "Cook Islands (the)",
    CR: "Costa Rica",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    CI: "Côte d'Ivoire",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic (the)",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (the) [Malvinas]",
    FO: "Faroe Islands (the)",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories (the)",
    GA: "Gabon",
    GM: "Gambia (the)",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (the)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Islamic Republic of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (the Democratic People's Republic of)",
    KR: "Korea (the Republic of)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic (the)",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands (the)",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia (Federated States of)",
    MD: "Moldova (the Republic of)",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands (the)",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger (the)",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands (the)",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines (the)",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    MK: "Republic of North Macedonia",
    RO: "Romania",
    RU: "Russian Federation (the)",
    RW: "Rwanda",
    RE: "Réunion",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan (the)",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands (the)",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates (the)",
    GB: "United Kingdom",
    UM: "United States Minor Outlying Islands (the)",
    US: "United States of America (the)",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela (Bolivarian Republic of)",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
  };
  for(let i in countryList){
   countries.push({country_code:i,name:countryList[i]})
  }
  
  useEffect(()=>{
    window.scroll(0,0)
    setLoading(true)
    if(accountDetailsRedux){
      const country_code=accountDetailsRedux?.country_code
      setAccountDetails(accountDetailsRedux)
      setLoading(false)
      setInputValues({...inputValues,firstname:accountDetailsRedux?.first_name,lastname:accountDetailsRedux?.last_name,email:accountDetailsRedux?.user_email,country:country_code,country_name:countryList[country_code]})


    }
  },[accountDetailsRedux])

const formValidation=(values)=>{
  let nameRegex = /^[a-zA-Z ]{3,30}$/;
  if(
    values?.firstname?.match(nameRegex)&&
    values?.lastname?.match(nameRegex)&&
    values?.currentpassword!=""
    ){
      setFnameValidation(null)
      setLnameValidation(null)
      setPasswordValidation(null)
    return true
  }else{
    return false
  }
}

  const onSubmitHandler=(e)=>{
    e.preventDefault()
    
    if(formValidation(inputValues)==true){
      service.userDetailUpdate(inputValues).then((response)=>{
        if(response?.success==true){
          setPasswordIncorrectMessage(false)
          setSuccessMessage(true)
          service.accountDetails().then((response)=>{
            dispatch(getAccountDetails({
              accountDetails:response?.data
            }))
          })

        }else{
          if(response?.message=="Incorrect Password"){
            setPasswordIncorrectMessage(true)
            setSuccessMessage(false)
          }
        }
      }).catch((err)=>{
      })
    }else{
      let nameRegex = /^[a-zA-Z ]{3,30}$/;

    if(inputValues?.firstname==""){
      setFnameValidation("Field cannot be blank!")
    }else{
      if(inputValues?.firstname?.match(nameRegex)){
        setFnameValidation(null)
    }else{
        setFnameValidation("Name should be 3-30 characters and shouldn't include any special character")
    }
    }

    if(inputValues?.lastname==""){
      setLnameValidation("Field cannot be blank!")
  }else{
      if(inputValues?.lastname?.match(nameRegex)){
          setLnameValidation(null)
      }else{
          setLnameValidation("Name should be 3-30 characters and shouldn't include any special character")
      }
  }

  if(inputValues?.currentpassword==""){
    setPasswordValidation("Field cannot be blank!")
  }else{
    setPasswordValidation(null)
  }
    }
  }
  const handleFileChange = (e)=>{
    var allowedExtensions =/(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if(!allowedExtensions.exec(e?.target?.files[0]?.name)){
      alert("Invalid extension")
    }else{

      const name= e?.target?.files[0]?.name;
      const files = e?.target?.files;
      setInputValues({
        ...inputValues,
        file: files[0],
      });
      setProfileWill(URL.createObjectURL(files[0]))
    }
  }
  return (
    <>
    {
      loading?(
        <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
      ):(

    <div className='accountSettings'>
    <div className="wrapper">   
    <div className="head" > 
     <h1 className="heading">Account Settings</h1></div>
      
        <div className="contents">
          <div className="left">
            <div className="profileImageContainer">
              {
                profileWill?(
                  <img src={profileWill} alt="Profile" />

                  ):accountDetails?.user_image?(
                  <img src={accountDetails?.user_image} alt="Profile" />

                  ):(
                    <img src={require("../../../images/user_avatar.png")} alt="Avatar" />
                  )
              }
              <input type="file" id='file' accept="image/*" onChange={(e)=>handleFileChange(e)} />
              <label htmlFor='file'>Change Picture</label>
            </div>
          </div>
          <div className="right">
            <div className="formSection">
                <form>
                    <div className="half">
                        <div className="fname">
                            <label htmlFor="#fname">First Name</label>
                            <input value={inputValues.firstname || ""} type="text" id='fname' onChange={(e)=>setInputValues({...inputValues,firstname:e.target.value})} />
                            {
                                    fNamevalidation&&
                                <span className="error text-danger">{fNamevalidation}</span>
                                }
                        </div>
                        <div className="lname">
                            <label htmlFor="#lname">Last Name</label>
                            <input type="text" id='lname' value={inputValues.lastname || ""} onChange={(e)=>setInputValues({...inputValues,lastname:e.target.value})} />
                            {
                            lNamevalidation&&
                              <span className="error text-danger">{lNamevalidation}</span>
                            }
                        </div>
                    </div>
                    <div className="half">
                        <div className="email">
                            <label htmlFor="email">Email</label>
                            <input value={inputValues.email || ""} disabled type="email" id='email' onChange={(e)=>setInputValues({...inputValues,email:e.target.value})}/>
                        </div>
                 
                        <div className="country">
                            <label htmlFor="text">Country</label>
                            <select
                              name="country"
                              id="country"
                              value={inputValues.country || ""}
                              onChange={(e) =>
                                setInputValues({
                                  ...inputValues,
                                  country: e.target.value,
                                })
                              }
                            >
                              <option defaultValue={inputValues?.country}>{inputValues?.country_name}</option>
                              {
                                countries?.map((data,index)=>{
                                  if(data?.country_code!==accountDetails?.country_code){
                                    return(

                                      <option key={index} value={data?.country_code}>{data?.name}</option>
                                    )
                                  }
                                })
                              }
                            </select>
                        </div>
                    </div>
                    <div className="full">
                      <div className="password">
                        <label htmlFor="password">Current Password</label>
                        <input type="password" id='password' value={inputValues.currentpassword || ""} onChange={(e)=>setInputValues({...inputValues,currentpassword:e.target.value})} />
                        {
                            passwordValidation&&
                              <span className="error text-danger">{passwordValidation}</span>
                            }
                      </div>
                      <Link to="/change-password">
                        <span>Change Password</span>
                      </Link>
                    </div>
                    
                    <div className="buttonContainer">
                        <button type='submit' className="bg-button" onClick={(e)=>onSubmitHandler(e)}>Save Changes</button>
                    </div>
                    {
                      passwordIncorrectMessage&&
                    <span className="error">Your password is incorrect</span>
                    }
                    {
                      successMessage && 
                    <span className="success text-success">Updated successfully</span>

                    }
        </form>
            </div>
          </div>
        </div>
                    

    </div>
</div>
      )
    }
    </>
  )
}

export default AccountSettings
