import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import PresenterCard from "../../../Components/PresenterCard/PresenterCard"
import * as service from "./service";

const LeaderShipTeam = () => {
  const [leadershipTeamData,setLeaderShipTeamData] = useState()
  const [loading,setLoading]=useState(true)
  const loadingStatus=useSelector((state)=>state?.loading?.value)

  useEffect(()=>{
    setLoading(true)
    service.getLeaderShipData().then((response)=>{
      setLeaderShipTeamData(response?.data)
      setLoading(false)
    })
  },[])
  
  return (
    <>
    {
      loading?(
        <div className="loadingContainer" >
               <div className="loadingOverlay"></div>
               <div className="spinnerIcon">

               <div className="inner lds-dual-ring"></div>
               </div>
            </div>
      ):(

    <div className='leaderShipContainer'>
      <div className="wrapper">
        <h1 className="heading">
          Leadership Team
        </h1>
        {
          leadershipTeamData?.data?.map((item,index)=>(

            <PresenterCard key={index} title={item?.title} partners={item?.partners}/>
          ))
        }
        
        
      </div>
    </div>
      )
    }
    </>
  )
}

export default LeaderShipTeam