import { createSlice } from "@reduxjs/toolkit";

export const homeDataSlice=createSlice({
    name:"homeData",
    initialState:{
        value:[]
    },
    reducers:{
        getHomeData:(state,action)=>{
            state.value=action.payload.homeData
        }
    }
})

export const {getHomeData}=homeDataSlice.actions
export default homeDataSlice.reducer