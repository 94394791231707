import axios from "axios";
const qs = require("query-string");

export const donationAdd = (values) => {
  let uId =localStorage.getItem("guestUserId")
  let countryCode = localStorage.getItem("country_code");
  let ipaddress = localStorage.getItem("ipaddress");
  let deviceId = localStorage.getItem("deviceId");
  let user_id = localStorage.getItem("userId")
  var token = localStorage.getItem("access_token");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
  };
  const data = {
    first_name:values?.firstName,
    last_name:values?.lastName,
    email:values?.email,
    phone:values?.phone,
    type:values?.paymentType,
    payment_method:values?.paymentGateway,
    address:values?.address,
    address1:values?.address1,
    address2:values?.address2,
    address3:values?.address3,
    postal_code:values?.postalCode,
    city:values?.city,
    state:values?.state,
    country:values?.country,
    account_holder:values?.accountHolder,
    account_number:values?.accountNumber,
    sort_code:values?.sortCode,
    currency:values?.paymentCurrency,
    amount:values?.paymentAmount,
    monthly_donation_date:values?.mdd,
    contact_by_email:values?.contactByEmail,
    contact_by_phone:values?.contactByPhone,
    contact_by_post:values?.contactByPost,
    is_gift_aid:values?.giftAidForm,
    number_of_payments:values?.noOfPayment,
    donation_use:values?.donationUse,
    newsletter_subscribed:values?.notification,
    house_number:values?.houseNo
  };
  return axios
    .post(`${process.env.REACT_APP_API_URL}donation/add`,
    qs.stringify(data)
    , customConfig)
    .then((response) => {
      return response?.data;
    })
    .catch((err) => {
      return [];
    });
};
export const donationUserData=(key) => {
  let countryCode=localStorage.getItem("country_code");
  let ipaddress=localStorage.getItem("ipaddress");
  let deviceId=localStorage.getItem("deviceId");
  var token = localStorage.getItem("access_token");
 
const customConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": true,
    crossorigin: true,
    "access-token": token,
    pubid: process.env.REACT_APP_PUBID,
    country_code: countryCode,
    channelid: process.env.REACT_APP_CHANNELID,
    dev_id: deviceId,
    ip: ipaddress,
    device_type: "web",
  },
    params: {
      key: key,
    },
  };
  return axios
    .get(process.env.REACT_APP_API_URL + "donation/authenticate", customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}