import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./User/userSlice";
import presenterReducer from "./PresentersList/presentersSlice"
import favandRemReducer from "./Favourites&Reminders/FavAndRemSlice.";
import homeDataReducer from "./HomeData/homeDataSlice";
import loadingReducer from "./Loading/loadingSlice";
import accountDetailsReducer from "./AccountDetails/accountDetailsSlice"
import donationKeyReducer from "./DonationKey/DonationKeySlice";
import scheduleRefreshReducer from "./ScheduleRefresh/ScheduleRefreshSlice";
export default configureStore({
    reducer:{
        user:userReducer,
        presenters:presenterReducer,
        favAndreminders:favandRemReducer,
        homeData:homeDataReducer,
        loading:loadingReducer,
        accountDetails:accountDetailsReducer,
        donationKey:donationKeyReducer,
        scheduleRefresh:scheduleRefreshReducer
    }
})