import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MagazineCard from "../../../Components/MagazineCard/MagazineCard";
import { getMagazines } from "./service";

const MagazinesList = () => {
  const [magazines, setMagazines] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    getMagazines().then((response) => {
      if (response?.success === true) {
        setMagazines(response?.data);
        setLoading(false)

      }else{
        setLoading(false)
      }
    });
  }, []);

  if(loading){
    return(
        <div className="loadingContainer" >
           <div className="loadingOverlay"></div>
           <div className="spinnerIcon">

           <div className="inner lds-dual-ring"></div>
           </div>
        </div>
    )
}

  return (
    <div className="magazinesList">
      <div className="wrapper">
        <h1 className="heading">Magazines</h1>
        <div className="magazines">
          {magazines?.map((item, index) => (
            <div className="item">
              <MagazineCard
                thumbnail={item?.image}
                title={item?.name}
                url={item?.url}
                data={item}
                downloadButton={false}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MagazinesList;
