import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'


const PrivateRoutes = () => {
  const user=useSelector((state)=>state?.user?.value)
  if(user){
    return(
      <Outlet/>
    )
  }else{
    return(
      <Navigate to="/register-login"/>
    )
  }
}

export default PrivateRoutes