import React from 'react'
import { useState } from 'react'
import * as service from "./service"
const ForgotPassword = () => {
  const [inputValue,setInputValue]=useState("")
  const [validation,setValidation]=useState("")
  const [buttonDisabled,setButtonDisabled]=useState(true)
  const [successMessage,setSuccessMessage]=useState(false)
 
  const validateEmail = (email) => {
    {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      }
      return false;
    }
  };
  const submitHandler=(e)=>{
    e.preventDefault()
    if(validateEmail(inputValue)){
      setValidation(null)
      service.forgotPasswordEmailVerify(inputValue).then((response)=>{
        if(response?.success==true){
          if(response?.message=="Email has been sent successfully"){
            setSuccessMessage(true)
          }
        }else{
          if(response?.message=="Invalid user"){
            setValidation("User not found!")
            setSuccessMessage(false)
          }
        }
      })
    }else{
      setSuccessMessage(false)
      setValidation("Enter valid email address!")
    }
  }
  return (
    <div className='forgotPassword'>
        <div className="wrapper">
            <h1 className="heading">Reset Password</h1>
            <div className="resetEmailContainer">
                <form>
                  <div className="inputContainer">
                    <label htmlFor="email">Email</label>
                    <input type="email" onChange={(e)=>setInputValue(e.target.value)}  />
                    {
                      validation!="" &&
                      <span className="error">{validation}</span>
                    }
                    {
                      successMessage &&
                      <span className="success text-success">Email has been sent successfully</span>
                    }
                    <button  className='bg-button' type='submit' onClick={(e)=>submitHandler(e)} >Reset Password</button>

                  </div>
                </form>
            </div>
        </div>
    </div>
  )
}

export default ForgotPassword