import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Products = () => {
  const navigate = useNavigate()

  useEffect(()=>{
    window.scroll(0,0)
  },[])
  return (
    <div className="products">
      <div className="watchSection">
      <h1 className="title">Watch</h1>
      <ul>
       
      <li onClick={()=>window.open("https://www.amazon.co.uk/Broadcast-Systems-Europe-Ltd-Revelation/dp/B0BSCHMZ31","_blank")}>
          <img src={require("../../images/firetv.png")} alt="Icon" />
          <span className="name">
            Fire TV
          </span>
        </li>
        <li onClick={()=>window.open("https://play.google.com/store/apps/details?id=com.revelation.android","_blank")}>
          <img src={require("../../images/android.png")} alt="Icon" />
          <span className="name">
            Android
          </span>
        </li>
        <li onClick={()=>window.open("https://apps.apple.com/us/app/revelation-tv/id1226981767","_blank")}>
          <img src={require("../../images/ios.png")} alt="Icon" />
          <span className="name">
            ios
          </span>
        </li>
        <li onClick={()=>window.open("https://apps.apple.com/us/app/revelation-tv/id1226981767?platform=appleTV","_blank")}>
          <img src={require("../../images/apple tv.png")} alt="Icon" />
          <span className="name">
            Apple TV
          </span>
        </li>
        <li onClick={()=>navigate('/roku')}>
          <img src={require("../../images/roku.png")} alt="Icon" />
          <span className="name">
            Roku
          </span>
        </li>
        <li onClick={()=>navigate('/tv')}>
          <img src={require("../../images/smart.png")} alt="Icon" />
          <span className="name">
            TV
          </span>
        </li>
      </ul>
    </div>
    </div>

    
  );
};

export default Products;
