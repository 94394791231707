import React, { useEffect, useState } from "react";
import CategoryTile from "../../../Components/CategoryTile/CategoryTile";
import Show from "../../../Components/ScheduleCard/ScheduleCard";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import * as service from "./service";
import { ReactComponent as PlusIcon } from "../../../images/svg/plus.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as DownArrow } from "../../../images/svg/down-arrow.svg";

const ReminderAndFav = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const loadingStatus = useSelector((state) => state?.loading?.value);
  const [loading, setLoading] = useState(true);
  const getFavAndRemRedux = useSelector(
    (state) => state?.favAndreminders?.value
  );
  const [sliceCount, setSliceCount] = useState(4);

  useEffect(() => {
    window.scroll(0, 0);
    setLoading(true);
    service.getFavourites().then((response) => {
      setData(response?.data);
      setLoading(false);
    });
  }, []);
  useEffect(() => {
    if (getFavAndRemRedux) {
      setData(getFavAndRemRedux);
    }
  }, [getFavAndRemRedux]);
  const showMoreHandler = () => {
    setSliceCount(sliceCount + 4);
  };
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <div className="loadingOverlay"></div>
          <div className="spinnerIcon">
            <div className="inner lds-dual-ring"></div>
          </div>
        </div>
      ) : (
        <div className="Reminder-favouritesSection">
          <div className="wrapper">
            <div className="favourites">
              <h1 className="heading">Favourites & Reminders</h1>
              {data?.filter((mainData, index) => {
                return mainData?.type == "SHOWS";
              }).length == 0 && <h1 className="nodata">No Favourites Found</h1>}
              {data?.map((mainData, index) =>
                mainData?.type == "SHOWS" ? (
                  mainData?.data?.length <= 4 ? (
                    <>
                      <div className="head">
                        <div className="left">
                          <span className="title">Favourites</span>
                          <span className="icon">
                            <img
                              src={
                                require("../../../images/svg/star-red.svg")
                                  .default
                              }
                              alt="Magazine"
                            />
                          </span>
                        </div>
                      </div>
                      <div className="favouritesContainer">
                        {mainData?.data?.map((item, index) => {
                          if (item) {
                            return (
                              <div className="item" key={index}>
                                <ShowCard
                                  cardType="dateCard"
                                  title={item?.show_name}
                                  year={item?.year}
                                  description={item?.synopsis}
                                  thumbnail={item?.logo}
                                  innerCard="innerCard"
                                  id={item?.vanity_url?item.vanity_url:item?.show_id}
                                  navigate="show"
                                  date={item?.schedule_date}
                                  favourites={item?.watchlist_flag}
                                  showId={item?.show_id}
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                    </>
                  ) : (
                    // mainData?.data>4&&
                    <CategoryTile
                      cardType="dateCard"
                      title="Favourites"
                      shows={mainData?.data}
                      innerCard="innerCard"
                    />
                  )
                ) : (
                  mainData?.type == "REMINDERS" && (
                    <div className="reminders">
                      <h1 className="heading">Reminders</h1>
                      {mainData?.data
                        ?.slice(0, sliceCount)
                        ?.map((item, index) => (
                          <Show
                            key={index}
                            thumbnail={item?.thumbnail_350_200}
                            startTime={item?.starttime}
                            title={item?.video_title}
                            description={item?.video_description}
                            id={item?.id}
                            reminded={item?.schedule_reminded}
                            showId={item?.vanity_url?item?.vanity_url:item?.show_id}
                            data={item}
                          />
                        ))}
                    </div>
                  )
                )
              )}
              
            </div>
          </div>
          {data?.[0]?.key == "Reminders" && (
            <>
              {data?.[0].data?.length > sliceCount && (
                <div className="gradientBg">
                  <div className="buttonContainer text-center">
                    <button
                      className="showMore mt-5"
                      onClick={() => showMoreHandler()}
                    >
                      Show More
                      <DownArrow />
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
          {data?.[1]?.key == "Reminders" && (
            <>
              {data?.[1].data?.length > sliceCount && (
                <div className="gradientBg">
                  <div className="buttonContainer text-center">
                    <button
                      className="showMore mt-5"
                      onClick={() => showMoreHandler()}
                    >
                      Show More
                      <DownArrow />
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ReminderAndFav;
