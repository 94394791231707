import { createSlice } from "@reduxjs/toolkit";

export const favandRemSlice=createSlice({
    name:"favouritesAndReminders",
    initialState:{
        value:[]
    },
    reducers:{
        getFavandReminders:(state,action)=>{
            state.value=action.payload.favouritesAndReminders
        }
    }
})

export const {getFavandReminders}=favandRemSlice.actions
export default favandRemSlice.reducer