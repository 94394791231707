import React, { useEffect } from 'react'
import { Routes,Route, useLocation, useNavigate, useParams, Outlet, Navigate } from 'react-router-dom'
import Footer from '../Components/Footer/Footer'
import Header from '../Components/Header/Header'
import AboutRevelation from '../views/pages/AboutSection/AboutRevelation'
import LeaderShipTeam from '../views/pages/AboutSection/LeaderShipTeam'
import ArticleInfo from '../views/pages/ArticleInfo/ArticleInfo'
import BuildingFund from '../views/pages/BuildingFund/BuildingFund'
import Contact from '../views/pages/Contact/Contact'
import DonateByDebit from '../views/pages/DonationSection/DonateByDebit'
import Donation from '../views/pages/DonationSection/Donation'
import HealingScriptures from '../views/pages/HealingScriptures/HealingScriptures'
import Home from '../views/pages/Home/Home'
import IsraelTour from '../views/pages/IsraelTour/IsraelTour'
import Live from '../views/pages/LivePage/Live'
import LatestNews from '../views/pages/NewsSection/LatestNews'
import OnDemand from '../views/pages/OnDemand/OnDemand'
import Prayer from '../views/pages/PrayerSection/Prayer'
import Presenters from '../views/pages/PresentersSection/Presenters'
import PresentersInfo from '../views/pages/PresentersSection/PresentersInfo'
import ReminderAndFav from '../views/pages/Reminder&Fav/ReminderAndFav'
import Schedule from '../views/pages/SchedulePage/Schedule'
import SearchResult from '../views/pages/SearchResult/SearchResult'
import ShowInformation from '../views/pages/ShowInformation/ShowInformation'
import StatementOfFaith from '../views/pages/StatementOfFaith/StatementOfFaith'
import VideoPlayer from '../views/pages/VideoPlayer/VideoPlayer'
import AccountSettings from '../views/pages/AccountSetting/AccountSetting'
import ChangePassword from '../views/pages/ChangePassword/ChangePassword'
import Subscribe from '../views/pages/Subscribe/Subscribe'
import FeaturedShows from '../views/pages/AllShows/FeaturedShows'
import RegisterLogin from '../views/pages/Register&Login/RegisterLogin'
import { useDispatch, useSelector } from 'react-redux'
import * as service from "../CommonService"
import { getHomeData } from '../Redux/HomeData/homeDataSlice'
import { useState } from 'react'
import Slider from "react-slick";
import GiftAidInfo from '../views/pages/DonationSection/GiftAidInfo/GiftAidInfo'
import { getUser } from '../Redux/User/userSlice'
import { getLoadingSlice } from '../Redux/Loading/loadingSlice'
import ForgotPassword from '../views/pages/ForgetPassword/ForgotPassword'
import ResetPassword from '../views/pages/ForgetPassword/ResetPassword'
import Featured from '../views/pages/AllShows/Featured'
import PrivacyPolicy from '../views/pages/PrivacyPolicy/PrivacyPolicy'
import PageNotFound from '../views/pages/PageNotFound/PageNotFound'
import PrivateRoutes from './PrivateRoutes'
import OurStudio from '../views/pages/AboutSection/OurStudio'
import Spotify from '../views/pages/Devices/Spotify'
import Other from '../views/pages/Devices/Other'
import Roku from '../views/pages/Devices/Roku'
import Sattelite from '../views/pages/Devices/Sattelite'
import Smart from '../views/pages/Devices/Smart'
import PreviewArticleInfo from '../views/pages/ArticleInfo/PreviewArticleInfo'
import PaymentFailed from '../views/pages/PaymentStatus/PaymentFailed/PaymetFailed'
import PaymentSuccess from '../views/pages/PaymentStatus/PaymentSuccess/PaymentSuccess'
import { checkOperatingSystem } from '../Utils/utils'
import PrayerSuccessPage from '../views/pages/PrayerSection/PrayerSuccessPage'
import Products from '../Components/Products/Products';

import MagazinesList from '../views/pages/Magazines/MagazinesList'
import SearchShowAll from '../views/pages/SearchResult/SearchShowAll'
const LayoutsRoute = () => {
  const pathname=window.location.href
  const dispatch=useDispatch()
  const [path,setPath]=useState()
  const location=useLocation()
  const [topBanners,setTopBanners]=useState([])
  const navigate=useNavigate()
  const user = useSelector((state) => state?.user?.value);
  const [loading,setLoading]=useState(true)
  const [deviceType,setDeviceType] = useState()
  const available_paths=[
    "/",
    "account-settings",
    "change-password",
    "subscribe",
    "live",
    "favourites",
    "schedule",
    "leadership-team",
    "about-revelation",
    "latest-news",
    "videos",
    "send-prayer",
    "presenters",
    "donation",
    "building-fund",
    "israel-tour",
    "article-info",
    "statement-of-faith",
    "healing-scriptures",
    "watch-video",
    "presenters-info",
    "donate-by-debitcard",
    "show-information",
    "contact-us",
    "search",
    "searchAll",
    "featured-shows",
    "featured",
    "gift-aid-info",
    "privacy-policy",
    "our-studio",
    "spotify",
    "others",
    "tv",
    "satellite",
    "roku",

  ]
  var settings = {
    dots: false,
    infinite: true,
    loop: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
  };
  useEffect(()=>{
    setDeviceType(checkOperatingSystem())
  },[])


  useEffect(()=>{
    if(location?.pathname=="/"){
      setPath("/")
    }else{
      let locPath=location?.pathname?.split("/")
      setPath(locPath[1])
    }
  },[location?.pathname])

  useEffect(()=>{
    dispatch(getLoadingSlice({
      loading:true
    }))
    if(location?.pathname=="/"){
      setLoading(true)
    }else{
      setLoading(false)
    }
    service.getTopBanners().then((response)=>{
      if(response?.success===true && response?.data?.length>0){
        setTopBanners(response?.data)
      }
    })
  },[getHomeData])
  useEffect(()=>{
    window.scroll(0,0)
  },[])
  const bannerHandler=(data)=>{
    if(data?.show_id || data?.vanity_url){
        navigate(data?.vanity_url?
          "/"+data?.vanity_url:
          "/"+data?.show_id

          )
    }
    if(data?.file){
        window.open(data?.file,'_blank')
    }
    if(data?.redirect_url){
        window.location.href=data?.redirect_url
    }
}
  return (
    <>
   
        
        <Header/>
        
         { path!="register-login"&&path!="forgot-password"&&location?.pathname?.slice(0,20)!=`/auth/reset-password`&&available_paths.find((ele)=>ele==path) &&topBanners?.length>0 || location?.state?.id?
          (<div className="wrapper">
    
          <Slider className={deviceType==="android" || deviceType==="iPhone"?"bannerSection topSpace":"bannerSection"} {...settings}>
                    
          {
              topBanners?.map((item,key)=>(
                 
                      
                          <div key={key} className="imageContainer" onClick={()=>bannerHandler(item)}>
              <img src={item?.banner} alt="Banner" />
          </div>
                      
                  
    
              ))
          }
      </Slider>
          </div> ):(
            <div className="wrapper">
              {
                path!="register-login"&&path!="forgot-password"&&location?.pathname?.slice(0,20)!=`/auth/reset-password`&&
              <div className={deviceType==="android" || deviceType==="iPhone"?"emptySpace topSpace":"topSpace"}/>
              }
            </div>
          )
          }
          <Routes>
            <Route path='/' element={<PrivateRoutes/>}>
            <Route path="/account-settings" element={<AccountSettings/>} />
            <Route path="/change-password" element={<ChangePassword/>} />
            <Route path="/favourites" element={<ReminderAndFav/>} />
              
          </Route>
              <Route index element={<Home/>} />
              <Route path='/live' element={<Live/>} />
              <Route path='/schedule' element={<Schedule/>} />
              <Route path='/leadership-team' element={<LeaderShipTeam/>} />
              <Route path="/about-revelation" element={<AboutRevelation/>} />
              <Route path="/our-studio" element={<OurStudio/>} />
              <Route path="/latest-news" element={<LatestNews/>} />
              <Route path="/videos" element={<OnDemand/>} />
              <Route path="/send-prayer" element={<Prayer/>} />
              <Route path="/presenters" element={<Presenters/>} />
              <Route path="/donation" element={<Donation/>} />
              <Route path="/building-fund" element={<BuildingFund/>} />
              <Route path="/israel-tour" element={<IsraelTour/>} />
              <Route path="/subscribe" element={<Subscribe/>} />
              <Route path="/article-info/:id" element={<ArticleInfo/>} />
              <Route path="/preview/:id" element={<PreviewArticleInfo/>} />
              <Route path="/statement-of-faith" element={<StatementOfFaith/>} />
              <Route path="/healing-scriptures" element={<HealingScriptures/>} />
              <Route path="/watch-video/:id" element={<VideoPlayer/>} />
              <Route path="/presenters-info" element={<PresentersInfo/>} />
              <Route path="/donate-by-debitcard" element={<DonateByDebit/>} />
              <Route path="/show-information/:show_id" element={<ShowInformation/>} />
              <Route path="/:show_id" element={<ShowInformation/>} />
              <Route path="/contact-us" element={<Contact/>} />
              <Route path="/search" element={<SearchResult/>} />
              <Route path="/showAll" element={<SearchShowAll/>} />
              <Route path="/featured-shows" element={<FeaturedShows/>} />
              <Route path="/featured" element={<Featured/>} />
              <Route path='/gift-aid-info' element={<GiftAidInfo/>}/>
              <Route path='/magazines' element={<MagazinesList/>}/>
              
              <Route path="/register-login" element={!user?(<RegisterLogin/>):(<Navigate to="/"/>)} />
              <Route path='/forgot-password' element={!user?(<ForgotPassword/>):(<Navigate to="/"/>)}/>
              <Route path='/auth/reset-password/:token' element={!user?(<ResetPassword/>):(<Navigate to="/"/>)}/>
              
              <Route path='/failed' element={<PaymentFailed/>} />
              <Route path='/success' element={<PaymentSuccess/>} />

              <Route path='/send-prayer/success' element={<PrayerSuccessPage/>} />

              <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
              <Route path='/spotify' element={<Spotify/>} />
              <Route path='/others' element={<Other/>} />
              <Route path='/tv' element={<Smart/>} />
              <Route path='/satellite' element={<Sattelite/>} />
              <Route path='/roku' element={<Roku/>} />
          <Route path='*' element={<PageNotFound/>} />
        </Routes>
        <Products/>

    <Footer/>
        
      
        
    </>

  )
}

export default LayoutsRoute