import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y,Autoplay } from "swiper";
import { ReactComponent as PlusIcon } from "../../../images/svg/plus.svg";
import { ReactComponent as PlayIcon } from "../../../images/svg/play.svg";
import ShowCard from "../../../Components/ShowCard/ShowCard";
import { Link, useNavigate } from "react-router-dom";
import * as service from "./service";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay"
import { getShowDetails } from "../../../CommonService";
const OnDemand = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scroll(0, 0);
    setLoading(true);
    service.getOnDemandData().then((response) => {
      setData(response?.data);
      setLoading(false);
    });
  }, []);
  const navigateToVideoPlayer=(showId)=>{
    getShowDetails(showId).then((response)=>{
      if(response?.success===true){
        let videoId;
        if(response?.data?.single_video===0){
          videoId=response?.data?.videos[0]?.videos[0]?.vanity_url?response?.data?.videos[0]?.videos[0]?.vanity_url:response?.data?.videos[0]?.videos[0]?.video_id
        }else{
          videoId=response?.data?.videos[0]?.vanity_url?response?.data?.videos[0]?.vanity_url:response?.data?.videos[0]?.video_id
        }
        navigate(`/watch-video/${videoId}`)
      }
    })
  }
  return (
    <>
      {loading ? (
        <div className="loadingContainer">
          <div className="loadingOverlay"></div>
          <div className="spinnerIcon">
            <div className="inner lds-dual-ring"></div>
          </div>
        </div>
      ) : (
        <div className="ondemand">
          <div className="wrapper1">
            <div className="spotlight">
              {data?.map(
                (mainData, index) =>
                  mainData?.category_id == 99988 && (
                    <Swiper
                      slidesPerView={1}
                      watchSlidesProgress
                      autoplay={{
                        delay: 7000,
                        disableOnInteraction: false
                    }}
                      spaceBetween={0}
                      pagination={{
                        dynamicBullets: true,
                        clickable: true,
                      }}
                      modules={[Pagination,Autoplay]}
                      className="mySwiper"
                      loop
                    >
                      {mainData?.shows?.map((showsData, index) => {
                        if (showsData) {
                          return (
                            <SwiperSlide key={index}>
                              <div className="featuredContainer">
                                <div className="background">
                                  <img
                                    src={showsData?.logo_thumb}
                                    alt="Thumbnail"
                                  />
                                </div>
                                <div className="imageContainer">
                                  <img
                                    src={showsData?.logo_thumb}
                                    alt="Thumbnail"
                                  />
                                </div>
                                <div className="gradientBg"></div>
                              </div>
                              <div className="details">
                                <div className="left">
                                  <h1 className="title">
                                    {showsData?.show_name}
                                  </h1>
                                  <p>{showsData?.synopsis}</p>
                                </div>
                                <div className="right">
                                  <button
                                    className="transparent"
                                    onClick={() =>
                                      navigate(
                                        showsData?.vanity_url?
                                        `/${showsData?.vanity_url}`:
                                        `/${showsData?.show_id}`
                                        ,{state:{id:showsData?.vanity_url?showsData?.vanity_url:showsData?.show_id}}
                                      )
                                    }
                                  >
                                    More Info
                                    <PlusIcon />
                                  </button>
                                  <button
                                    className="bg-button"
                                    onClick={() =>{
                                      if(showsData?.video_vanity_url){
                                        navigate(`/watch-video/${showsData?.video_vanity_url}`)
                                      }else{

                                        navigateToVideoPlayer(showsData?.show_id)
                                      }
                                    }
                                    }
                                  >
                                    Play
                                    <PlayIcon />
                                  </button>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        }
                      })}
                    </Swiper>
                  )
              )}
            </div>
          </div>
          <div className="wrapper">
            <div className="librarySection">
              {data?.map(
                (mainData, index) =>
                  mainData?.category_id == 515 && (
                    <>
                      <h1 className="heading">Show Library</h1>
                      <div className="libraries">
                        {mainData?.shows?.map((data, index) => (
                          <div key={index} className="items">
                            <ShowCard
                              cardType="descriptionCard"
                              title={data?.show_name}
                              year={data?.year}
                              description={data?.synopsis}
                              thumbnail={data?.logo_thumb}
                              id={data?.vanity_url?data?.vanity_url:data?.show_id}
                              navigate="show"
                              favourites={data?.watchlist_flag}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  )
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnDemand;
