import { createSlice } from "@reduxjs/toolkit";

export const DonationKeySlice=createSlice({
    name:"donationKey",
    initialState:{
        value:""
    },
    reducers:{
        getDonationKey:(state,action)=>{
            state.value=action.payload.donationKey
        }
    }
})

export const {getDonationKey}=DonationKeySlice.actions
export default DonationKeySlice.reducer