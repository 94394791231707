import axios from "axios";
const qs = require('query-string');

export const getLiveChannels=()=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
    params:{
      channel_id:370
  }
  };
  return axios.get(process.env.REACT_APP_API_URL+"channel/list",customConfig)
  .then((response)=>{
    return response.data
  }).catch((err)=>{
    return [];
  })
}

export const getLiveSchedule=()=>{
    let uId=localStorage.getItem("guestUserId");
    let countryCode=localStorage.getItem("country_code");
    let ipaddress=localStorage.getItem("ipaddress");
    let deviceId=localStorage.getItem("deviceId");
    let user_id=localStorage.getItem("userId");
    var token = localStorage.getItem("access_token");
  if (user_id) {
    uId = user_id;
  }
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
      uid: uId,
      pubid: process.env.REACT_APP_PUBID,
      country_code: countryCode,
      channelid: process.env.REACT_APP_CHANNELID,
      dev_id: deviceId,
      ip: ipaddress,
      device_type: "web",
    },
    params:{
        channel_id:370
    }
  };
    return axios
    .get(process.env.REACT_APP_API_URL + "schedule/guide/"+370, customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
  
}

export const onVideoPlayFunction=(values, event,currentTime,is_live) =>{
  let countryCode=localStorage.getItem("country_code");
  let sessionId = localStorage.getItem("session_id");
  let uId=localStorage.getItem("guestUserId");
  let videoTime =
    currentTime == 0 || currentTime == undefined ? "" : currentTime.toString();
    let user_id=localStorage.getItem("userId");
  if (user_id) {
    uId = user_id;
  }
  let deviceId=localStorage.getItem("deviceId");
  let ctimestamp = Date.now().toString();
  let ctime = ctimestamp.slice(0, 10);
  let appid = "150"; //revelation
  const requestBody = {
    session_id: sessionId,
    user_id: uId,
    device_id: deviceId,
    publisherid: process.env.REACT_APP_PUBID,
    app_id: appid,
    channel_id: 370,
    event_type: event,
    video_id: values?.video_id?values?.video_id:"0",
    show_id:values?.show_id,
    is_live:is_live,
    video_title: values.video_title,
    timestamp: ctime,
    video_time: videoTime,
  };
  var token = localStorage.getItem("access_token");
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
      "access-token": token,
    },
  };
  
  return axios
    .post(
      "https://analytics.poppo.tv/event",
      qs.stringify(requestBody),
      customConfig
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
}