import React, { useRef, useState } from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {ReactComponent as ShareIcon} from "../../../images/svg/share.svg"
import {ReactComponent as DownArrow} from "../../../images/svg/down-arrow.svg"
import { FacebookShareButton, TwitterShareButton,EmailShareButton,WhatsappShareButton } from "react-share";
import { useEffect } from "react";

const Other = () => {
    const [shareButton,setShareButton]=useState(false)
    let shareRef = useRef(null)
  let shareIconRef = useRef(null)

  useEffect(()=>{
    window.scroll(0,0)

  },[])

  useEffect(() => {
    let handleOutClick = (e) => {
      if (!shareIconRef?.current?.contains(e.target)) {
        if (!shareRef?.current?.contains(e.target)) {
          setShareButton(false); //outside click
        }
      }
    };

    document.addEventListener("mousedown", handleOutClick);
    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [shareRef]);
  return (
    <div className="otherDevices">
      <div className="wrapper">
        <h1 className="heading">Other</h1>

        <div className="contents">
          <div className="sections">
            <div className="imageContainer">
              <img
                src={require("../../../images/freeviewhdlogo.jpg")}
                alt="Freeviewhdlogo"
              />
            </div>
            <div className="description">
              <p>
                Revelation TV can be viewed in standard definition only on
                FreeviewHD channel 281. An internet connection and a UK located
                terrestrial antenna are to your FreeviewHD compatible TV or set
                top box are needed to view FreeviewHD channel 281. There is no
                monthly subscription cost.
              </p>
              <p>
                The low cost <span className="link" onClick={()=>window.open("https://manhattan-tv.com/freeview-hd/manhattan-t1","_blank")}>Mahnhattan T1</span> set top
                box is compatible with Freeview HD channel 281 should your TV
                not be FreeviewHD compatible. This unit also has an{" "}
                <span className="link" onClick={()=>window.open("https://manhattan-tv.com/av-kit","_blank")}>AV kit</span> option available from the
                manufacturer for £5 plus delivery suitable for viewers who do
                not have a digital TV with HDMI inputs.{" "}
              </p>
              <p>
                Regular Freeview set top boxes or TVs that are not branded
                Freeview HD and have no internet connection cannot view
                FreeviewHD channel 281. Likewise the newer Freeview Play set top
                boxes and Smart TVs cannot view FreeviewHD channel 281.
              </p>
              {/* <p>
                More info available on FreeviewHD channel 281 from{" "}
                <span className="link" onClick={()=>window.open("https://www.visiontv.co.uk/#/where-to-watch","_blank")}>VisionTV</span>.
              </p> */}
            </div>
          </div>

          {/* <div className="sections">
            <div className="imageContainer">
              <img
                src={require("../../../images/visiontvnetwork.png")}
                alt="Visiontvnetwork"
              />
            </div>
            <div className="description">
              <p>
                Revelation TV can be viewed in standard definition only on the{" "}
                <span className="link" onClick={()=>window.open("https://www.visiontv.co.uk/#/channel/revelation-tv","_blank")}>Vision TV website</span> or using the{" "}
                <span className="link" onClick={()=>window.open("https://www.visiontv.co.uk/#/where-to-watch","_blank")}>AV kit</span> Vision TV app
              </p>
            </div>
          </div> */}

          <div className="sections">
            <div className="imageContainer">
              <img
                src={require("../../../images/chromecastlogo.jpg")}
                alt="Chromecastlogo"
              />
            </div>
            <div className="description">
              <p>
                Revelation TV can be viewed on Chromecast using the Revelation
                TV App selecting the{" "}
                <span className="link" onClick={()=>window.open("https://www.visiontv.co.uk/#/where-to-https://admin.mangomolo.com//analytics/cms_assets/145/Chromecast%20Revelation%20TV.jpg","_blank")}>Chromecast icon</span>. You may need to
                install the Google Home App from Google Play Store to set up
                your Chromcast device.
              </p>
            </div>
          </div>

          <div className="date">
            <div className="icon">
              <img
                src={require("../../../images/icon-calendartime.png")}
                alt="Calender"
              />
            </div>
            <span>02 Nov 2020</span>
          </div>

          <div className="buttonContainer">
                    <button ref={shareIconRef} onClick={()=>setShareButton(!shareButton)} className={shareButton?"active shareButton ":"shareButton"}>
                        <ShareIcon/>
                        <span>Share</span>
                        
                        <DownArrow className='active'/>
                    </button>
                    {
                        shareButton&&
                        <div className="shareContainer" ref={shareRef}>
                            <FacebookShareButton
                                url={window.location.href}
                            >
                                <span className='name facebook'>Facebook</span>
                            </FacebookShareButton>
                            <TwitterShareButton
                                url={window.location.href}
                            >
                                <span className='name twitter'>Twitter</span>
                            </TwitterShareButton>
                            <CopyToClipboard text={window.location.href}>
                                <button className='clipboard' onClick={()=>alert("Link copied to clipboard")}>Clipboard</button>
                            </CopyToClipboard>
                            <EmailShareButton
                                url={window.location.href}
                            >
                                <span className='name'>Mail</span>
                            </EmailShareButton>
                            <WhatsappShareButton  url={window.location.href}>
                          <span className='name whatsapp'>Whatsapp</span>
                          </WhatsappShareButton   >
                        </div>
                    }
                </div>
        </div>
      </div>
    </div>
  );
};

export default Other;
