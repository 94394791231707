import React from 'react'
import { useNavigate } from 'react-router-dom'

const SubscribeBanner = () => {
  const navigate=useNavigate()
  return (
    <div className="subscribeBanner">
        <div className="imageContainer">
            <img src={require('../../images/banner_art.jpg')} alt="Banner" />
            <div className="buttonContainer">
                <button className="subscribe" onClick={()=>navigate("/subscribe")}>
                    Subscribe
                </button>
            </div>
        </div>
    </div>
  )
}

export default SubscribeBanner