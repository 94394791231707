import { createSlice } from "@reduxjs/toolkit";

export const userSlice=createSlice({
    name:"user",
    initialState:{
        value:""
    },
    reducers:{
        getUser:(state,action)=>{
            state.value=action.payload.user
        }
    }
})

export const {getUser}=userSlice.actions
export default userSlice.reducer